import { TSignUp } from "../../interfaces/individualSignUp";
import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";

export interface ISignUp {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
  country: string;
  countryCode: string;
  phoneNoCountryDialCode: string;
  gender: string;
  funder_type_id: number | null;
  business_name: string;
  step: number;
  emailVerified: boolean;
  bvn: string;
  dob: string;
  bvnValidated: boolean;
}

export const initialState: ISignUp = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  password: "",
  country: "",
  countryCode: "",
  phoneNoCountryDialCode: "",
  gender: "",
  funder_type_id: null,
  business_name: "",
  step: 1,
  emailVerified: false,
  bvn: "",
  dob: "",
  bvnValidated: false,
};

export const signUpReducer = (
  state: TSignUp = initialState,
  action: ReduxActionModel
): TSignUp => {
  switch (action.type) {
    // Set During Individual Sign up
    case actionType.SET_SIGN_UP:
      state = { ...action.payload };
      return state;
    case actionType.RESET_SIGN_UP:
      return initialState;
    default:
      return state;
  }
};
