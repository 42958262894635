import React, { Dispatch, SetStateAction } from "react";
import { yupValidators } from "../../../../helpers/yupValidators";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  LOCAL_CURRENCY_CODE,
  // USD_CURRENCY_CODE,
} from "../../../../helpers/currencies";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData } from "../../../../apis/apiMethods";
import { InvestmentCreation } from "../../../../helpers/generalUtils";
import { handleFormatAmount } from "../../../../helpers/formatAmount";
import { appInsights } from "../../../AppInsight/AppInsight";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import {
  setInvestmentStep,
  setInvestmentType,
  setOnlineInvestmentInfo,
} from "../../../../redux/actions";

interface IInvestmentModalStepOne {
  setError: Dispatch<SetStateAction<string>>;
}

const schema = yup.object().shape({
  investmentAmount: yupValidators.investmentAmount,
});

const InvestmentModalStepOne: React.FC<IInvestmentModalStepOne> = ({
  setError,
}) => {
  const currencyList = useSelector(
    (state: ReduxStoreModel) => state.currencies.availableCurrencies
  );
  const currentStep = useSelector(
    (state: ReduxStoreModel) => state.investment.currentStep
  );
  const investmentCurrency = useSelector(
    (state: ReduxStoreModel) => state.investment.onlineInvestmentInfo.currency
  );
  const investment = useSelector(
    (state: ReduxStoreModel) => state.investment.onlineInvestmentInfo
  );
  const selectedInvestment = useSelector(
    (state: ReduxStoreModel) => state.investment.selectedInvestment
  );
  const { currency } = useSelector(
    (state: ReduxStoreModel) => state.investment.onlineInvestmentInfo
  );
  const dispatch = useDispatch();
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError: setFormError,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      investmentAmount: investment.amount,
    },
  });

  const minInvestmentAmount: any = Math.min(
    ...selectedInvestment?.interest_ranges.map(
      (range: any) => range.lower_amount
    )
  );
  const maximumInvestmentAmount: any = Math.max(
    ...selectedInvestment?.interest_ranges.map(
      (range: any) => range.upper_amount
    )
  );
  const initiatePaystackPayment = async (amount: number) => {
    const reqPayload = {
      amount: Number(amount),
      currency,
      funder_id: funderId,
    };

    const reqBody = {
      investment_transaction_type_id: InvestmentCreation,
      amount: Number(amount),
      request_payload: JSON.stringify(reqPayload),
    };
    try {
      const res = await postData(
        apiEndpoints.INITIATE_PAYSTACK_PAYMENT,
        reqBody
      );
      dispatch(
        setOnlineInvestmentInfo({
          reference: res.internal_transaction_reference,
        })
      );
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: InvestmentModalStepOne },
      });
    }
  };

  const onSubmit = ({ investmentAmount }: { investmentAmount: number }) => {
    setError("");
    if (investmentAmount < minInvestmentAmount) {
      return setFormError("investmentAmount", {
        type: "manual",
        message: "Amount is not up to minimum investment amount",
      });
    }
    if (investmentAmount > maximumInvestmentAmount) {
      return setFormError("investmentAmount", {
        type: "manual",
        message: "Amount is more than maximum investment amount",
      });
    }

    if (!investmentCurrency) {
      setError("Choose investment currency");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }

    if (
      (investmentCurrency === LOCAL_CURRENCY_CODE &&
        selectedInvestment.lcy_locked) ||
      (investmentCurrency === LOCAL_CURRENCY_CODE &&
        selectedInvestment.lcy_locked)
    ) {
      return setError(
        "Sorry, the target investment amount has been raised for this currency. "
      );
    }
    initiatePaystackPayment(handleFormatAmount(String(investmentAmount)));
    dispatch(
      setOnlineInvestmentInfo({
        amount: handleFormatAmount(String(investmentAmount)),
      })
    );
    dispatch(setInvestmentStep(currentStep + 1));
  };
  return (
    <div>
      <h5 className="page-subtitle mb-3">{selectedInvestment?.name}</h5>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <p className="form-label">
            What kind of Investment do you want to make?
          </p>
          <div className="alignStepOneBoxes">
            {/* Box for Local Currency  */}
            {selectedInvestment?.lcy_product_id && (
              <div
                className={`box ${
                  investmentCurrency === currencyList[0].currencyCode
                    ? "active"
                    : ""
                } ${selectedInvestment.lcy_locked && "disabled"}`}
                onClick={() =>
                  dispatch(
                    setOnlineInvestmentInfo({
                      currency: currencyList[0].currencyCode,
                    })
                  )
                }
              >
                <div className="currency">
                  {" "}
                  {selectedInvestment.currencyCode}
                </div>
                <p className="label">
                  {" "}
                  {selectedInvestment.currencyCode} Investment
                </p>
              </div>
            )}
          </div>
        </div>

        <CustomInputFieldFormItem
          type="number"
          enableSeparator
          defaultValue={getValues("investmentAmount")}
          maxLength={20}
          {...register("investmentAmount")}
          placeholder="e.g. 100,000.00"
          label="Enter Amount"
          errors={errors.investmentAmount}
        />

        <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
          <button
            type="button"
            className="btn btn-sm advancly-nobg-btn mr-3"
            onClick={() => dispatch(setInvestmentType(""))}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-sm advancly-white-btn">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
export default InvestmentModalStepOne;
