import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import { appInsights } from "../../../AppInsight/AppInsight";
import { TVSteps, ISelectedBeneficiary, getLogo } from "../TVDrawer.component";
import dstv from "../../../../assets/img/pay/dstv.png";
import gotv from "../../../../assets/img/pay/gotv.png";
import startimes from "../../../../assets/img/pay/startimes.png";
import other from "../../../../assets/img/pay/otherNetworks.png";
import { ETVProviders } from "../TVDrawerStepTwo/TVProviderListDrawer";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import { RiSearch2Line } from "react-icons/ri";
import styles from "../../BankTransferDrawers/NGNTransferDrawer/WithdrawalModalStepTwo/NGNTransferDrawerBankList.module.scss";
import {
  IBillBeneficiary,
  IBillBeneficiaryModel,
} from "../../../../interfaces/billPayment";
import { ESupportedBills } from "../../../../Constants/mock";
import { Modal, Spin } from "antd";

interface IProps {
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
  setShowBeneficiaryList: Dispatch<SetStateAction<boolean>>;
  showBeneficiaryList: boolean;
}

const TVBeneficiaryListDrawer = ({
  setSelectedBeneficiaryData,
  setShowBeneficiaryList,
  showBeneficiaryList,
}: IProps) => {
  const { beneficiaries, isLoadingBeneficiary } = useSelector(
    (state: ReduxStoreModel) => state.billBeneficiaries
  );
  const { supportedTVProviders } = useSelector(
    (state: ReduxStoreModel) => state.tvProviders
  );
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    appInsights.trackPageView({
      name: "TVBeneficiaryListDrawer.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const handleSelectProvider = (
    deviceNumber: string,
    generic_operator_name: string | null,
    beneficiaryName: string | null
  ) => {
    setSelectedBeneficiaryData({
      billType: "",
      currency: "",
      customerId: 0,
      operatorId: "",
      productId: "",
      productName: "",
      deviceNumber: deviceNumber,
      beneficiaryName: beneficiaryName || "",
      genericOperatorName: generic_operator_name || "",
    });
    setShowBeneficiaryList(false);
  };
  return (
    <Modal
      open={showBeneficiaryList}
      onCancel={() => {
        setShowBeneficiaryList(false);
      }}
      footer={null}
      closeIcon={null}
    >
      <div className="mt-5">
        <CustomInputFieldFormItem
          size="large"
          maxLength={15}
          placeholder="Search"
          onChange={handleSearch}
          prefix={<RiSearch2Line size="20px" className="text-muted" />}
          fieldName="search"
          allowClear
        />
      </div>
      {isLoadingBeneficiary ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : beneficiaries && beneficiaries.length === 0 ? (
        <p className="text-center font-weight-bold">No Beneficiary Available</p>
      ) : (
        <div className={styles.list_of_banks_container}>
          {/* List of Banks */}
          <ul className={styles.listOfBanks}>
            {beneficiaries &&
              beneficiaries
                .filter(({ device_number, operator_id, beneficiary_name }) => {
                  return beneficiary_name?.toLowerCase()?.includes(searchQuery);
                })
                .map(
                  (
                    {
                      product_name,
                      operator_id,
                      device_number,
                      product_id,
                      beneficiary_name,
                      generic_operator_name,
                    },
                    index
                  ) => {
                    return (
                      <div key={index}>
                        {
                          <li
                            className={styles.list_of_banks_li}
                            onClick={() => {
                              handleSelectProvider(
                                device_number,
                                generic_operator_name,
                                beneficiary_name
                              );
                            }}
                          >
                            <div className={styles.list_of_banks_sub_container}>
                              <img
                                src={getLogo(
                                  generic_operator_name?.toLowerCase() || ""
                                )}
                                alt="list of network provider icon"
                                className={styles.list_of_bank_logo}
                              />
                              <p className={`${styles.bank_name} p-none`}>
                                {beneficiary_name || "N/A"} -
                              </p>
                              <p className={`${styles.bank_name} p-none`}>
                                ({device_number})
                              </p>
                            </div>
                          </li>
                        }
                      </div>
                    );
                  }
                )}
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default TVBeneficiaryListDrawer;
