import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import useWalletBalance from "./../../hooks/custom-hooks/useWalletBalance";
import Alert from "../../components/Alert/Alert.component";
import { errorHandler } from "./../../helpers/errorHandler";
import { capitalizeFirstLetter } from "./../../helpers/capitalizeFirstLetter";
import useDashboardStats from "../../hooks/custom-hooks/useDashboardStats";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { FiCopy } from "react-icons/fi";
import { Badge, Card, Divider, message, Spin } from "antd";
import creditAd from "../../assets/img/dashboard/adOne.png";
import cardAd from "../../assets/img/dashboard/cardAd.png";
import chart from "../../assets/img/dashboard/Chart.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import formatMoney from "../../helpers/formatMoney";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import useWalletTransactions from "../../hooks/custom-hooks/useWalletTransactions";
import NoTableData from "../../components/NoTableData/NoTableData.component";
import moment from "moment";
import DashboardQuickActions from "../../components/DashboardQuickActions";
import { useDispatch, useSelector } from "react-redux";
import { setShowBalance } from "../../redux/actions";
import { ReduxStoreModel } from "../../interfaces/redux";
import { GrTransaction } from "react-icons/gr";

export default function Dashboard() {
  const history = useHistory();
  const currencyList = useSelector(
    (state: ReduxStoreModel) => state.currencies.availableCurrencies
  );
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const [activeTab, setActiveTab] = useState("");
  const dispatch = useDispatch();
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const showBalance = useSelector(
    (state: ReduxStoreModel) => state.user.showBalance
  );

  const [copied, setCopied] = useState("");
  const [accountDetailsCopy, setAccountDetailsCopy] = useState("Not Available");
  const [transactionSize, setTransactionSize] = useState(3);
  const [showQuickActions, setShowQuickActions] = useState(false);

  const copyAccountNumber = () => {
    setCopied("Copied.");
    navigator.clipboard.writeText(accountDetailsCopy);
    message.success("Copied!");
    setTimeout(() => setCopied(""), 3000);
  };

  useEffect(() => {
    if (currencyList.length > 0) {
      setActiveTab(currencyList[0]?.currencyCode);
    }
  }, [currencyList]);

  const {
    data: wallet,
    error,
    isLoading,
  } = useWalletBalance({
    walletId:
      activeTab === currencyList[0]?.currencyCode
        ? lcy_wallet_id
        : usd_wallet_id,
    funderId,
  });

  const {
    data: transactions = [],
    error: transactionsError,
    isLoading: isLoadingTransactions,
  } = useWalletTransactions({
    walletId: lcy_wallet_id,
    page: 1,
    size: transactionSize,
    funderId,
  });

  useEffect(() => {
    setAccountDetailsCopy(wallet?.accountNumber);
  }, [accountDetailsCopy, wallet]);

  const {
    isLoading: dashboardStatsLoading,
    error: dashboardStatsError,
    data,
  } = useDashboardStats();

  const dataToShow =
    activeTab === currencyList[1]?.currencyCode
      ? data?.usd_summary
      : activeTab === currencyList[0]?.currencyCode
      ? data?.lcy_summary
      : null;

  const {
    first_name,
    last_name,
    business_name,
    funder_type_id,
    isBvnValidated,
    country,
  } = useSelector((state: ReduxStoreModel) => state?.user);

  useEffect(() => {
    appInsights.trackPageView({ name: "Dashboard.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    if (
      country === "NG" &&
      !isBvnValidated &&
      localStorage.getItem("bvnalert")
    ) {
      history.push(ROUTES.PROFILE_SETTINGS);
    }
  }, [country, isBvnValidated, history]);

  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <div className="title-align mb-0">
        {/* Display Business Name for Business and Full Name for individuals  */}
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="page-title">
              Hello,{" "}
              {funder_type_id === 1
                ? `${capitalizeFirstLetter(first_name)}`
                : funder_type_id === 2
                ? capitalizeFirstLetter(business_name || "")
                : null}
            </div>
            {wallet?.accountNumber && (
              <p
                className="text-muted mt-1 cursor-pointer"
                onClick={copyAccountNumber}
              >
                {wallet?.accountNumber} <FiCopy className="text-primary" />
              </p>
            )}
          </div>
        </div>
        <div>
          <button
            className="btn btn-sm advancly-light-blue-btn mr-2"
            onClick={() => setShowQuickActions(true)}
          >
            Quick Actions
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-4 dashboard-balance">
          <Card bordered={false} className="mb-3 mb-md-0 w-100 h-100">
            <div>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <p className="font-weight-bold p-none text-muted">
                  All Spending Accounts{" "}
                  <MdKeyboardArrowDown className="ml-1" size={16} />{" "}
                </p>
                <div onClick={() => dispatch(setShowBalance(!showBalance))}>
                  {showBalance ? (
                    <AiFillEyeInvisible className="text-muted" size={16} />
                  ) : (
                    <AiFillEye className="text-muted" size={16} />
                  )}
                </div>
              </div>
              <div className="mt-2">
                <h5>
                  {showBalance
                    ? formatMoney(wallet?.availableBalance, activeTab)
                    : "*****"}
                </h5>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <button
                className="btn btn-primary px-4"
                onClick={() => history.push(ROUTES.PAY)}
              >
                Pay
              </button>
              <p className="text-primary p-none">Receive Money</p>
            </div>
          </Card>
        </div>

        <div className="col-md-4">
          <img
            src={creditAd}
            alt="Coming soon"
            className="mw-100 mb-3 mb-md-0 w-100 h-100"
            style={{ objectFit: "contain" }}
          />
        </div>

        <div className="col-md-4">
          <img
            src={cardAd}
            alt="Coming soon"
            className="mw-100 mb-3 mb-md-0 w-100 h-100"
            style={{ objectFit: "contain" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <Card bordered={false} className="mb-3 mb-md-0 card-no-padding">
            <div className="d-flex align-items-center">
              <h5 className="mr-3">Quick Insight</h5>
              <p className="font-weight-bold p-none text-muted">
                This month <MdKeyboardArrowDown className="ml-1" size={16} />{" "}
              </p>
            </div>

            <div className="mt-4 d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <p className="font-weight-bold p-none text-muted">Income</p>
                <h5 className="mr-3">
                  {formatMoney(500000, activeTab)}
                  <Badge
                    className="site-badge-count-109 ml-1 font-weight-bold"
                    count={"+2.45%"}
                    style={{
                      backgroundColor: "rgba(5, 205, 153, 0.10)",
                      color: "#05CD99",
                    }}
                  />
                </h5>
              </div>

              <div>
                <p className="font-weight-bold p-none text-muted">Expense</p>
                <h5 className="mr-3">
                  {formatMoney(200000, activeTab)}
                  <Badge
                    className="site-badge-count-109 ml-1 font-weight-bold"
                    count={"-4.75%"}
                    style={{
                      backgroundColor: "rgba(227, 26, 26, 0.10)",
                      color: "#E31A1A",
                    }}
                  />
                </h5>
              </div>

              <button className="btn advancly-white-btn">Track & Plan</button>
            </div>
            <img
              src={chart}
              alt="Coming soon"
              className="mw-100 w-100 mb-3 mb-md-0"
            />
          </Card>
        </div>

        <div className="col-md-4">
          <Card
            bordered={false}
            style={{
              height: 345,
              borderRadius: 20,
              overflow: "auto",
            }}
            className="mb-3 mb-md-0"
          >
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="p-none">Recent transactions</h6>
              <p
                className="text-primary p-none cursor-pointer"
                onClick={() => setTransactionSize(transactionSize * 2)}
              >
                See more
              </p>
            </div>

            <div>
              {isLoadingTransactions ? (
                <div className="d-flex justify-content-center align-items-center h-100 w-100">
                  <Spin />
                </div>
              ) : !transactions?.data?.length ? (
                <div className="mt-5">
                  <div className="d-flex justify-content-center w-100">
                    <GrTransaction size="20px" />
                  </div>
                  <br />
                  <p className="text-muted text-center">
                    You have not made any transaction yet!
                  </p>
                </div>
              ) : (
                <>
                  {transactions?.data?.map(
                    ({
                      amount,
                      note,
                      transactionDate,
                      transactioncategory,
                    }: {
                      amount: number;
                      transactionDate: string;
                      transactionId: number;
                      transactionType: string;
                      transactioncategory: string;
                      note: string;
                      runningBalance: any;
                    }) => {
                      const transactionType =
                        transactioncategory?.toLowerCase();
                      return (
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <p>{note || "No Description"}</p>
                              <p className="text-muted">
                                {moment(transactionDate).format("L") ||
                                  "No Description"}
                              </p>
                            </div>

                            <div>
                              <p
                                className={
                                  transactionType === "debit"
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {`${
                                  transactionType === "debit" ? "-" : "+"
                                } ${formatMoney(amount, activeTab)}`}
                              </p>
                              <p className="text-muted">Account 1</p>
                            </div>
                          </div>
                          <Divider />
                        </>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </Card>
        </div>
      </div>
      <DashboardQuickActions
        isOpen={showQuickActions}
        onClose={() => setShowQuickActions(false)}
      />
    </div>
  );
}
