import React, { Dispatch, SetStateAction } from "react";
import {
  AirtimeSteps,
  IAirtimeDataRequestBody,
} from "../AirtimeDrawer.component";
import { Button, Card } from "antd";
import formatMoney from "../../../../helpers/formatMoney";
import { naira } from "../../../../Constants/mock";

interface IProps {
  requestBody: IAirtimeDataRequestBody | null;
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  airtimeActiveTab: "airtime" | "data" | string;
}

const AirtimeReviewPayment = ({
  requestBody,
  setCurrentStep,
  airtimeActiveTab,
}: IProps) => {
  return (
    <div className="w-100">
      <Card style={{ width: "100%" }}>
        <h4 className="text-center">
          {requestBody?.dataPlanTitle ||
            formatMoney(requestBody?.amount || 0, naira)}
        </h4>
        <p className="text-center">
          {airtimeActiveTab === "airtime"
            ? "Airtime Recharge"
            : "Data Subscription"}
        </p>
        <div className="d-flex justify-content-between">
          <p>Provider</p>
          <p className="font-weight-bold">{requestBody?.productName}</p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Phone Number</p>
          <p className="font-weight-bold">
            {requestBody?.beneficiaryPhoneNumber}
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Amount</p>
          <p className="font-weight-bold">
            {formatMoney(requestBody?.amount || 0, naira)}
          </p>
        </div>
      </Card>
      <Button
        block
        type="primary"
        className="mt-5"
        size="large"
        onClick={() => setCurrentStep(AirtimeSteps.TransactionPinScreen)}
      >
        Continue
      </Button>
    </div>
  );
};

export default AirtimeReviewPayment;
