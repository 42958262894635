import {
  IAirtimeProvider,
  IBillBeneficiaryModel,
  IBillRecentTransactionsModel,
  IElectricityProvider,
  ISupportedBillList,
  ITVProvider,
} from "../../interfaces/billPayment";
import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";

const supportedBillListState: ISupportedBillList = {
  isLoadingSupportedBills: false,
  supportedBills: null,
  fetchSupportedBillsFailed: false,
};

const tvProviderState: ITVProvider = {
  isLoadingTVProviders: false,
  supportedTVProviders: null,
  fetchTVProvidersFailed: false,
};

const airtimeProviderState: IAirtimeProvider = {
  isLoadingAirtimeProviders: false,
  supportedAirtimeProviders: null,
  fetchAirtimeProvidersFailed: false,
};

const electricityProviderState: IElectricityProvider = {
  isLoadingElectricityProviders: false,
  supportedElectricityProviders: null,
  fetchElectricityProvidersFailed: false,
};
const beneficiaryState: IBillBeneficiaryModel = {
  isLoadingBeneficiary: false,
  beneficiaries: null,
  fetchBeneficiaryFailed: false,
};

const transactionsState: IBillRecentTransactionsModel = {
  isLoadingTransactions: false,
  transactions: null,
  fetchTransactionsFailed: false,
};

export const supportedBillsReducer = (
  state = supportedBillListState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_SUPPORTED_BILLS:
      state = {
        ...state,
        supportedBills: action.payload,
      };
      return state;

    case actionType.SET_SUPPORTED_BILLS_FAILED:
      state = {
        ...state,
        fetchSupportedBillsFailed: true,
      };
      return state;

    case actionType.LOADING_SUPPORTED_BILLS:
      state = {
        ...state,
        isLoadingSupportedBills: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export const tVProvidersReducer = (
  state = tvProviderState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_TV_PROVIDERS:
      state = {
        ...state,
        supportedTVProviders: action.payload,
      };
      return state;

    case actionType.SET_TV_PROVIDERS_FAILED:
      state = {
        ...state,
        fetchTVProvidersFailed: true,
      };
      return state;

    case actionType.LOADING_TV_PROVIDERS:
      state = {
        ...state,
        isLoadingTVProviders: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export const airtimeProvidersReducer = (
  state = airtimeProviderState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_AIRTIME_PROVIDERS:
      state = {
        ...state,
        supportedAirtimeProviders: action.payload,
      };
      return state;

    case actionType.SET_AIRTIME_PROVIDERS_FAILED:
      state = {
        ...state,
        fetchAirtimeProvidersFailed: true,
      };
      return state;

    case actionType.LOADING_AIRTIME_PROVIDERS:
      state = {
        ...state,
        isLoadingAirtimeProviders: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export const electricityProvidersReducer = (
  state = electricityProviderState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_ELECTRICITY_PROVIDERS:
      state = {
        ...state,
        supportedElectricityProviders: action.payload,
      };
      return state;

    case actionType.SET_ELECTRICITY_PROVIDERS_FAILED:
      state = {
        ...state,
        fetchElectricityProvidersFailed: true,
      };
      return state;

    case actionType.LOADING_ELECTRICITY_PROVIDERS:
      state = {
        ...state,
        isLoadingElectricityProviders: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export const billBeneficiaryReducer = (
  state = beneficiaryState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_BILL_BENEFICIARY_LIST:
      state = {
        ...state,
        beneficiaries: action.payload,
      };
      return state;

    case actionType.SET_BILL_BENEFICIARY_LIST_FAILED:
      state = {
        ...state,
        fetchBeneficiaryFailed: true,
      };
      return state;

    case actionType.LOADING_BILL_BENEFICIARY_LIST:
      state = {
        ...state,
        isLoadingBeneficiary: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export const billTransactionsReducer = (
  state = transactionsState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_BILL_TRANSACTIONS:
      state = {
        ...state,
        transactions: action.payload,
      };
      return state;

    case actionType.SET_BILL_TRANSACTIONS_FAILED:
      state = {
        ...state,
        fetchTransactionsFailed: true,
      };
      return state;

    case actionType.LOADING_BILL_TRANSACTIONS:
      state = {
        ...state,
        isLoadingTransactions: action.payload,
      };
      return state;
    default:
      return state;
  }
};
