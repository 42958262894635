import { Route, useRouteMatch } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { appInsights } from "../../components/AppInsight/AppInsight";
import Pay from "./Pay";
import PayBills from "./PayBills/PayBills";

const PayRoot = () => {
  const match = useRouteMatch();
  useEffect(() => {
    appInsights.trackPageView({ name: "PayRoot.tsx", isLoggedIn: true });
  }, []);
  return (
    <Fragment>
      <Route exact path={match.path} component={Pay} />
      <Route exact path={`${match.path}/bills`} component={PayBills} />
    </Fragment>
  );
};

export default PayRoot;
