import { TWalletTopUp } from "../../interfaces/wallet";
import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";
import { IWalletModel } from "../../interfaces/billPayment";

export const initialState: TWalletTopUp = {
  currentStep: 1,
  currency: "",
  walletId: null,
  amount: "",
  reference: "",
  minimumTopUpAmounts: [],
};

const walletState: IWalletModel = {
  wallet: null,
  isLoadingWallet: false,
  setWalletFailed: false,
};

export const getWalletTopUp = (
  state: TWalletTopUp = initialState,
  action: ReduxActionModel
): TWalletTopUp => {
  switch (action.type) {
    case actionType.SET_MINIMUM_TOP_UP_AMOUNTS:
      state = {
        ...state,
        ...action.payload,
      };
      return state;
    case actionType.SET_WALLET_TOPUP_INFO:
      state = {
        ...state,
        ...action.payload,
      };
      return state;
    case actionType.RESET_WALLET_TOPUP:
      return initialState;
    default:
      return state;
  }
};

export const getNairaWalletReducer = (
  state = walletState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_WALLET:
      state = {
        ...state,
        wallet: action.payload,
      };
      return state;

    case actionType.LOADING_WALLET:
      state = {
        ...state,
        isLoadingWallet: action.payload,
      };
      return state;

    case actionType.SET_WALLET_FAILED:
      state = {
        ...state,
        setWalletFailed: action.payload,
      };
      return state;
    default:
      return state;
  }
};
