import React from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { ChangeHandler } from "react-hook-form";

interface ICustomTextAreaProps {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: undefined | number;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean;
  onChange?: ChangeHandler;
  min?: number;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  extraLabel?: string;
  bottomLabel?: string;
  rows?: number;
}

const CustomTextArea: React.FC<ICustomTextAreaProps> = React.forwardRef(
  (
    {
      name,
      placeholder,
      label,
      errors,
      maxLength,
      defaultValue,
      minLength,
      onChange,
      showRequiredIcon,
      extraLabel,
      bottomLabel,
      rows,
      ...otherProps
    },
    ref: any
  ) => {
    return (
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <label className="form-label">{label}</label>
          {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
          {extraLabel && extraLabel.length && (
            <label className="form-label ml-2">
              <small className="crimson">{extraLabel}</small>
            </label>
          )}
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            name={name}
            placeholder={placeholder}
            onKeyPress={enforceMaxLength}
            maxLength={maxLength}
            minLength={minLength}
            defaultValue={defaultValue}
            onChange={onChange}
            rows={rows || 3}
            ref={ref}
            {...otherProps}
          />
          {bottomLabel && bottomLabel.length && !errors && (
            <small>{bottomLabel}</small>
          )}
          {errors && <ErrorHandler errors={errors} />}
        </div>
      </div>
    );
  }
);
export default CustomTextArea;
