import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TBank } from "../../../../../interfaces/bankTransfer";
import { appInsights } from "../../../../AppInsight/AppInsight";
import CustomInputFieldFormItem from "../../../../CustomHTMLElements/CustomInputFieldFormItem";
import { TransferNGNSteps } from "../NGNTransferDrawer.component";
import styles from "./NGNTransferDrawerBankList.module.scss";
import { RiSearch2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux";

interface ITransferNGNModalStepTwoBanks {
  setCurrentStep: Dispatch<SetStateAction<TransferNGNSteps>>;
  setSelectBankName: Dispatch<SetStateAction<boolean>>;
  setBankCode: Dispatch<SetStateAction<string | null>>;
  getBeneficiaryShortName: (name: string | undefined) => string | any;
}
const NGNTransferDrawerBankList: React.FC<ITransferNGNModalStepTwoBanks> = ({
  setCurrentStep,
  setSelectBankName,
  setBankCode,
  getBeneficiaryShortName,
}) => {
  const [searchBankQuery, setSearchBankQuery] = useState("");
  const { bankList } = useSelector((state: ReduxStoreModel) => state.bankList);

  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletTransferModalNGNStepTwoBanks.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleBankSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({
      value: e.target.value,
    });
    setSearchBankQuery(e.target.value);
  };
  const handlerTransferForm = () => {
    setSelectBankName(true);
    setCurrentStep(TransferNGNSteps.BankTransferFormScreen);
  };
  return (
    <div>
      <div className="modal-body p-0 ">
        {/* search by bank */}
        <div className="w-100">
          <CustomInputFieldFormItem
            size="large"
            maxLength={15}
            placeholder="Search for a bank"
            onChange={handleBankSearch}
            prefix={<RiSearch2Line size="20px" className="text-muted" />}
            fieldName="search"
            allowClear
          />
        </div>
        <div className={styles.list_of_banks_container}>
          {/* List of Banks */}
          <ul className={styles.listOfBanks}>
            {bankList
              .filter(({ bank_name }: TBank) => {
                return bank_name.toLowerCase().includes(searchBankQuery);
              })
              .map(
                ({ bank_code, bank_name, svg_logo_url }: TBank, id: number) => {
                  return (
                    <div>
                      {
                        <li
                          key={id}
                          className={styles.list_of_banks_li}
                          onClick={() => {
                            setBankCode(bank_code);
                            handlerTransferForm();
                          }}
                        >
                          <div className={styles.list_of_banks_sub_container}>
                            {svg_logo_url ? (
                              <img
                                src={svg_logo_url}
                                alt="list of banks icon"
                                className={styles.list_of_bank_logo}
                              />
                            ) : (
                              <div className={styles.list_of_bank_no_logo}>
                                {getBeneficiaryShortName(bank_name)?.length > 2
                                  ? `${getBeneficiaryShortName(bank_name)[0]}${
                                      getBeneficiaryShortName(bank_name)[1]
                                    }`
                                  : getBeneficiaryShortName(bank_name)}
                              </div>
                            )}
                            <span className={styles.bank_name}>
                              {bank_name}
                            </span>{" "}
                          </div>
                        </li>
                      }
                    </div>
                  );
                }
              )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NGNTransferDrawerBankList;
