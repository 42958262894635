import styles from "../StepTwo/StepTwo.module.scss";
import SignUpTabs from "../../../components/SignUpTabs/SignUpTabs.component";
import { stepsProp } from "../StepThree/StepThree";
import { useEffect } from "react";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { useDispatch, useSelector } from "react-redux";
import { setSignUp } from "../../../redux/actions";
import { ReduxStoreModel } from "../../../interfaces/redux";

const StepTwo = ({ funderType, setFunderType }: stepsProp) => {
  const signUpDetails = useSelector((state: ReduxStoreModel) => state.signUp);
  const dispatch = useDispatch();
  useEffect(() => {
    appInsights.trackPageView({
      name: "StepTwo.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className="my-5">
      <h3 className="font-weight-bold mb-3 color-dark-blue">
        Start Investing Today
      </h3>
      <p>What kind of account do you want to create?</p>
      <SignUpTabs funderType={funderType} setFunderType={setFunderType} />

      <button
        className={styles.continueButton}
        onClick={() => dispatch(setSignUp({ ...signUpDetails, step: 3 }))}
      >
        Continue
      </button>
    </div>
  );
};

export default StepTwo;
