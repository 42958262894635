import React, { Fragment } from "react";
import PinInput from "react-pin-input";
import { numberRegExp } from "../../helpers/yupValidators";

interface ICustomPinInputField {
  length: number;
  hide?: boolean;
  setValue: (value: string) => void;
  focus: boolean;
  initialValue?: string;
  label?: string;
}

export const CustomPinInputField: React.FC<ICustomPinInputField> = ({
  focus,
  length,
  hide,
  setValue,
  initialValue,
  label,
}) => {
  return (
    <Fragment>
      <div className="mb-4">
        <h6 className="text-left w-100 mb-5">{label}</h6>
        <PinInput
          length={length}
          initialValue={initialValue || ""}
          secret={true || hide}
          onChange={(value) => {
            setValue(value);
          }}
          type="numeric"
          inputMode="number"
          regexCriteria={numberRegExp}
          autoSelect={true}
          focus={focus}
          /* onComplete={(value, index) => {
            setValue(value);
          }}*/
        />
      </div>
    </Fragment>
  );
};
