import React from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { ChangeHandler } from "react-hook-form";

interface ICustomSelectDropdownProps {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: undefined | number;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean;
  onChange?: ChangeHandler;
  min?: number;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  extraLabel?: string;
  bottomLabel?: string;
  rows?: number;
}

const CustomSelectDropdown: React.FC<ICustomSelectDropdownProps> =
  React.forwardRef(
    (
      {
        name,
        children,
        label,
        errors,
        value,
        onChange,
        showRequiredIcon,
        extraLabel,
        readOnly,
        defaultValue,
      },
      ref: any
    ) => {
      return (
        <>
          <div className="form-group">
            <div className="d-flex align-items-center">
              <label className="form-label">{label}</label>
              {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
              {extraLabel && extraLabel.length && (
                <label className="form-label ml-2">
                  <small className="crimson">{extraLabel}</small>
                </label>
              )}
            </div>
            <select
              className="form-control text-capitalize"
              name={name}
              ref={ref}
              value={value}
              onChange={onChange}
              disabled={readOnly}
              defaultValue={defaultValue}
            >
              {children}
            </select>
            <ErrorHandler errors={errors} />
          </div>
        </>
      );
    }
  );

export default CustomSelectDropdown;
