import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "./../../../helpers/routes";
import { TCurrencyCodes } from "../../../interfaces/currencies";
import { PortfolioCard } from "./../../../components/PortfolioCard/PortfolioCard.component";
import Loader from "../../../components/Loader/Loader.component";
// import { CURRENCIES } from "./../../../helpers/currencies";
import usePortfolios from "./../../../hooks/custom-hooks/usePortfolios";
import { formatDate } from "./../../../helpers/formatDate";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux";

export default function Portfolios() {
  const currencyList = useSelector(
    (state: ReduxStoreModel) => state.currencies.availableCurrencies
  );
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const [activeTab, setActiveTab] = useState("");
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const walletId = activeTab !== "USD" ? lcy_wallet_id : usd_wallet_id;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const defaultActiveTab = searchParams.get("activeTab") as TCurrencyCodes;

  useEffect(() => {
    if (defaultActiveTab) {
      setActiveTab(defaultActiveTab);
    } else {
      if (currencyList.length > 0) {
        setActiveTab(currencyList[0]?.currencyCode);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    appInsights.trackPageView({ name: "Portfolios.tsx", isLoggedIn: true });
  }, []);

  const { isLoading, error, data } = usePortfolios({ funderId, walletId });

  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <div className="title-align">
        <div className="page-title">Portfolio</div>
        <div>
          <Link className="btn btn-sm advancly-btn" to={ROUTES.INVESTMENTS}>
            New Investment
          </Link>
        </div>
      </div>

      {/* Currency Tabs  */}
      <div className="alignToggleTabItems">
        <div className="d-flex justify-content-between">
          {currencyList &&
            currencyList?.length > 0 &&
            currencyList.map((item) => (
              <div
                className={`tabItem ${
                  activeTab === item.currencyCode && "activeTab"
                }`}
                onClick={() => setActiveTab(item.currencyCode)}
                key={item.currencyCode}
              >
                {item.currencyCode} Investments
              </div>
            ))}
        </div>
      </div>
      <Fragment>
        {isLoading ? (
          <div className="no-advancly-data">
            <Loader type="lg" variant="blue" />
            <div className="mt-3">Updating portfolio information...</div>
          </div>
        ) : !data ||
          !data?.length ||
          data?.filter(
            (portfolio: any) =>
              portfolio?.currency?.toLowerCase() === activeTab?.toLowerCase()
          ).length === 0 ||
          error ? (
          <div className="no-advancly-data">
            <i className="ri-briefcase-4-fill ri-3x"></i>
            <div className="color-bg-blue py-2 font-weight-bold">
              You have no {activeTab} investments
            </div>
            <div>Click on the “New Investment” button to get started</div>
          </div>
        ) : (
          <div className="three-grid-layout">
            {data
              ?.filter(
                (portfolio: any) =>
                  portfolio?.currency?.toLowerCase() ===
                  activeTab?.toLowerCase()
              )
              ?.map((portfolio: any) => {
                const {
                  investment_product_name,
                  currency,
                  investment_status,
                  principal_amount,
                  effective_date,
                  maturity_date,
                  id,
                  tenure,
                  reference_number,
                } = portfolio;
                return (
                  <PortfolioCard
                    portfolio={{
                      title: investment_product_name,
                      startDate: formatDate(effective_date),
                      endDate: formatDate(maturity_date),
                      amount: principal_amount,
                      currencyCode: currency || activeTab,
                      id: id,
                      status: investment_status,
                      tenure,
                    }}
                    key={reference_number}
                  />
                );
              })}
          </div>
        )}
      </Fragment>
    </div>
  );
}
