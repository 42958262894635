import { Dispatch, Fragment, SetStateAction } from "react";
import ProgressIndicator from "../../ProgressIndicator/ProgressIndicator.component";
import InvestmentModalStepOne from "./InvestmentModalStepOne/InvestmentModalStepOne.component";
import InvestmentModalStepTwo from "./InvestmentModalStepTwo/InvestmentModalStepTwo.component";
import InvestmentModalStepThree from "./InvestmentModalStepThree/InvestmentModalStepThree.component";
import TermsAndConditionModalOnline from "./TermsAndConditionsModalOnline/TermsAndConditionOnline";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux";
// import { useActions } from "../../../hooks/redux-hooks/useActions";

interface IOnlineInvestmentLanding {
  closeModal: () => void;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const OnlineInvestmentLanding: React.FC<IOnlineInvestmentLanding> = ({
  closeModal,
  error,
  setError,
}) => {
  const currentStep = useSelector(
    (state: ReduxStoreModel) => state.investment.currentStep
  );
  // const { setInvestmentStep }=useActions();

  return (
    <Fragment>
      {(() => {
        switch (currentStep) {
          case 1:
            return (
              <WrapWithProgressIndicator>
                <InvestmentModalStepOne setError={setError} />
              </WrapWithProgressIndicator>
            );
          case 2:
            return (
              <WrapWithProgressIndicator>
                <TermsAndConditionModalOnline />
              </WrapWithProgressIndicator>
            );
          // case 2:
          //   return (
          //     <WrapWithProgressIndicator>
          //       <div>
          //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos dro dicta vitae beatae?
          //       <button type="submit" onClick={()=>{ setInvestmentStep(currentStep + 1);}} className="btn btn-sm advancly-white-btn">
          //   Next
          // </button>
          //       </div>
          //     </WrapWithProgressIndicator>
          //   );
          case 3:
            return (
              <WrapWithProgressIndicator>
                <InvestmentModalStepTwo setError={setError} />
              </WrapWithProgressIndicator>
            );
          case 4:
            return (
              <WrapWithProgressIndicator>
                <InvestmentModalStepThree
                  closeModal={closeModal}
                  setError={setError}
                  error={error}
                />
              </WrapWithProgressIndicator>
            );
          default:
            return null;
        }
      })()}
    </Fragment>
  );
};

const WrapWithProgressIndicator: React.FC<{}> = ({ children }) => {
  return (
    <Fragment>
      <ProgressIndicator />
      {children}
    </Fragment>
  );
};
export default OnlineInvestmentLanding;
