import { getData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useQuery } from "react-query";
import { ESupportedBills } from "../../Constants/mock";

type paramProps = {
  operator_id: string;
  bill_name: string;
};

const getOperatorProducts = async ({ queryKey }: any) => {
  const { operator_id, bill_name } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.GET_PROVIDER_LIST_AND_PRODUCTS}/${operator_id}/products?bill_name=${bill_name}`
  );
  return data;
};

export default function useOperatorProducts({
  operator_id,
  bill_name,
}: paramProps) {
  return useQuery(
    ["getOperatorProducts", { operator_id, bill_name }],
    getOperatorProducts,
    {
      // refetchOnWindowFocus: true, // Refetch on window focus
      // refetchInterval: 5 * 1000 * 60, // Fetch balance every five minutes
      enabled: operator_id && bill_name ? true : false,
    }
  );
}

const validateMeterNumber = async ({ queryKey }: any) => {
  const { operator_id, device_number, meter_type } = queryKey[1];

  const { data } = await getData(
    `${apiEndpoints.VALIDATE_DEVICE_BEFORE_PURCHASE}?operator_id=${operator_id}&device_number=${device_number}&bill_name=${ESupportedBills.ELECTRICITY}&meter_type=${meter_type}`
  );
  return data;
};

export function useValidateMeterNumber({
  operator_id,
  device_number,
  meter_type,
}: {
  operator_id: string;
  device_number: string;
  meter_type: string;
}) {
  return useQuery(
    ["validateMeterNumber", { operator_id, device_number, meter_type }],
    validateMeterNumber,
    {
      enabled: operator_id && device_number && meter_type ? true : false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

const validateTVDeviceNumber = async ({ queryKey }: any) => {
  const { operator_id, device_number } = queryKey[1];

  const { data } = await getData(
    `${apiEndpoints.VALIDATE_DEVICE_BEFORE_PURCHASE}?operator_id=${operator_id}&device_number=${device_number}&bill_name=${ESupportedBills.TELEVISION}`
  );
  return data;
};

export function useValidateTVDeviceNumber({
  operator_id,
  device_number,
}: {
  operator_id: string;
  device_number: string;
}) {
  return useQuery(
    ["validateTVDeviceNumber", { operator_id, device_number }],
    validateTVDeviceNumber,
    {
      enabled: operator_id && device_number ? true : false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
