import React, { Dispatch, SetStateAction } from "react";
import { ReactComponent as RightArrowNew } from "../../../../../assets/img/RightArrowNew.svg";
import styles from "./NGNTransferDrawerStepOne.module.scss";
import { TransferNGNSteps } from "../NGNTransferDrawer.component";
import useRecentRecipients from "../../../../../hooks/custom-hooks/useRecentRecipients";
import { Alert } from "reactstrap";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { Divider, Spin } from "antd";
import { BsBank2, BsPeople } from "react-icons/bs";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux";

export interface IBeneficiaries {
  id?: number;
  recipient_account_number: string | null;
  recipient_account_name: string | null | undefined;
  recipient_bank_code: string | null;
  recipient_bank_name?: string;
}
interface ITransferNGNModalStepOne {
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  getBeneficiaryShortName: any;
  setSelectBeneficiary: Dispatch<SetStateAction<boolean>>;
  setSelectBeneficiaryData: Dispatch<SetStateAction<IBeneficiaries | null>>;
  activeTab: string;
}
const NGNTransferDrawerStepOne: React.FC<ITransferNGNModalStepOne> = ({
  setCurrentStep,
  getBeneficiaryShortName,
  setSelectBeneficiary,
  setSelectBeneficiaryData,
  activeTab,
}) => {
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );

  const handlerTransferForm = () => {
    setSelectBeneficiary(true);
    setCurrentStep(TransferNGNSteps.BankTransferFormScreen);
  };
  const {
    data: recentRecipientsData,
    isLoading: recentRecipientsLoading,
    error: recentRecipientError,
  } = useRecentRecipients({
    walletId: activeTab === "USD" ? usd_wallet_id : lcy_wallet_id,
  });

  return (
    <div>
      <div className="actions">
        <div
          className={styles.choose_a_bank_wrapper}
          onClick={() => setCurrentStep(TransferNGNSteps.ChooseABankScreen)}
        >
          <div className={styles.choose_sub_wrapper}>
            <div className="icon">
              <BsBank2 />
            </div>
            <div className={styles.choose_text_wrapper}>
              <p className={styles.choose_heading}>Choose bank</p>
              <p className={styles.choose_text1}>
                Select a bank to transfer to
              </p>
            </div>
          </div>
          <div className={styles.rigtArrow}>
            <RightArrowNew />
          </div>
        </div>
        <br />
        <br />
        <div
          className={styles.choose_a_beneficiary}
          onClick={() =>
            setCurrentStep(TransferNGNSteps.ChooseABeneficiaryScreen)
          }
        >
          <div className={styles.choose_sub_wrapper}>
            <div className="icon">
              <BsPeople />
            </div>
            <div className={styles.choose_text_wrapper}>
              <p className={styles.choose_heading}>Choose a beneficiary</p>
              <p className={styles.choose_text1}>
                Select a saved beneficiary to send money to{" "}
              </p>
            </div>
          </div>
          <div className={styles.rigtArrow}>
            <RightArrowNew />
          </div>
        </div>
      </div>
      <Divider />
      {/* Recent transactions */}
      <div className={styles.recent_transactions_wrapper}>
        {recentRecipientError && (
          <div>
            <Alert message={errorHandler(recentRecipientError)} />
          </div>
        )}

        <h6>Recent Transactions</h6>
        <div>
          <div className={styles.recentBankTransactionsName}>
            {recentRecipientsLoading ? (
              <div className={styles.pageLoader}>
                <Spin />
              </div>
            ) : (
              recentRecipientsData
                ?.slice(0, 3)
                ?.map(
                  ({
                    id,
                    recipient_account_number,
                    recipient_account_name,
                    recipient_bank_code,
                  }: IBeneficiaries) => {
                    return (
                      <div
                        className={styles.recentTransactionGroup}
                        key={id}
                        onClick={() => {
                          setSelectBeneficiaryData({
                            recipient_account_name,
                            recipient_account_number,
                            recipient_bank_code,
                          });
                          handlerTransferForm();
                        }}
                      >
                        <div className={styles.bank_logo}>
                          {recipient_account_name &&
                          recipient_account_name?.length > 2
                            ? `${
                                getBeneficiaryShortName(
                                  recipient_account_name
                                )[0]
                              }${
                                getBeneficiaryShortName(
                                  recipient_account_name
                                )[1]
                              }`
                            : getBeneficiaryShortName("Advancly")}
                        </div>
                        <p className="text-align-center">
                          {recipient_account_name
                            ? recipient_account_name
                            : "UnAvailable"}
                        </p>
                      </div>
                    );
                  }
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NGNTransferDrawerStepOne;
