export enum actionType {
  SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER",
  SET_LOGGED_IN_USER_TOKEN = "SET_LOGGED_IN_USER_TOKEN",
  CLEAR_LOGGED_IN_USER = "CLEAR_LOGGED_IN_USER",
  SET_SIGN_UP = "SET_SIGN_UP",
  RESET_SIGN_UP = "RESET_SIGN_UP",
  SET_INVESTMENT_TYPE = "SET_INVESTMENT_TYPE",
  SET_INVESTMENT_STEP = "SET_INVESTMENT_STEP",
  SET_INVESTMENT_AGREEMENT_CHECK = "SET_INVESTMENT_AGREEMENT_CHECK",
  SELECT_INVESTMENT = "SELECT_INVESTMENT",
  SET_ONLINE_INVESTMENT_INFO = "SET_ONLINE_INVESTMENT_INFO",
  SET_OFFLINE_INVESTMENT_INFO = "SET_OFFLINE_INVESTMENT_INFO",
  RESET_INVESTMENT = "RESET_INVESTMENT",
  SET_RESET_PASSWORD_INFO = "SET_RESET_PASSWORD_INFO",
  SET_WITHDRAWAL_INFO = "SET_WITHDRAWAL_INFO",
  RESET_WITHDRAWAL = "RESET_WITHDRAWAL",
  SET_WALLET_TOPUP_INFO = "SET_WALLET_TOPUP_INFO",
  RESET_WALLET_TOPUP = "RESET_WALLET_TOPUP",
  SHOW_BALANCE = "SHOW_BALANCE",
  SET_INVESTMENT_ACCOUNTS = "SET_INVESTMENT_ACCOUNTS",
  SET_MINIMUM_TOP_UP_AMOUNTS = "SET_MINIMUM_TOP_UP_AMOUNTS",
  SET_USER_AVAILABLE_CURRENCIES = "SET_USER_AVAILABLE_CURRENCIES",
  LOADING_USER_AVAILABLE_CURRENCIES = "LOADING_USER_AVAILABLE_CURRENCIES",
  SET_USER_AVAILABLE_CURRENCIES_FAILED = "SET_USER_AVAILABLE_CURRENCIES_FAILED",
  LOADING_BANK_LIST = "LOADING_BANK_LIST",
  SET_BANK_LIST = "SET_BANK_LIST",
  SET_BANK_LIST_FAILED = "SET_BANK_LIST_FAILED",
  LOADING_BENEFICIARY_LIST = "LOADING_BENEFICIARY_LIST",
  SET_BENEFICIARY_LIST = "SET_BENEFICIARY_LIST",
  SET_BENEFICIARY_LIST_FAILED = "SET_BENEFICIARY_LIST_FAILED",
  LOADING_SUPPORTED_BILLS = "LOADING_SUPPORTED_BILLS",
  SET_SUPPORTED_BILLS = "SET_SUPPORTED_BILLS",
  SET_SUPPORTED_BILLS_FAILED = "SET_SUPPORTED_BILLS_FAILED",
  LOADING_TV_PROVIDERS = "LOADING_TV_PROVIDERS",
  SET_TV_PROVIDERS = "SET_TV_PROVIDERS",
  SET_TV_PROVIDERS_FAILED = "SET_TV_PROVIDERS_FAILED",
  LOADING_AIRTIME_PROVIDERS = "LOADING_AIRTIME_PROVIDERS",
  SET_AIRTIME_PROVIDERS = "SET_AIRTIME_PROVIDERS",
  SET_AIRTIME_PROVIDERS_FAILED = "SET_AIRTIME_PROVIDERS_FAILED",
  LOADING_ELECTRICITY_PROVIDERS = "LOADING_ELECTRICITY_PROVIDERS",
  SET_ELECTRICITY_PROVIDERS = "SET_ELECTRICITY_PROVIDERS",
  SET_ELECTRICITY_PROVIDERS_FAILED = "SET_ELECTRICITY_PROVIDER_BILLS_FAILED",
  LOADING_BILL_BENEFICIARY_LIST = "LOADING_BILL_BENEFICIARY_LIST",
  SET_BILL_BENEFICIARY_LIST = "SET_BILL_BENEFICIARY_LIST",
  SET_BILL_BENEFICIARY_LIST_FAILED = "SET_BILL_BENEFICIARY_LIST_FAILED",
  LOADING_BILL_TRANSACTIONS = "LOADING_BILL_TRANSACTIONS",
  SET_BILL_TRANSACTIONS = "SET_BILL_TRANSACTIONS",
  SET_BILL_TRANSACTIONS_FAILED = "SET_BILL_BENEFICIARY_LIST_FAILED",
  LOADING_WALLET = "LOADING_WALLET",
  SET_WALLET = "SET_WALLET",
  SET_WALLET_FAILED = "SET_WALLET_FAILED",
}
