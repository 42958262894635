import { IBankList, IBeneficiaryList } from "../../interfaces/bankTransfer";
import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";

export const initialState: IBankList = {
  isLoadingBankList: false,
  bankList: [],
  fetchBankListFailed: false,
};

export const initialBeneficiaryState: IBeneficiaryList = {
  beneficiaryList: [],
  fetchBeneficiaryListFailed: false,
  isLoadingBeneficiaryList: false,
};

export const getBankListReducer = (
  state = initialState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_BANK_LIST:
      state = {
        ...state,
        bankList: action.payload,
      };
      return state;

    case actionType.SET_BANK_LIST_FAILED:
      state = {
        ...state,
        fetchBankListFailed: true,
      };
      return state;

    case actionType.LOADING_BANK_LIST:
      state = {
        ...state,
        isLoadingBankList: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export const getBeneficiaryListReducer = (
  state = initialBeneficiaryState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_BENEFICIARY_LIST:
      state = {
        ...state,
        beneficiaryList: action.payload,
      };
      return state;

    case actionType.SET_BENEFICIARY_LIST_FAILED:
      state = {
        ...state,
        fetchBeneficiaryListFailed: true,
      };
      return state;

    case actionType.LOADING_BENEFICIARY_LIST:
      state = {
        ...state,
        isLoadingBeneficiaryList: action.payload,
      };
      return state;

    default:
      return state;
  }
};
