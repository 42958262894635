import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

const getBanks = async () => {
  const data = await getData(apiEndpoints.ALL_BANKS);
  return data;
};

export default function useBanks() {
  return useQuery(["getBanks"], getBanks);
}
