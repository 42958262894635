import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  walletId: number | string;
};

const getRecentRecipients = async ({ queryKey }: any) => {
  const { walletId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.DIGITAL_BANK_RECENT_RECEPIENT}/${walletId}`
  );
  return data;
};

export default function useRecentRecipients({ walletId }: paramProps) {
  return useQuery(["getRecentRecipients", { walletId }], getRecentRecipients, {
    refetchOnWindowFocus: false, // Refetch on window focus
  });
}
