import React, { useEffect, useRef } from "react";
import ReactNotificationAlert from "react-notification-alert";

interface AlertOptions {
  place?: "tl" | "tc" | "tr" | "bl" | "bc" | "br";
  message: string | any;
  type?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  icon?: string;
  autoDismiss?: number;
}

const Alert: React.FC<AlertOptions> = ({
  message,
  type,
  place,
  autoDismiss,
  icon,
}) => {
  useEffect(() => {
    notify({ message, type, place, autoDismiss, icon });
    // eslint-disable-next-line
  }, []);

  const alert = useRef<null | ReactNotificationAlert>(null);
  const notify = ({ message, type, place }: AlertOptions) => {
    const options = {
      place: place || "tc",
      message,
      type: type || "danger",
      autoDismiss: 700,
      icon: "",
    };
    if (alert && alert.current) {
      alert.current.notificationAlert(options);
    }
  };
  return (
    <div>
      <ReactNotificationAlert ref={alert} />
    </div>
  );
};

export default Alert;
