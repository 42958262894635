import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import CustomInputFieldFormItem from "../../../../CustomHTMLElements/CustomInputFieldFormItem";
import { TransferUSDSteps } from "../USDTransferDrawer.component";
import useBankAccountNameEnquiry from "../../../../../hooks/custom-hooks/useBankAccountNameEnquiry";
import Alert from "../../../../Alert/Alert.component";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { Button, Form, Input, InputNumber, message, Spin, Switch } from "antd";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux";
import formatMoney from "../../../../../helpers/formatMoney";

export interface IUSDReqBody {
  customer_id: string;

  saving_wallet_id: string;

  amount: number;

  narration: string;
  bank_code: string;

  recipient_bank_name: string;

  recipient_bank_account_number: string | number;

  recipient_bank_account_name: string;

  currency_code: string;
}

interface ITransferUSDModalStepTwo {
  closeModal: () => void;
  setCurrentStepUSD: Dispatch<SetStateAction<TransferUSDSteps>>;
  activeTab: string;
  setBankTransferReqBody: Dispatch<SetStateAction<IUSDReqBody>>;
  bankTransferReqBody: IUSDReqBody;
  setBeneficiary: Dispatch<SetStateAction<boolean>>;
  wallet: { gatewayBankCode: string };
  walletError: string | any;
  walletLoading: boolean;
}

const USDTransferDrawerStepOne: React.FC<ITransferUSDModalStepTwo> = ({
  closeModal,
  activeTab,
  bankTransferReqBody,
  setBankTransferReqBody,
  setCurrentStepUSD,
  setBeneficiary,
  walletError,
}) => {
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const { wallet } = useSelector((state: ReduxStoreModel) => state.wallet);
  const [form] = Form.useForm();
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );

  const [accountNumber, setAccountNumber] = useState("");

  const {
    data: bankAccountNameEnquiryData,
    isLoading: bankAccountNameEnquiryLoading,
    error: bankAccountNameEquiryError,
  } = useBankAccountNameEnquiry({
    bankCode: wallet?.gatewayBankCode,
    accountNumber,
    selectBeneficiaryData: null,
  });

  useEffect(() => {
    if (bankTransferReqBody) {
      const {
        amount,
        narration,
        recipient_bank_account_number,
        recipient_bank_account_name,
      } = bankTransferReqBody;
      form.setFieldsValue({
        account_number: recipient_bank_account_number,
        bank_name: "BishopGate",
        narration: narration,
        amount: amount,
        account_name: recipient_bank_account_name,
      });
    }
    if (bankAccountNameEquiryError) {
      message.error(errorHandler(bankAccountNameEquiryError));
    }
  }, [bankAccountNameEquiryError, bankTransferReqBody]);

  useEffect(() => {
    form.setFieldsValue({
      bank_name: "BishopGate",
      account_name:
        bankAccountNameEnquiryData?.account_name ||
        bankTransferReqBody?.recipient_bank_account_name,
    });
  }, [bankAccountNameEnquiryData]);

  const onSubmit = ({
    account_number,
    amount,
    bank_name,
    narration,
    save_beneficiary,
    account_name,
  }: any) => {
    if (save_beneficiary) {
      setBeneficiary(save_beneficiary);
    }
    setBankTransferReqBody({
      customer_id: funderId,

      saving_wallet_id: activeTab === "USD" ? usd_wallet_id : lcy_wallet_id,

      amount: amount,

      narration: narration,
      bank_code: wallet?.gatewayBankCode,

      recipient_bank_name: bank_name,

      recipient_bank_account_number: account_number,

      recipient_bank_account_name: account_name,

      currency_code: activeTab,
    });

    setCurrentStepUSD(TransferUSDSteps.ReviewPayment);
  };
  return (
    <div>
      <Fragment>
        <Fragment>
          {walletError && (
            <div>
              <Alert message={errorHandler(walletError)} />
            </div>
          )}

          <Form
            layout="vertical"
            name="normal_login"
            className="login-form "
            requiredMark
            onFinish={(values) => {
              onSubmit(values);
            }}
            onFinishFailed={() => null}
            size="large"
            form={form}
          >
            <CustomInputFieldFormItem
              fieldName="source_account"
              label="Source Account"
              initialValue={`${wallet?.accountNumber} - ${formatMoney(
                wallet?.availableBalance || 0,
                "NGN"
              )} (${wallet?.accountName})`}
              readOnly
              size="large"
            />
            <CustomInputFieldFormItem
              fieldName="bank_name"
              type="text"
              size="large"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Please enter bank name",
                },
              ]}
              disabled
            />
            <CustomInputFieldFormItem
              fieldName="account_number"
              type="number"
              maxLength={20}
              size="large"
              label="Enter Account number"
              rules={[
                {
                  required: true,
                  message: "Please enter your Account Number",
                },
              ]}
              onChange={(e) => setAccountNumber(e.target.value)}
            />

            <CustomInputFieldFormItem
              fieldName="account_name"
              size="large"
              label="Account Name"
              rules={[
                {
                  required: true,
                  message: "Account Name is required",
                },
              ]}
              disabled
              suffix={bankAccountNameEnquiryLoading ? <Spin /> : null}
            />

            <CustomInputFieldFormItem
              fieldName="amount"
              placeholder="Enter Amount"
              type="number"
              size="large"
              label="Enter Amount"
              rules={[
                {
                  required: true,
                  message: "Please enter an Amount to transfer",
                },
              ]}
              InputComponent={
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: "100%" }}
                />
              }
            />

            <CustomInputFieldFormItem
              fieldName="narration"
              placeholder="Narration"
              size="large"
              label="Narration"
              rules={[
                {
                  required: true,
                  message: "Please enter transfer narration",
                },
              ]}
              InputComponent={<Input.TextArea rows={2} />}
            />

            <CustomInputFieldFormItem
              fieldName="save_beneficiary"
              size="large"
              InputComponent={
                <div className="d-flex align-items-center mt-5">
                  <Switch
                    onChange={(value) => {
                      form.setFieldsValue({
                        save_beneficiary: value,
                      });
                    }}
                  />{" "}
                  <span className="ml-3">Save Beneficiary</span>
                </div>
              }
            />

            <Button
              htmlType="submit"
              type="primary"
              block
              className="mt-5"
              // disabled={bankAccountNameEnquiryLoading || !currentAccountName}
            >
              Continue
            </Button>
          </Form>
        </Fragment>
      </Fragment>
    </div>
  );
};

export default USDTransferDrawerStepOne;
