import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { ROUTES } from "../../helpers/routes";
import Dashboard from "../../pages/Dashboard/Dashboard";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import ProfileSettings from "../../pages/ProfileSettings/ProfileSettings";
import SectorsRoot from "../../pages/Sectors/SectorsRoot.component";
import Terms from "../../pages/TermsOfUse/TermsOfUse";
import AuthLayout from "../AuthLayout/AuthLayout.component";
import InvestmentsRoot from "../../pages/Investments/InvestmentsRoot";
import { CompleteSetupPrompt } from "./../CompleteSetupPrompt/CompleteSetupPrompt.component";
import PortfoliosRoot from "./../../pages/Portfolio/PortfoliosRoot";
import Wallets from "./../../pages/Wallets/Wallets/Wallets";
import UsersManagement from "../../pages/UsersManagement/UsersManagement";
import ContactPageRoot from "./../../pages/ContactPage/ContactPageRoot";
import { isAfter, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import PayRoot from "../../pages/Pay/PayRoot";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInfo } from "../../redux/actions";
import { ReduxStoreModel } from "../../interfaces/redux";

const TIMEOUT_WARNING = 1000 * 60 * 4;
const LOGOUT_AFTER_TIMEOUT = 1000 * 60 * 1;

const AuthRoutesRoot = () => {
  const dispatch = useDispatch();
  const [isPrompted, setIsPrompted] = useState(false);
  const { activated, session_timeout } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const token = useSelector((state: ReduxStoreModel) => state.user?.token);
  const history = useHistory();

  const forceLogout = (message?: string) => {
    dispatch(clearUserInfo());
    if (message) {
      alert(message);
    }
    const modals = Array.from(document.querySelectorAll(".modal-backdrop"));
    if (modals.length) {
      //@ts-ignore
      modals.map((modal) => (modal.style.display = "none"));
    }
    history.replace(ROUTES.LOGIN);
  };

  useEffect(() => {
    const timeoutFunction = () =>
      setTimeout(() => {
        forceLogout("Your session timed out.");
      }, LOGOUT_AFTER_TIMEOUT);
    if (isPrompted) {
      timeoutFunction();
    } else {
      clearTimeout(timeoutFunction());
    }

    return () => {
      clearTimeout(timeoutFunction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrompted]);

  function getUserLoginStatus() {
    setIsPrompted(true);
    setTimeout(() => {
      const status = window.confirm(
        `You have been idle for a long time and you will be logged out in the next few seconds. Do you want to remain logged in?`
      );
      if (status === true) {
        setIsPrompted(false);
        idleTimer.reset();
      } else {
        setIsPrompted(false);
        forceLogout();
      }
    }, 200);
  }

  const handleOnAction = () => {
    const isTokenExpired = isAfter(new Date(), parseISO(session_timeout));
    if (isTokenExpired) {
      forceLogout("Your session timed out.");
    }
  };

  const idleTimer = useIdleTimer({
    timeout:
      process.env.NODE_ENV === "development"
        ? 1000 * 60 * 5000
        : TIMEOUT_WARNING, // Timeout of 5 minutes except in development
    onIdle: getUserLoginStatus,
    onAction: handleOnAction,
    debounce: 500,
  });

  const { search } = useLocation();
  useEffect(() => {
    const redirectUrl = search?.split("?redirectUrl=")[1];
    if (redirectUrl && token) {
      return history.push(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (!token) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  return (
    <AuthLayout>
      {/*{!activated && <CompleteSetupPrompt />}*/}
      <Route exact path={ROUTES.FUNDER} component={Dashboard} />
      <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
      <Route path={ROUTES.SECTORS} component={SectorsRoot} />
      <Route path={ROUTES.PROFILE_SETTINGS} component={ProfileSettings} />
      <Route path={ROUTES.INVESTMENTS} component={InvestmentsRoot} />
      <Route path={ROUTES.PORTFOLIO} component={PortfoliosRoot} />
      <Route path={ROUTES.WALLET} component={Wallets} />
      <Route path={ROUTES.USERS} component={UsersManagement} />
      <Route path={ROUTES.TERMS_OF_USE} component={Terms} />
      <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
      <Route path={ROUTES.CONTACT_SUPPORT} component={ContactPageRoot} />
      <Route path={ROUTES.PAY} component={PayRoot} />
    </AuthLayout>
  );
};

export default AuthRoutesRoot;
