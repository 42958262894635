import { TResetPassword } from "../../interfaces/resetPassword";
import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";

export const initialState: TResetPassword = {
  email: "",
  stateToken: "",
};

export const resetPasswordReducer = (
  state: TResetPassword = initialState,
  action: ReduxActionModel
): TResetPassword => {
  switch (action.type) {
    // Set During Reset Password
    case actionType.SET_RESET_PASSWORD_INFO:
      state = { ...action.payload };
      return state;
    default:
      return state;
  }
};
