import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import styles from "./InvestmentLanding.module.scss";
import { TInvestmentType } from "../../../interfaces/investment";
import { useDispatch, useSelector } from "react-redux";
import { setInvestmentType } from "../../../redux/actions";
import { ReduxStoreModel } from "../../../interfaces/redux";

interface IInvestModalStepZero {
  closeModal: () => void;
  setError: Dispatch<SetStateAction<string>>;
}

export const InvestmentLanding: React.FC<IInvestModalStepZero> = ({
  closeModal,
  setError,
}) => {
  const currencyList = useSelector(
    (state: ReduxStoreModel) => state.currencies.availableCurrencies
  );
  const [type, setType] = useState<TInvestmentType>("");
  const dispatch = useDispatch();
  const lcy_product_id = useSelector(
    (state: ReduxStoreModel) =>
      state.investment.selectedInvestment.lcy_product_id
  );

  return (
    <Fragment>
      <div className="modal-header border-bottom-0 p-0 mb-4">
        <h5 className="page-subtitle">How would you like to Invest?</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {currencyList[0].currencyCode === "NGN" && lcy_product_id && (
        <div
          className={`${styles.modalCard} ${
            type === "online" && styles.active
          }`}
          onClick={() => setType("online")}
        >
          <div className={styles.radioButton}></div>
          <div>
            <h6 className="font-weight-bold">Online Investment</h6>
            <p>Invest in Advancly’s debt funding directly from the platform.</p>
          </div>
        </div>
      )}
      <div
        className={`${styles.modalCard} ${type === "offline" && styles.active}`}
        onClick={() => setType("offline")}
      >
        <div className={styles.radioButton}></div>
        <div>
          <h6 className="font-weight-bold">Log Offline Investment</h6>
          <p>
            Invested manually? Upload details of your investment so you can
            monitor the details of your investment on this platform.
          </p>
        </div>
      </div>
      <button
        className="btn advancly-btn btn-sm w-100 mt-3"
        onClick={() => {
          dispatch(setInvestmentType(type));
          setError("");
        }}
        disabled={!type}
      >
        Continue
      </button>
    </Fragment>
  );
};
