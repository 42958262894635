import CONFIG from "./config";

const key = CONFIG.ENCRYPTION_KEY;
const encryptData = require("simple-encryptor")(key);

export const encrypt = (data: any) => {
  const encrypted =
    process.env.NODE_ENV === "development"
      ? data
      : encryptData.encrypt(JSON.stringify(data));
  return encrypted;
};
export const decrypt = (data: string) => {
  const decrypted =
    process.env.NODE_ENV === "development"
      ? data
      : JSON.parse(encryptData.decrypt(data));
  return decrypted;
};
