// Custom phone number validation rule
import aba from "../assets/img/pay/electric/aba.png";
import eko from "../assets/img/pay/electric/ekedc.jpeg";
import abuja from "../assets/img/pay/electric/abuja.jpeg";
import ph from "../assets/img/pay/electric/ph.png";
import jos from "../assets/img/pay/electric/jos.jpeg";
import benin from "../assets/img/pay/electric/benin.png";
import kano from "../assets/img/pay/electric/kano.jpeg";
import kaduna from "../assets/img/pay/electric/kaduna.jpeg";
import enugu from "../assets/img/pay/electric/enugu.png";
import ibadan from "../assets/img/pay/electric/ibadan.png";
import ikeja from "../assets/img/pay/electric/ikeja.png";
import other from "../assets/img/pay/otherNetworks.png";

export const validatePhoneNumber = (_: any, value: string) => {
  const phoneNumberRegex = /^(0|\+234)?\d{10}$/; // 11 digits with or without "0" or "+234" country code
  if (!value || phoneNumberRegex.test(value.toString())) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid phone number");
};

export enum EElectricityProviders {
  PH = "PHEDC",
  AES_JOS = "AES_JEDC",
  BENIN = "BEDC",
  KANO = "KEDCO",
  ABUJA = "AEDC",
  EKO = "EKEDC",
  ENUGU = "EEDC",
  IKEJA = "IKEDC",
  IBEDC = "IBADAN",
  KADUNA = "KAEDCO",
  ABA = "APLE",
  CEL_JOS = "CEL_JEDC",
}

export const getLogo = (genericOperatorName: string | null) => {
  switch (genericOperatorName) {
    case EElectricityProviders.ABA:
      return aba;
    case EElectricityProviders.EKO:
      return eko;
    case EElectricityProviders.ABUJA:
      return abuja;
    case EElectricityProviders.PH:
      return ph;
    case EElectricityProviders.AES_JOS:
    case EElectricityProviders.CEL_JOS:
      return jos;
    case EElectricityProviders.BENIN:
      return benin;
    case EElectricityProviders.KANO:
      return kano;
    case EElectricityProviders.KADUNA:
      return kaduna;
    case EElectricityProviders.ENUGU:
      return enugu;
    case EElectricityProviders.IBEDC:
      return ibadan;
    case EElectricityProviders.IKEJA:
      return ikeja;
    default:
      return other;
  }
};
