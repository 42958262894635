import { Fragment, useEffect, useState } from "react";
import DeleteUserModal from "./DeleteUserModal.component";
import AddUserModal from "./AddUserModal";
import EditPermissionsModal from "./EditPermissionsModal";
import useUsers from "./../../hooks/custom-hooks/useUsers";
import NoTableData from "../../components/NoTableData/NoTableData.component";
import TableDataLoading from "../../components/TableDataLoading/TableDataLoading.component";
import Alert from "./../../components/Alert/Alert.component";
import { errorHandler } from "./../../helpers/errorHandler";
import { formatDate } from "./../../helpers/formatDate";
import { formatUserType, USER_TYPES } from "../../helpers/userTypes";
import { capitalizeFirstLetter } from "./../../helpers/capitalizeFirstLetter";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../interfaces/redux";

export default function UsersManagement() {
  const [userToEdit, setUserToEdit] = useState<{
    isActive?: boolean;
    userId?: number;
  }>({});
  const { isLoading, error, data } = useUsers({
    page: 1,
    size: 10,
  });

  const funderRole = useSelector((state: ReduxStoreModel) => state?.user?.role);
  useEffect(() => {
    appInsights.trackPageView({
      name: "UsersManagement.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <h5 className="page-title">User Management</h5>
      <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
        <div className="my-1">
          Invite people as admins on the platform, assign them roles and set
          their permissions.
        </div>
        <button
          className="btn btn-sm advancly-btn my-1"
          data-toggle="modal"
          data-target="#addUserModal"
        >
          Add User
        </button>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-lighter-grey">
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>Role</th>
              <th className="text-center">Date Onboarded</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableDataLoading />
            ) : !data?.data?.length ? (
              <NoTableData
                text="You have not created any user under this account"
                icon={<i className="ri-user-6-line"></i>}
              />
            ) : (
              <Fragment>
                {data?.data?.map((user: any) => {
                  const {
                    email,
                    isActive,
                    userType,
                    id,
                    firstName,
                    lastName,
                    dateJoined,
                    funderId,
                    userId,
                  } = user;

                  return (
                    <tr key={id}>
                      <th>
                        {capitalizeFirstLetter(firstName)}{" "}
                        {capitalizeFirstLetter(lastName)}
                      </th>
                      <td>{email}</td>
                      <td>
                        {funderId === userId
                          ? "Owner"
                          : formatUserType(userType)}
                      </td>
                      <td className="text-center">{formatDate(dateJoined)}</td>
                      <td className="dropdown">
                        {funderRole === USER_TYPES.FUNDER_ADMIN && (
                          <span
                            className="more-icon px-3 d-none" // Hide for now
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></span>
                        )}

                        <div
                          className="dropdown-menu p-0"
                          aria-labelledby="dropdownButton"
                        >
                          <small
                            className="dropdown-button"
                            data-toggle="modal"
                            data-target="#editPermissionsModal"
                            onClick={() =>
                              setUserToEdit({
                                isActive,
                                userId,
                              })
                            }
                          >
                            Update Role
                          </small>
                          <small
                            className="dropdown-button"
                            data-toggle="modal"
                            data-target="#deleteUserModal"
                            onClick={() =>
                              setUserToEdit({
                                isActive,
                                userId,
                              })
                            }
                          >
                            Remove User
                          </small>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            )}
          </tbody>
        </table>
      </div>
      <AddUserModal />
      <DeleteUserModal user={userToEdit} />
      <EditPermissionsModal user={userToEdit} />
    </div>
  );
}
