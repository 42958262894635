import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import styles from "./Sidebar.module.scss";
import { RiHomeFill } from "react-icons/ri";
import { AiOutlinePieChart, AiOutlineUser } from "react-icons/ai";
import { BiChart } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { IoSendOutline } from "react-icons/io5";
import { MdOutlineCreditScore } from "react-icons/md";
import referAd from "../../assets/img/dashboard/refer.png";

const Sidebar = () => {
  const match = useRouteMatch();

  return (
    <React.Fragment>
      <ul className={styles.sidebarElements}>
        {/* Dashboard */}
        <li>
          <NavLink
            className={`${styles.navLink} ${match.isExact && styles.selected}`}
            activeClassName={styles.selected}
            to={ROUTES.DASHBOARD}
          >
            <RiHomeFill />
            <span>Home</span>
          </NavLink>
        </li>
        {/* Pay  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.PAY}
          >
            <IoSendOutline
              style={{
                transform: "rotate(316deg)",
              }}
            />
            <span>Pay</span>
          </NavLink>
        </li>
        {/* Receive Money  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.WALLET}
          >
            <IoSendOutline
              style={{
                transform: "rotate(137deg)",
              }}
            />
            <span>Receive Money</span>
          </NavLink>
        </li>
        {/* Access Credit  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.WALLET}
          >
            <MdOutlineCreditScore />
            <span>Access Credit</span>
          </NavLink>
        </li>{" "}
        {/* Receive Money  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.CONTACT_SUPPORT}
          >
            <AiOutlinePieChart />
            <span>Track & Plan</span>
          </NavLink>
        </li>{" "}
        {/* Receive Money  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.CONTACT_SUPPORT}
          >
            <BiChart />
            <span>Make Returns</span>
          </NavLink>
        </li>{" "}
        {/* Receive Money  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.CONTACT_SUPPORT}
          >
            <BsPeople />
            <span>Manage Accounts</span>
          </NavLink>
        </li>{" "}
        {/* Receive Money  */}
        <li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.CONTACT_SUPPORT}
          >
            <AiOutlineUser />
            <span>Profile & Settings</span>
          </NavLink>
        </li>
        <img
          src={referAd}
          alt="Refer and earn"
          className="mw-100 mb-3 mb-md-0 ml-3 mt-5"
          style={{ width: 187, height: 170, objectFit: "contain" }}
        />
        {/*<li>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.selected}
            to={ROUTES.WALLET}
          >
            <i className="ri-wallet-3-fill"></i>
            <span>Wallet</span>
          </NavLink>
        </li>

        <div className={styles.sidebarLine}></div>

        {funderType === 2 && (
          <li>
            <NavLink
              className={styles.navLink}
              to={ROUTES.USERS}
              activeClassName={styles.selected}
            >
              <i className="ri-user-6-fill"></i>
              <span>User Management</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.PROFILE_SETTINGS}
            activeClassName={styles.selected}
          >
            <i className="ri-settings-3-fill"></i>
            <span>Profile Settings</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.navLink}
            to={ROUTES.CONTACT_SUPPORT}
            activeClassName={styles.selected}
          >
            <i className="ri-customer-service-2-fill"></i>
            <span>Contact Support</span>
          </NavLink>
        </li>*/}
      </ul>
    </React.Fragment>
  );
};
export default Sidebar;
