import React from "react";
import success from "../../../../assets/img/pay/transfer/transfer_success.png";
import { ItransferBody } from "../NGNTransferDrawer/NGNTransferDrawer.component";
import formatMoney from "../../../../helpers/formatMoney";
import { IUSDReqBody } from "../USDTransferDrawer/USDTransferDrawerStepOne/USDTransferDrawerStepOne";

interface IProps {
  bankTransferReqBody: ItransferBody | IUSDReqBody | null;
  closeModal: () => void;
  activeTab: string;
}

const TransferSuccessfulComponent = ({
  bankTransferReqBody,
  closeModal,
  activeTab,
}: IProps) => {
  return (
    <div className="mt-5">
      <div className="d-flex text-center justify-content-between align-items-center flex-column">
        <div>
          <img
            src={success}
            alt="transfer successful"
            className="mw-100 mb-4"
            style={{
              width: "114px",
              height: "114px",
            }}
          />
          <h4>Transfer Successful</h4>
          <p className="my-4">
            You have successfully done a transfer of{" "}
            <span className="font-weight-bold">
              {formatMoney(bankTransferReqBody?.amount || 0, activeTab)}
            </span>{" "}
            to{" "}
            <span className="font-weight-bold">
              {bankTransferReqBody?.recipient_bank_account_name}
            </span>
          </p>
        </div>
        <br />
        <br />
        <br />
        <button
          type="button"
          className="btn btn-sm advancly-btn btn-block"
          onClick={closeModal}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default TransferSuccessfulComponent;
