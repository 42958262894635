import React, { useState } from "react";
import Switch from "react-switch";

interface ISwitchButton {
  onChange: (e: any) => void;
  loading: boolean;
  checkedState?: boolean;
  disabled?: boolean;
}

const SwitchButton: React.FC<ISwitchButton> = ({
  onChange,
  loading,
  checkedState,
  disabled,
}) => {
  const [checked, setChecked] = useState(checkedState || false);
  const onToggleSwitch = (e: any) => {
    setChecked(!checked);
    onChange(e);
  };
  return (
    <div>
      <Switch
        onChange={onToggleSwitch}
        checked={checked}
        disabled={loading || disabled}
        activeBoxShadow=""
        onColor="#200865"
        offColor="#aa8eff"
      />
    </div>
  );
};
export default SwitchButton;
