import React, { Fragment, useEffect, useState } from "react";
import styles from "./WalletCard.module.scss";
import { guardUserAction } from "./../../helpers/guardUserActions";
import useWalletBalance from "./../../hooks/custom-hooks/useWalletBalance";
import formatMoney from "./../../helpers/formatMoney";
import Loader from "../Loader/Loader.component";
import Alert from "../Alert/Alert.component";
import { errorHandler } from "./../../helpers/errorHandler";
// import { CURRENCIES } from "./../../helpers/currencies";
import USDTransferDrawer from "../Pay/BankTransferDrawers/USDTransferDrawer/USDTransferDrawer.component";
import NGNTransferDrawer from "../Pay/BankTransferDrawers/NGNTransferDrawer/NGNTransferDrawer.component";
import WalletModal from "../../pages/Wallets/WalletModal/WalletModal.component";
import { useDispatch, useSelector } from "react-redux";
import { setWalletTopUpInfo } from "../../redux/actions";
import { ReduxStoreModel } from "../../interfaces/redux";

interface IWalletCard {
  title: string;
  activeTab: string;
}

const WalletCard: React.FC<IWalletCard> = ({ title, activeTab }) => {
  const [copied, setCopied] = useState("");
  const [accountDetailsCopy, setAccountDetailsCopy] = useState("Not Available");
  const isActivated = useSelector(
    (state: ReduxStoreModel) => state?.user?.activated
  );
  const walletTopUpInfo = useSelector(
    (state: ReduxStoreModel) => state.walletTopup
  );

  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const showBalance = useSelector(
    (state: ReduxStoreModel) => state.user.showBalance
  );
  const walletId = activeTab !== "USD" ? lcy_wallet_id : usd_wallet_id;
  // const walletId =
  //   activeTab === currencyList[0].currencyCode ? lcy_wallet_id : usd_wallet_id;

  const {
    data: wallet,
    error,
    isLoading,
  } = useWalletBalance({
    walletId,
    funderId,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setAccountDetailsCopy(
      `
       Account Name: ${wallet?.accountName}
       Account Number:${wallet?.accountNumber}
       Bank Name: ${wallet?.accountBankName}`
    );
  }, [accountDetailsCopy, wallet]);
  const copyText = () => {
    setCopied("Copied.");

    navigator.clipboard.writeText(accountDetailsCopy);
    setTimeout(() => setCopied(""), 3000);
  };
  return (
    <div className={`${styles.card} px-3 py-4`}>
      {copied && <Alert message={copied} type="info" />}

      {error && <Alert message={errorHandler(error)} />}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <div className={styles.title}>
            <span className="mr-2">{title} </span>
          </div>
          <div className={styles.value}>
            {isLoading ? (
              <Loader />
            ) : error ? null : (
              <Fragment>
                {showBalance
                  ? formatMoney(wallet?.availableBalance, activeTab)
                  : "****"}
              </Fragment>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          {(activeTab === "NGN" ||
            activeTab === "USD" ||
            activeTab === "KES") && (
            <div className={styles.account_details_styling}>
              <div>
                <p className={`${styles.acct_details_acct_num}`}>
                  {wallet?.accountNumber}
                </p>
                <p className={`${styles.acct_details_acct_name}`}>
                  {wallet?.accountName}{" "}
                </p>
              </div>
              <div>
                <span
                  className="font-weight-bold cursor-pointer pl-4 "
                  onClick={() => copyText()}
                >
                  <i className="ri-file-copy-fill color-blue "></i>
                </span>
              </div>
              <p className={`${styles.acct_details_bank_name} pl-4`}>
                {wallet?.accountBankName}
              </p>
            </div>
          )}
          {!isActivated ? (
            <button
              className="btn btn-sm advancly-white-btn mr-2"
              onClick={(e) => {
                guardUserAction(e, isActivated);
              }}
            >
              Transfer
            </button>
          ) : (
            (activeTab === "NGN" || activeTab === "USD") && (
              <button
                className="btn btn-sm advancly-white-btn mr-2"
                data-toggle="modal"
                data-target={
                  activeTab === "NGN"
                    ? "#transferModalNGN"
                    : "#transferModalUSD"
                }
                onClick={() => {
                  dispatch(
                    setWalletTopUpInfo({
                      ...walletTopUpInfo,
                      currency: activeTab,
                      walletId,
                      currentStep: 1,
                      amount: "",
                    })
                  );
                }}
              >
                Transfer
              </button>
            )
          )}

          {!isActivated ? (
            <button
              className="btn btn-sm advancly-white-btn mr-2"
              onClick={(e) => {
                guardUserAction(e, isActivated);
              }}
            >
              Add Money
            </button>
          ) : (
            activeTab === "NGN" && (
              <button
                className="btn btn-sm advancly-btn"
                data-toggle="modal"
                data-target="#walletModal"
              >
                Add Money
              </button>
            )
          )}
        </div>
      </div>
      <NGNTransferDrawer activeTab={activeTab} />
      <USDTransferDrawer activeTab={activeTab} />
      {/* this is for Add money modal */}
      <WalletModal walletDetails={wallet} />
    </div>
  );
};
export default WalletCard;
