import React from "react";
import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";

interface ICustomInputFieldFormItemProps {
  placeholder?: string;
  id?: string;
  label?: string;
  type?: string;
  initialValue?: any;
  size?: "small" | "middle" | "large";
  suffix?: string | React.ReactNode;
  prefix?: string | React.ReactNode;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  customInputContainerStyles?: React.CSSProperties;
  CustomInputFieldFormItemStyles?: React.CSSProperties;
  InputComponent?: React.ReactNode;
  fieldName: string;
  rules?: Rule[];
  disabled?: boolean;
  readOnly?: boolean;
  hasFeedback?: boolean;
  dependencies?: Array<string>;
  style?: React.CSSProperties | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  onBlur?: () => void;
  validateStatus?:
    | ""
    | "success"
    | "warning"
    | "error"
    | "validating"
    | undefined;
  help?: string;
  classname?: string;
  allowClear?: boolean;
}

export const CustomInputFieldFormItem = ({
  id = "",
  placeholder = "",
  label = "",
  type = "text",
  size = "middle",
  suffix,
  prefix,
  min,
  max,
  InputComponent,
  initialValue,
  disabled,
  readOnly,
  customInputContainerStyles,
  CustomInputFieldFormItemStyles,
  rules,
  fieldName,
  hasFeedback,
  dependencies,
  style,
  onBlur,
  value,
  onChange,
  validateStatus,
  help,
  allowClear,
  classname = "",
}: ICustomInputFieldFormItemProps) => {
  return (
    <>
      <div className="custom-input" style={customInputContainerStyles}>
        {InputComponent ? (
          <Form.Item
            initialValue={initialValue}
            label={label}
            name={fieldName}
            rules={rules}
            hasFeedback={hasFeedback}
            dependencies={dependencies}
            style={style}
            validateStatus={validateStatus}
            help={help}
          >
            {InputComponent}
          </Form.Item>
        ) : (
          <Form.Item
            initialValue={initialValue}
            label={label}
            name={fieldName}
            rules={rules}
            hasFeedback={hasFeedback}
            dependencies={dependencies}
            style={style}
            validateStatus={validateStatus}
            help={help}
          >
            <Input
              min={min}
              max={max}
              size={size}
              id={id}
              type={type}
              placeholder={placeholder}
              prefix={prefix}
              suffix={suffix}
              style={CustomInputFieldFormItemStyles}
              disabled={disabled}
              readOnly={readOnly}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              className={classname}
              allowClear={allowClear}
            />
          </Form.Item>
        )}
      </div>
    </>
  );
};

export default CustomInputFieldFormItem;
