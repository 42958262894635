import React, { Dispatch, SetStateAction } from "react";
import {
  ElectricitySteps,
  IElectricityDataRequestBody,
} from "../ElectricityDrawer.component";
import { Button, Card } from "antd";
import formatMoney from "../../../../helpers/formatMoney";
import { naira } from "../../../../Constants/mock";

interface IProps {
  requestBody: IElectricityDataRequestBody | null;
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
}

const ElectricityReviewPayment = ({ requestBody, setCurrentStep }: IProps) => {
  return (
    <div className="w-100">
      <Card style={{ width: "100%" }}>
        <h4 className="text-center">
          {formatMoney(requestBody?.amount || 0, naira)}
        </h4>
        <p className="text-center">Amount Payable</p>
        <div className="d-flex justify-content-between">
          <p>Your Provider</p>
          <p className="font-weight-bold">{requestBody?.packageTitle}</p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Meter Type</p>
          <p className="font-weight-bold">{requestBody?.meterType}</p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Meter Number</p>
          <p className="font-weight-bold">{requestBody?.deviceNumber}</p>
        </div>
      </Card>
      <Button
        block
        type="primary"
        className="mt-5"
        size="large"
        onClick={() => setCurrentStep(ElectricitySteps.TransactionPinScreen)}
      >
        Continue
      </Button>
    </div>
  );
};

export default ElectricityReviewPayment;
