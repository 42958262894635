import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import { appInsights } from "../../../AppInsight/AppInsight";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import { RiSearch2Line } from "react-icons/ri";
import styles from "../../BankTransferDrawers/NGNTransferDrawer/WithdrawalModalStepTwo/NGNTransferDrawerBankList.module.scss";
import { TVSteps } from "../TVDrawer.component";
import dstv from "../../../../assets/img/pay/dstv.png";
import gotv from "../../../../assets/img/pay/gotv.png";
import startimes from "../../../../assets/img/pay/startimes.png";
import other from "../../../../assets/img/pay/otherNetworks.png";

export enum ETVProviders {
  DSTV = "dstv",
  GOTV = "gotv",
  STARTIMES = "startimes",
}

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  setOperatorId: Dispatch<SetStateAction<string>>;
  activeTab: string;
}
const TVProviderListDrawer = ({
  activeTab,
  setCurrentStep,
  setOperatorId,
}: IProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { supportedTVProviders } = useSelector(
    (state: ReduxStoreModel) => state.tvProviders
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "TVProviderListDrawer.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({
      value: e.target.value,
    });
    setSearchQuery(e.target.value);
  };
  const handleSelectProvider = (operator_id: string) => {
    setOperatorId(operator_id);
    setCurrentStep(TVSteps.TVForm);
  };

  const getTVLogo = (name: string) => {
    const provider = name.toLowerCase();
    switch (provider) {
      case ETVProviders.DSTV:
        return dstv;
      case ETVProviders.GOTV:
        return gotv;
      case ETVProviders.STARTIMES:
        return startimes;
      default:
        return other;
    }
  };

  return (
    <div>
      <div className="modal-body p-0 ">
        <div className="w-100">
          <CustomInputFieldFormItem
            size="large"
            maxLength={15}
            placeholder="Search"
            onChange={handleSearch}
            prefix={<RiSearch2Line size="20px" className="text-muted" />}
            fieldName="search"
            allowClear
          />
        </div>
        <div className={styles.list_of_banks_container}>
          {/* List of Banks */}
          <ul className={styles.listOfBanks}>
            {supportedTVProviders &&
              supportedTVProviders
                .filter(({ name, operator_id }) => {
                  return name?.toLowerCase()?.includes(searchQuery);
                })
                .map(({ name, operator_id }) => {
                  return (
                    <div key={operator_id}>
                      {
                        <li
                          className={styles.list_of_banks_li}
                          onClick={() => {
                            handleSelectProvider(operator_id);
                          }}
                        >
                          <div className={styles.list_of_banks_sub_container}>
                            <img
                              src={getTVLogo(name)}
                              alt="list of network provider icon"
                              className={styles.list_of_bank_logo}
                            />
                            <p className={`${styles.bank_name} p-none`}>
                              {name}
                            </p>{" "}
                          </div>
                        </li>
                      }
                    </div>
                  );
                })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TVProviderListDrawer;
