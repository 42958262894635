import React, { Dispatch, SetStateAction } from "react";

import style from "./TermsConditionOffline.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setInvestmentAgreementCheck } from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux";
interface ITermsAndConditionOffline {
  setShowBankDetails: Dispatch<SetStateAction<boolean>>;
}
const TermsAndConditionOffline: React.FC<ITermsAndConditionOffline> = ({
  setShowBankDetails,
}) => {
  const agreementCheck = useSelector(
    (state: ReduxStoreModel) => state.investment.agreementCheck
  );
  const dispatch = useDispatch();

  const agreementCheckHandle = () => {
    if (!agreementCheck) {
      dispatch(setInvestmentAgreementCheck(true));
    } else {
      dispatch(setInvestmentAgreementCheck(false));
    }
  };

  return (
    <div className={style.terms}>
      <h3>Terms and conditions</h3>
      <ol className={style.termlist}>
        <li>
          This is a fixed deposit product as such; there will be no principal
          liquidation until maturity.
        </li>
        <li>
          Interest will be paid into your wallet based on the pre-selected
          interest payment plan.
        </li>
        <li>
          The investment and the accrued interest will be rolled over at a rate
          to be determined by Advancly (based on the prevailing money market
          rate) if we do not receive confirmation from you on or before the
          maturity date.
        </li>
      </ol>
      <div className={style.checkagreement}>
        <input
          type="checkbox"
          id="agreement"
          name="agreement"
          checked={agreementCheck}
          onChange={agreementCheckHandle}
        />
        <label htmlFor="agreement"> I agree to the terms and conditions</label>
      </div>
      <button
        type="submit"
        className="btn btn-sm advancly-btn mb-3 w-100"
        onClick={() => {
          setShowBankDetails(true);
        }}
        disabled={!agreementCheck}
      >
        I agree
      </button>
    </div>
  );
};

export default TermsAndConditionOffline;
