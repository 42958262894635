import React from "react";
import styles from "./SectorsGridDetailsRight.module.scss";
import { ReactComponent as AbegLogo } from "../../assets/img/abeg-logo.svg";
import { ReactComponent as EyowoLogo } from "../../assets/img/eyowo-logo.svg";
import { ReactComponent as FundallLogo } from "../../assets/img/fundall-logo.svg";

export default function SectorsGridDetailsRight() {
  return (
    <div className={styles.sectorDetailsGridRight}>
      <h6 className="font-weight-bold mb-3">Some companies we Invest in:</h6>

      <div className="card bg-lighter-grey p-3 mb-3">
        <AbegLogo />
        <div className={styles.title}>Abeg Technologies</div>
        <div className={styles.subTitle}>
          Abeg is an innovative best Peer-to-Peer (P2P) service that allows
          people to send and receiving money instantly, all across Africa.
        </div>
        <div className={styles.subTitle}>
          <span className="mr-3">Founded: 2019</span>
          <span>Customers: 750,000+</span>
        </div>
      </div>
      <div className="card bg-lighter-grey p-3 mb-3">
        <EyowoLogo />
        <div className={styles.title}>Eyowo Integrated Payments</div>
        <div className={styles.subTitle}>
          Eyowo is a digital solution that helps you achieve simple day-to-day
          financial transactions.
        </div>
        <div className={styles.subTitle}>
          <span className="mr-3">Founded: 2017</span>
          <span>Customers: 3,000,000+</span>
        </div>
      </div>
      <div className="card bg-lighter-grey p-3 mb-3">
        <FundallLogo />
        <div className={styles.title}>Fundall</div>
        <div className={styles.subTitle}>
          Fundall is a personalized digital financial platform that makes
          managing and growing your money simple, safe and secure.
        </div>
        <div className={styles.subTitle}>
          <span className="mr-3">Founded: 2018</span>
          <span>Customers: 20,000+</span>
        </div>
      </div>
    </div>
  );
}
