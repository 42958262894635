import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";

export interface ICurrencies {
  isLoading: boolean;
  availableCurrencies: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[];
  fetchAvailableCurrenciesFailed: boolean;
}

export const initialState: ICurrencies = {
  isLoading: false,
  availableCurrencies: [],
  fetchAvailableCurrenciesFailed: false,
};

export const userCurrenciesReducer = (
  state = initialState,
  action: ReduxActionModel
) => {
  switch (action.type) {
    case actionType.SET_USER_AVAILABLE_CURRENCIES:
      state = {
        ...state,
        availableCurrencies: action.payload,
      };
      return state;

    case actionType.SET_USER_AVAILABLE_CURRENCIES_FAILED:
      state = {
        ...state,
        fetchAvailableCurrenciesFailed: true,
      };
      return state;

    case actionType.LOADING_USER_AVAILABLE_CURRENCIES:
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    default:
      return state;
  }
};
