import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Divider, Spin } from "antd";
import { Alert } from "reactstrap";
import { errorHandler } from "../../../../helpers/errorHandler";
import { IBeneficiaries } from "../../BankTransferDrawers/NGNTransferDrawer/NGNTransferDrawerStepOne/NGNTransferDrawerStepOne";
import { TVSteps, ISelectedBeneficiary, getLogo } from "../TVDrawer.component";
import { ReactComponent as RightArrowNew } from "../../../../assets/img/RightArrowNew.svg";
import useRecentRecipients from "../../../../hooks/custom-hooks/useRecentRecipients";
import { FiSmartphone, FiUser } from "react-icons/fi";
import nineMobile from "../../../../assets/img/pay/9Mobile.jpg";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import { TbHourglassEmpty } from "react-icons/tb";
import { ETVProviders } from "../TVDrawerStepTwo/TVProviderListDrawer";
import dstv from "../../../../assets/img/pay/dstv.png";
import gotv from "../../../../assets/img/pay/gotv.png";
import startimes from "../../../../assets/img/pay/startimes.png";
import other from "../../../../assets/img/pay/otherNetworks.png";
import styles from "../../BankTransferDrawers/NGNTransferDrawer/WithdrawalModalStepTwo/NGNTransferDrawerBankList.module.scss";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { appInsights } from "../../../AppInsight/AppInsight";

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  activeTab: string;
  setOperatorId: Dispatch<SetStateAction<string>>;
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
}

const TVDrawerStepOne = ({
  setCurrentStep,
  activeTab,
  setOperatorId,
  setSelectedBeneficiaryData,
}: IProps) => {
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const { transactions } = useSelector(
    (state: ReduxStoreModel) => state.billTransactions
  );
  const { supportedTVProviders } = useSelector(
    (state: ReduxStoreModel) => state.tvProviders
  );
  const {
    data: recentRecipientsData,
    isLoading: recentRecipientsLoading,
    error: recentRecipientError,
  } = useRecentRecipients({
    walletId: activeTab === "USD" ? usd_wallet_id : lcy_wallet_id,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "TVDrawerStepOne.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <div className="actions">
        <div
          className="choose_a_bank_wrapper"
          onClick={() => setCurrentStep(TVSteps.SelectAProvider)}
        >
          <div className="choose_sub_wrapper">
            <div className="icon">
              <HiOutlineDesktopComputer />
            </div>
            <div className="choose_text_wrapper">
              <p className="choose_heading">Choose a provider</p>
              <p className="choose_text1">Select a provider to recharge</p>
            </div>
          </div>
          <div className="rigtArrow">
            <RightArrowNew />
          </div>
        </div>
      </div>
      <Divider />
      {/* Recent transactions */}
      <div className="recent_transactions_wrapper">
        {recentRecipientError && (
          <div>
            <Alert message={errorHandler(recentRecipientError)} />
          </div>
        )}

        <h6>Recent Transactions</h6>
        <div>
          <div className="recentBankTransactionsName">
            {transactions && transactions.length === 0 ? (
              <div className="pageLoader pt-5">
                <h6>No recent transactions</h6>
              </div>
            ) : (
              transactions
                ?.slice(0, 3)
                ?.map(
                  (
                    {
                      deviceNumber,
                      productName,
                      operatorId,
                      genericOperatorName,
                    },
                    index
                  ) => {
                    return (
                      <div
                        className="recentTransactionGroup align-items-center"
                        key={index}
                        // TODO: add d click event back when filters are corrected
                        /*  onClick={() => {
                        handleSelectProvider(operatorId, deviceNumber);
                      }}*/
                      >
                        <img
                          src={getLogo(
                            genericOperatorName?.toLowerCase() || ""
                          )}
                          alt="list of network provider icon"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "100%",
                            marginRight: "5px",
                          }}
                        />
                        <p className="text-align-center p-none">
                          {deviceNumber || "N/A"}
                        </p>
                      </div>
                    );
                  }
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TVDrawerStepOne;
