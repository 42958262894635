export const accountNumberLength = 11;

export const mockUserAvailableCurrencies = {
  data: [
    {
      currencyType: "Local",
      currencyCode: "NGN",
    },
    {
      currencyType: "Foreign",
      currencyCode: "USD",
    },
  ],
};

export const mockRecentTransactions = {
  data: [
    {
      id: 1,
      wallet_account_id: 45573,
      currency: "NGN",
      recipient_bank_code: "000013",
      recipient_bank_name: "000013",
      recipient_account_number: "03548799986",
      recipient_account_name: "ABOSEDE OLOWOJESIKU",
    },
    {
      id: 2,
      wallet_account_id: 45573,
      currency: "NGN",
      recipient_bank_code: "000003",
      recipient_account_number: "03548799986",
      recipient_account_name: "ABOSEDE OLOWOJESIKU",
      recipient_bank_name: "000013",
    },
    {
      id: 3,
      wallet_account_id: 45573,
      currency: "NGN",
      recipient_bank_code: "090555",
      recipient_account_number: "03548799986",
      recipient_account_name: "ABOSEDE OLOWOJESIKU",
      recipient_bank_name: "000013",
    },
    {
      id: 4,
      wallet_account_id: 45573,
      currency: "NGN",
      recipient_bank_code: "000004",
      recipient_account_number: "03548799986",
      recipient_account_name: "ABOSEDE OLOWOJESIKU",
      recipient_bank_name: "000013",
    },
  ],
  message: "Success",
  status: true,
  status_code: 200,
};

export const mockBeneficiaryList = {
  data: [
    {
      id: 1,
      customer_id: 45573,
      currency: "NGN",
      bank_code: "090555",
      bank_name: "BishopGate MFB",
      account_number: "03548799986",
      account_name: "ABOSEDE OLOWOJESIKU",
    },
    {
      id: 2,
      customer_id: 45573,
      currency: "NGN",
      bank_code: "090551",
      bank_name: "BishopGate MFB",
      account_number: "03548799986",
      account_name: "ABOSEDE OLOWOJESIKU 1",
    },
  ],
  message: "Success",
  status: true,
  status_code: 200,
};

export const mockAccountName = {
  data: {
    account_number: "03548799986",
    account_name: "ABOSEDE OLOWOJESIKU",
  },
  message: "Success",
  status: true,
  status_code: 200,
};

export const mockBankList = {
  data: [
    {
      bank_code: "000001",
      bank_name: "STERLING BANK",
      bank_logo_url:
        "https://nigerialogos.com/logos/sterling_bank/sterling_bank.png",
    },
    {
      bank_code: "000002",
      bank_name: "KEYSTONE BANK",
      bank_logo_url:
        "https://nigerialogos.com/logos/keystone_bank/keystone_bank.png",
    },
    {
      bank_code: "000003",
      bank_name: "FCMB",
      bank_logo_url: "https://nigerialogos.com/logos/fcmb/fcmb.png",
    },
    {
      bank_code: "000004",
      bank_name: "UNITED BANK FOR AFRICA",
      bank_logo_url:
        "https://nigerialogos.com/logos/united_bank_for_africa/united_bank_for_africa.png",
    },
    {
      bank_code: "000005",
      bank_name: "ACCESS BANK PLC (DIAMOND)",
      bank_logo_url:
        "https://nigerialogos.com/logos/access_bank/access_bank.png",
    },
    {
      bank_code: "000006",
      bank_name: "JAIZ BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000006.svg",
    },
    {
      bank_code: "000007",
      bank_name: "FIDELITY BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000007.svg",
    },
    {
      bank_code: "000008",
      bank_name: "POLARIS BANK",
      bank_logo_url:
        "https://nigerialogos.com/logos/polaris_bank/polaris_bank.png",
    },
    {
      bank_code: "000009",
      bank_name: "CITI BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000009.svg",
    },
    {
      bank_code: "000010",
      bank_name: "ECOBANK BANK",
      bank_logo_url: "https://nigerialogos.com/logos/ecobank/ecobank.png",
    },
    {
      bank_code: "000011",
      bank_name: "UNITY BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000011.svg",
    },
    {
      bank_code: "000012",
      bank_name: "STANBICIBTC BANK",
      bank_logo_url:
        "https://nigerialogos.com/logos/stanbic_ibtc/stanbic_ibtc.png",
    },
    {
      bank_code: "000013",
      bank_name: "GTBANK PLC",
      bank_logo_url:
        "https://nigerialogos.com/logos/guaranty_trust_bank/guaranty_trust_bank.png",
    },
    {
      bank_code: "000014",
      bank_name: "ACCESS BANK",
      bank_logo_url:
        "https://nigerialogos.com/logos/access_bank/access_bank.png",
    },
    {
      bank_code: "000015",
      bank_name: "ZENITH BANK PLC",
      bank_logo_url:
        "https://nigerialogos.com/logos/zenith_bank/zenith_bank.png",
    },
    {
      bank_code: "000016",
      bank_name: "FIRST BANK OF NIGERIA",
      bank_logo_url: "https://nigerialogos.com/logos/first_bank/first_bank.png",
    },
    {
      bank_code: "000017",
      bank_name: "WEMA BANK",
      bank_logo_url: "https://nigerialogos.com/logos/wema_bank/wema_bank.png",
    },
    {
      bank_code: "000018",
      bank_name: "UNION BANK",
      bank_logo_url: "https://nigerialogos.com/logos/union_bank/union_bank.png",
    },
    {
      bank_code: "000020",
      bank_name: "HERITAGE",
      bank_logo_url:
        "https://nigerialogos.com/logos/heritage_bank/heritage_bank.png",
    },
    {
      bank_code: "000021",
      bank_name: "STANDARDCHARTERED",
      bank_logo_url:
        "https://nigerialogos.com/logos/standard_chartered/standard_chartered.png",
    },
    {
      bank_code: "000022",
      bank_name: "SUNTRUST BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000022.svg",
    },
    {
      bank_code: "000023",
      bank_name: "PROVIDUS BANK ",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000023.svg",
    },
    {
      bank_code: "000024",
      bank_name: "RAND MERCHANT BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000024.svg",
    },
    {
      bank_code: "000025",
      bank_name: "TITAN TRUST BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000025.svg",
    },
    {
      bank_code: "000026",
      bank_name: "TAJ BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000026.svg",
    },
    {
      bank_code: "000027",
      bank_name: "GLOBUS BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000027.svg",
    },
    {
      bank_code: "000028",
      bank_name: "Central Bank of Nigeria",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000028.svg",
    },
    {
      bank_code: "000029",
      bank_name: "Lotus Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000029.svg",
    },
    {
      bank_code: "000030",
      bank_name: "Parallex Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000030.svg",
    },
    {
      bank_code: "000031",
      bank_name: "Premium Trust bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000031.svg",
    },
    {
      bank_code: "050002",
      bank_name: "FEWCHORE FINANCE COMPANY LIMITED",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_050002.svg",
    },
    {
      bank_code: "050003",
      bank_name: "SageGrey Finance Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_050003.svg",
    },
    {
      bank_code: "060001",
      bank_name: "CORONATION",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_060001.svg",
    },
    {
      bank_code: "060002",
      bank_name: "FBNQUEST MERCHANT BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_060002.svg",
    },
    {
      bank_code: "060003",
      bank_name: "NOVA MB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_060003.svg",
    },
    {
      bank_code: "060004",
      bank_name: "Greenwich Merchant Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_060004.svg",
    },
    {
      bank_code: "070001",
      bank_name: "NPF MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070001.svg",
    },
    {
      bank_code: "070006",
      bank_name: "COVENANT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070006.svg",
    },
    {
      bank_code: "070007",
      bank_name: "LIVINGTRUST MORTGAGE BANK PLC",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070007.svg",
    },
    {
      bank_code: "070008",
      bank_name: "PAGE FINANCIALS",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070008.svg",
    },
    {
      bank_code: "070009",
      bank_name: "GATEWAY MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070009.svg",
    },
    {
      bank_code: "070010",
      bank_name: "ABBEY MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070010.svg",
    },
    {
      bank_code: "070011",
      bank_name: "REFUGE MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070011.svg",
    },
    {
      bank_code: "070012",
      bank_name: "LBIC MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070012.svg",
    },
    {
      bank_code: "070013",
      bank_name: "PLATINUM MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070013.svg",
    },
    {
      bank_code: "070014",
      bank_name: "FIRST GENERATION MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070014.svg",
    },
    {
      bank_code: "070015",
      bank_name: "BRENT MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070015.svg",
    },
    {
      bank_code: "070016",
      bank_name: "INFINITY TRUST  MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070016.svg",
    },
    {
      bank_code: "070017",
      bank_name: "HAGGAI MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070017.svg",
    },
    {
      bank_code: "070019",
      bank_name: "MAYFRESH MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070019.svg",
    },
    {
      bank_code: "070021",
      bank_name: "COOP Mortgage Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070021.svg",
    },
    {
      bank_code: "070022",
      bank_name: "STB Mortgage Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070022.svg",
    },
    {
      bank_code: "070023",
      bank_name: "Delta Trust Mortgage bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070023.svg",
    },
    {
      bank_code: "070024",
      bank_name: "HomeBase Mortgage",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070024.svg",
    },
    {
      bank_code: "070025",
      bank_name: "Akwa savings &amp; Loans Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_070025.svg",
    },
    {
      bank_code: "080002",
      bank_name: "TajWallet",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_080002.svg",
    },
    {
      bank_code: "090001",
      bank_name: "ASOSAVINGS",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090001.svg",
    },
    {
      bank_code: "090003",
      bank_name: "JUBILEELIFE",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090003.svg",
    },
    {
      bank_code: "090004",
      bank_name: "PARRALEX",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090004.svg",
    },
    {
      bank_code: "090005",
      bank_name: "TRUSTBOND",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090005.svg",
    },
    {
      bank_code: "090006",
      bank_name: "SAFETRUST",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090006.svg",
    },
    {
      bank_code: "090097",
      bank_name: "EKONDO MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090097.svg",
    },
    {
      bank_code: "090107",
      bank_name: "FIRSTTRUST MORGAGES LIMITED",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090107.svg",
    },
    {
      bank_code: "090110",
      bank_name: "VFD MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090110.svg",
    },
    {
      bank_code: "090111",
      bank_name: "FINATRUST MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090111.svg",
    },
    {
      bank_code: "090112",
      bank_name: "SEED CAPITAL MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090112.svg",
    },
    {
      bank_code: "090113",
      bank_name: "MICROVIS MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090113.svg",
    },
    {
      bank_code: "090114",
      bank_name: "EMPIRETRUST MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090114.svg",
    },
    {
      bank_code: "090115",
      bank_name: "TCF",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090115.svg",
    },
    {
      bank_code: "090116",
      bank_name: "AMML MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090116.svg",
    },
    {
      bank_code: "090117",
      bank_name: "BOCTRUST MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090117.svg",
    },
    {
      bank_code: "090118",
      bank_name: "IBILE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090118.svg",
    },
    {
      bank_code: "090119",
      bank_name: "OHAFIA MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090119.svg",
    },
    {
      bank_code: "090120",
      bank_name: "WETLAND MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090120.svg",
    },
    {
      bank_code: "090121",
      bank_name: "HASAL MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090121.svg",
    },
    {
      bank_code: "090122",
      bank_name: "GOWANS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090122.svg",
    },
    {
      bank_code: "090123",
      bank_name: "TRUSTBANC J6 MICROFINANCE BANK LIMITED",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090123.svg",
    },
    {
      bank_code: "090124",
      bank_name: "XSLNCE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090124.svg",
    },
    {
      bank_code: "090125",
      bank_name: "REGENT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090125.svg",
    },
    {
      bank_code: "090126",
      bank_name: "FIDFUND MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090126.svg",
    },
    {
      bank_code: "090127",
      bank_name: "BC KASH MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090127.svg",
    },
    {
      bank_code: "090128",
      bank_name: "NDIORAH MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090128.svg",
    },
    {
      bank_code: "090129",
      bank_name: "MONEYTRUST MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090129.svg",
    },
    {
      bank_code: "090130",
      bank_name: "CONSUMER  MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090130.svg",
    },
    {
      bank_code: "090131",
      bank_name: "ALLWORKERS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090131.svg",
    },
    {
      bank_code: "090132",
      bank_name: "RICHWAY MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090132.svg",
    },
    {
      bank_code: "090133",
      bank_name: "AL-BARKAH MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090133.svg",
    },
    {
      bank_code: "090134",
      bank_name: "ACCION MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090134.svg",
    },
    {
      bank_code: "090135",
      bank_name: "PERSONAL TRUST MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090135.svg",
    },
    {
      bank_code: "090136",
      bank_name: "MICROCRED MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090136.svg",
    },
    {
      bank_code: "090137",
      bank_name: "PECAN TRUST MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090137.svg",
    },
    {
      bank_code: "090138",
      bank_name: "ROYAL EXCHANGE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090138.svg",
    },
    {
      bank_code: "090139",
      bank_name: "VISA MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090139.svg",
    },
    {
      bank_code: "090140",
      bank_name: "SAGAMU MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090140.svg",
    },
    {
      bank_code: "090141",
      bank_name: "CHIKUM MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090141.svg",
    },
    {
      bank_code: "090142",
      bank_name: "YES MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090142.svg",
    },
    {
      bank_code: "090143",
      bank_name: "APEKS MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090143.svg",
    },
    {
      bank_code: "090144",
      bank_name: "CIT MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090144.svg",
    },
    {
      bank_code: "090145",
      bank_name: "FULL RANGE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090145.svg",
    },
    {
      bank_code: "090146",
      bank_name: "TRIDENT MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090146.svg",
    },
    {
      bank_code: "090147",
      bank_name: "HACKMAN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090147.svg",
    },
    {
      bank_code: "090148",
      bank_name: "BOWEN MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090148.svg",
    },
    {
      bank_code: "090149",
      bank_name: "IRL MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090149.svg",
    },
    {
      bank_code: "090150",
      bank_name: "VIRTUE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090150.svg",
    },
    {
      bank_code: "090151",
      bank_name: "MUTUAL TRUST MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090151.svg",
    },
    {
      bank_code: "090152",
      bank_name: "NARGATA MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090152.svg",
    },
    {
      bank_code: "090153",
      bank_name: "FFS MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090153.svg",
    },
    {
      bank_code: "090154",
      bank_name: "CEMCS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090154.svg",
    },
    {
      bank_code: "090155",
      bank_name: "ADVANS LA FAYETTE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090155.svg",
    },
    {
      bank_code: "090156",
      bank_name: "E-BARCS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090156.svg",
    },
    {
      bank_code: "090157",
      bank_name: "INFINITY MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090157.svg",
    },
    {
      bank_code: "090158",
      bank_name: "FUTO MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090158.svg",
    },
    {
      bank_code: "090159",
      bank_name: "CREDIT AFRIQUE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090159.svg",
    },
    {
      bank_code: "090160",
      bank_name: "ADDOSSER MFBB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090160.svg",
    },
    {
      bank_code: "090161",
      bank_name: "OKPOGA MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090161.svg",
    },
    {
      bank_code: "090162",
      bank_name: "STANFORD MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090162.svg",
    },
    {
      bank_code: "090163",
      bank_name: "FIRST MULTIPLE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090163.svg",
    },
    {
      bank_code: "090164",
      bank_name: "FIRST ROYAL MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090164.svg",
    },
    {
      bank_code: "090165",
      bank_name: "PETRA MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090165.svg",
    },
    {
      bank_code: "090166",
      bank_name: "ESO-E MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090166.svg",
    },
    {
      bank_code: "090167",
      bank_name: "DAYLIGHT MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090167.svg",
    },
    {
      bank_code: "090168",
      bank_name: "GASHUA MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090168.svg",
    },
    {
      bank_code: "090169",
      bank_name: "ALPHAKAPITAL MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090169.svg",
    },
    {
      bank_code: "090170",
      bank_name: "RAHAMA MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090170.svg",
    },
    {
      bank_code: "090171",
      bank_name: "MAINSTREET MFB",
      bank_logo_url:
        "https://nigerialogos.com/logos/mainstreet_bank/mainstreet_bank.png",
    },
    {
      bank_code: "090172",
      bank_name: "ASTRAPOLARIS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090172.svg",
    },
    {
      bank_code: "090173",
      bank_name: "RELIANCE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090173.svg",
    },
    {
      bank_code: "090174",
      bank_name: "MALACHY MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090174.svg",
    },
    {
      bank_code: "090176",
      bank_name: "BOSAK MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090176.svg",
    },
    {
      bank_code: "090177",
      bank_name: "LAPO MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090177.svg",
    },
    {
      bank_code: "090178",
      bank_name: "GREENBANK MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090178.svg",
    },
    {
      bank_code: "090179",
      bank_name: "FAST Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090179.svg",
    },
    {
      bank_code: "090180",
      bank_name: "AMJU MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090180.svg",
    },
    {
      bank_code: "090181",
      bank_name: "Balogun Fulani  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090181.svg",
    },
    {
      bank_code: "090182",
      bank_name: "Standard MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090182.svg",
    },
    {
      bank_code: "090186",
      bank_name: "Girei MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090186.svg",
    },
    {
      bank_code: "090188",
      bank_name: "BAINES CREDIT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090188.svg",
    },
    {
      bank_code: "090189",
      bank_name: "ESAN MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090189.svg",
    },
    {
      bank_code: "090190",
      bank_name: "MUTUAL BENEFITS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090190.svg",
    },
    {
      bank_code: "090191",
      bank_name: "KCMB MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090191.svg",
    },
    {
      bank_code: "090192",
      bank_name: "Midland MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090192.svg",
    },
    {
      bank_code: "090193",
      bank_name: "UNICAL MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090193.svg",
    },
    {
      bank_code: "090194",
      bank_name: "NIRSAL NATIONAL MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090194.svg",
    },
    {
      bank_code: "090195",
      bank_name: "GROOMING MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090195.svg",
    },
    {
      bank_code: "090196",
      bank_name: "PENNYWISE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090196.svg",
    },
    {
      bank_code: "090197",
      bank_name: "ABU MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090197.svg",
    },
    {
      bank_code: "090198",
      bank_name: "RENMONEY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090198.svg",
    },
    {
      bank_code: "090205",
      bank_name: "NEWDAWN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090205.svg",
    },
    {
      bank_code: "090251",
      bank_name: "UNN MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090251.svg",
    },
    {
      bank_code: "090252",
      bank_name: "Yobe MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090252.svg",
    },
    {
      bank_code: "090254",
      bank_name: "CoalCamp Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090254.svg",
    },
    {
      bank_code: "090258",
      bank_name: "IMO MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090258.svg",
    },
    {
      bank_code: "090259",
      bank_name: "ALEKUN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090259.svg",
    },
    {
      bank_code: "090260",
      bank_name: "ABOVE ONLY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090260.svg",
    },
    {
      bank_code: "090261",
      bank_name: "QUICKFUND MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090261.svg",
    },
    {
      bank_code: "090262",
      bank_name: "STELLAS MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090262.svg",
    },
    {
      bank_code: "090263",
      bank_name: "NAVY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090263.svg",
    },
    {
      bank_code: "090264",
      bank_name: "AUCHI MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090264.svg",
    },
    {
      bank_code: "090265",
      bank_name: "LOVONUS MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090265.svg",
    },
    {
      bank_code: "090266",
      bank_name: "UNIBEN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090266.svg",
    },
    {
      bank_code: "090267",
      bank_name: "KUDA MICROFINANCE BANK",
      bank_logo_url: "https://nigerialogos.com/logos/kuda_bank/kuda_bank.png",
    },
    {
      bank_code: "090268",
      bank_name: "ADEYEMI COLLEGE STAFF MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090268.svg",
    },
    {
      bank_code: "090269",
      bank_name: "GREENVILLE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090269.svg",
    },
    {
      bank_code: "090270",
      bank_name: "AB MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090270.svg",
    },
    {
      bank_code: "090271",
      bank_name: "LAVENDER MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090271.svg",
    },
    {
      bank_code: "090272",
      bank_name: "OLABISI ONABANJO UNIVERSITY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090272.svg",
    },
    {
      bank_code: "090273",
      bank_name: "EMERALDS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090273.svg",
    },
    {
      bank_code: "090274",
      bank_name: "PRESTIGE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090274.svg",
    },
    {
      bank_code: "090275",
      bank_name: "MERIDIAN MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090275.svg",
    },
    {
      bank_code: "090276",
      bank_name: "TRUSTFUND MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090276.svg",
    },
    {
      bank_code: "090277",
      bank_name: "ALHAYAT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090277.svg",
    },
    {
      bank_code: "090278",
      bank_name: "GLORY MFB ",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090278.svg",
    },
    {
      bank_code: "090279",
      bank_name: "IKIRE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090279.svg",
    },
    {
      bank_code: "090280",
      bank_name: "MEGAPRAISE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090280.svg",
    },
    {
      bank_code: "090281",
      bank_name: "FINEX MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090281.svg",
    },
    {
      bank_code: "090282",
      bank_name: "ARISE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090282.svg",
    },
    {
      bank_code: "090283",
      bank_name: "THRIVE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090283.svg",
    },
    {
      bank_code: "090285",
      bank_name: "FIRST OPTION MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090285.svg",
    },
    {
      bank_code: "090286",
      bank_name: "SafeHaven Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090286.svg",
    },
    {
      bank_code: "090287",
      bank_name: "Assets Matrix MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090287.svg",
    },
    {
      bank_code: "090289",
      bank_name: "PILLAR MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090289.svg",
    },
    {
      bank_code: "090290",
      bank_name: "FCT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090290.svg",
    },
    {
      bank_code: "090291",
      bank_name: "HALA MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090291.svg",
    },
    {
      bank_code: "090292",
      bank_name: "Afekhafe MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090292.svg",
    },
    {
      bank_code: "090293",
      bank_name: "BRETHREN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090293.svg",
    },
    {
      bank_code: "090294",
      bank_name: "EAGLE FLIGHT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090294.svg",
    },
    {
      bank_code: "090295",
      bank_name: "OMIYE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090295.svg",
    },
    {
      bank_code: "090296",
      bank_name: "POLYUWANNA MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090296.svg",
    },
    {
      bank_code: "090297",
      bank_name: "ALERT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090297.svg",
    },
    {
      bank_code: "090298",
      bank_name: "FEDERALPOLY NASARAWAMFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090298.svg",
    },
    {
      bank_code: "090299",
      bank_name: "KONTAGORA MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090299.svg",
    },
    {
      bank_code: "090302",
      bank_name: "Sunbeam Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090302.svg",
    },
    {
      bank_code: "090303",
      bank_name: "PURPLEMONEY MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090303.svg",
    },
    {
      bank_code: "090304",
      bank_name: "EVANGEL MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090304.svg",
    },
    {
      bank_code: "090305",
      bank_name: "SULSAP MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090305.svg",
    },
    {
      bank_code: "090307",
      bank_name: "Aramoko Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090307.svg",
    },
    {
      bank_code: "090308",
      bank_name: "BRIGHTWAY MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090308.svg",
    },
    {
      bank_code: "090310",
      bank_name: "EDFIN MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090310.svg",
    },
    {
      bank_code: "090315",
      bank_name: "U AND C MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090315.svg",
    },
    {
      bank_code: "090316",
      bank_name: "BAYERO MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090316.svg",
    },
    {
      bank_code: "090317",
      bank_name: "PATRICK GOLD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090317.svg",
    },
    {
      bank_code: "090318",
      bank_name: "FEDERAL UNIVERSITY DUTSE  MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090318.svg",
    },
    {
      bank_code: "090319",
      bank_name: "Bonghe Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090319.svg",
    },
    {
      bank_code: "090320",
      bank_name: "KADPOLY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090320.svg",
    },
    {
      bank_code: "090321",
      bank_name: "MAYFAIR  MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090321.svg",
    },
    {
      bank_code: "090322",
      bank_name: "REPHIDIM MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090322.svg",
    },
    {
      bank_code: "090323",
      bank_name: "MAINLAND MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090323.svg",
    },
    {
      bank_code: "090324",
      bank_name: "IKENNE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090324.svg",
    },
    {
      bank_code: "090325",
      bank_name: "SPARKLE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090325.svg",
    },
    {
      bank_code: "090326",
      bank_name: "BALOGUN GAMBARI MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090326.svg",
    },
    {
      bank_code: "090327",
      bank_name: "TRUST MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090327.svg",
    },
    {
      bank_code: "090328",
      bank_name: "EYOWO MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090328.svg",
    },
    {
      bank_code: "090329",
      bank_name: "NEPTUNE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090329.svg",
    },
    {
      bank_code: "090330",
      bank_name: "FAME Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090330.svg",
    },
    {
      bank_code: "090331",
      bank_name: "UNAAB MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090331.svg",
    },
    {
      bank_code: "090332",
      bank_name: "EVERGREEN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090332.svg",
    },
    {
      bank_code: "090333",
      bank_name: "OCHE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090333.svg",
    },
    {
      bank_code: "090335",
      bank_name: "Grant Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090335.svg",
    },
    {
      bank_code: "090336",
      bank_name: "BIPC MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090336.svg",
    },
    {
      bank_code: "090337",
      bank_name: "IYERU OKIN MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090337.svg",
    },
    {
      bank_code: "090338",
      bank_name: "UniUyo Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090338.svg",
    },
    {
      bank_code: "090340",
      bank_name: "Stockcorp  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090340.svg",
    },
    {
      bank_code: "090341",
      bank_name: "unilorin Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090341.svg",
    },
    {
      bank_code: "090343",
      bank_name: "Citizen Trust Microfinance Bank Ltd",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090343.svg",
    },
    {
      bank_code: "090349",
      bank_name: "Nasarawa Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090349.svg",
    },
    {
      bank_code: "090350",
      bank_name: "Illorin Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090350.svg",
    },
    {
      bank_code: "090352",
      bank_name: "Jessefield Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090352.svg",
    },
    {
      bank_code: "090353",
      bank_name: "Isuofia MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090353.svg",
    },
    {
      bank_code: "090360",
      bank_name: "CASHCONNECT   MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090360.svg",
    },
    {
      bank_code: "090362",
      bank_name: "MOLUSI MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090362.svg",
    },
    {
      bank_code: "090363",
      bank_name: "Headway MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090363.svg",
    },
    {
      bank_code: "090364",
      bank_name: "NUTURE MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090364.svg",
    },
    {
      bank_code: "090365",
      bank_name: "Corestep MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090365.svg",
    },
    {
      bank_code: "090366",
      bank_name: "Firmus MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090366.svg",
    },
    {
      bank_code: "090369",
      bank_name: "SEEDVEST MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090369.svg",
    },
    {
      bank_code: "090370",
      bank_name: "ILASAN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090370.svg",
    },
    {
      bank_code: "090371",
      bank_name: "AGOSASA MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090371.svg",
    },
    {
      bank_code: "090372",
      bank_name: "LEGEND MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090372.svg",
    },
    {
      bank_code: "090373",
      bank_name: "TF MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090373.svg",
    },
    {
      bank_code: "090374",
      bank_name: "COASTLINE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090374.svg",
    },
    {
      bank_code: "090376",
      bank_name: "APPLE  MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090376.svg",
    },
    {
      bank_code: "090377",
      bank_name: "ISALEOYO MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090377.svg",
    },
    {
      bank_code: "090378",
      bank_name: "NEW GOLDEN PASTURES MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090378.svg",
    },
    {
      bank_code: "090379",
      bank_name: "PENIEL MICORFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090379.svg",
    },
    {
      bank_code: "090380",
      bank_name: "KREDI MONEY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090380.svg",
    },
    {
      bank_code: "090383",
      bank_name: "MANNY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090383.svg",
    },
    {
      bank_code: "090385",
      bank_name: "GTI  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090385.svg",
    },
    {
      bank_code: "090386",
      bank_name: "Interland MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090386.svg",
    },
    {
      bank_code: "090389",
      bank_name: "EK-Reliable Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090389.svg",
    },
    {
      bank_code: "090390",
      bank_name: "Parkway MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090390.svg",
    },
    {
      bank_code: "090391",
      bank_name: "Davodani  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090391.svg",
    },
    {
      bank_code: "090392",
      bank_name: "Mozfin Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090392.svg",
    },
    {
      bank_code: "090393",
      bank_name: "Bridgeway Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090393.svg",
    },
    {
      bank_code: "090394",
      bank_name: "Amac Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090394.svg",
    },
    {
      bank_code: "090395",
      bank_name: "Borgu MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090395.svg",
    },
    {
      bank_code: "090396",
      bank_name: "Oscotech MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090396.svg",
    },
    {
      bank_code: "090397",
      bank_name: "Chanelle Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090397.svg",
    },
    {
      bank_code: "090398",
      bank_name: "Federal Polytechnic Nekede Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090398.svg",
    },
    {
      bank_code: "090399",
      bank_name: "Nwannegadi MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090399.svg",
    },
    {
      bank_code: "090400",
      bank_name: "Finca Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090400.svg",
    },
    {
      bank_code: "090401",
      bank_name: "Shepherd Trust Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090401.svg",
    },
    {
      bank_code: "090402",
      bank_name: "Peace Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090402.svg",
    },
    {
      bank_code: "090403",
      bank_name: "UDA Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090403.svg",
    },
    {
      bank_code: "090404",
      bank_name: "Olowolagba Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090404.svg",
    },
    {
      bank_code: "090405",
      bank_name: "MONIEPOINT MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090405.svg",
    },
    {
      bank_code: "090406",
      bank_name: "Business Support Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090406.svg",
    },
    {
      bank_code: "090408",
      bank_name: "GMB Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090408.svg",
    },
    {
      bank_code: "090409",
      bank_name: "FCMB MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090409.svg",
    },
    {
      bank_code: "090410",
      bank_name: "Maritime Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090410.svg",
    },
    {
      bank_code: "090411",
      bank_name: "GiGinya Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090411.svg",
    },
    {
      bank_code: "090412",
      bank_name: "Preeminent Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090412.svg",
    },
    {
      bank_code: "090413",
      bank_name: "Benysta Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090413.svg",
    },
    {
      bank_code: "090414",
      bank_name: "Crutech  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090414.svg",
    },
    {
      bank_code: "090415",
      bank_name: "Calabar Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090415.svg",
    },
    {
      bank_code: "090416",
      bank_name: "Chibueze Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090416.svg",
    },
    {
      bank_code: "090417",
      bank_name: "Imowo Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090417.svg",
    },
    {
      bank_code: "090418",
      bank_name: "Highland Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090418.svg",
    },
    {
      bank_code: "090419",
      bank_name: "WinView Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090419.svg",
    },
    {
      bank_code: "090420",
      bank_name: "LETSHEGO MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090420.svg",
    },
    {
      bank_code: "090421",
      bank_name: "Izon Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090421.svg",
    },
    {
      bank_code: "090422",
      bank_name: "Landgold  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090422.svg",
    },
    {
      bank_code: "090423",
      bank_name: "Mautech  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090423.svg",
    },
    {
      bank_code: "090424",
      bank_name: "Abucoop  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090424.svg",
    },
    {
      bank_code: "090425",
      bank_name: "Banex Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090425.svg",
    },
    {
      bank_code: "090426",
      bank_name: "Tangerine Money MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090426.svg",
    },
    {
      bank_code: "090427",
      bank_name: "EBSU MICROFINANCE Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090427.svg",
    },
    {
      bank_code: "090428",
      bank_name: "Ishie  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090428.svg",
    },
    {
      bank_code: "090429",
      bank_name: "CrossRiver  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090429.svg",
    },
    {
      bank_code: "090430",
      bank_name: "Ilora Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090430.svg",
    },
    {
      bank_code: "090431",
      bank_name: "Bluewhales  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090431.svg",
    },
    {
      bank_code: "090432",
      bank_name: "Memphis Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090432.svg",
    },
    {
      bank_code: "090433",
      bank_name: "RIGO Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090433.svg",
    },
    {
      bank_code: "090434",
      bank_name: "Insight Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090434.svg",
    },
    {
      bank_code: "090435",
      bank_name: "Links  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090435.svg",
    },
    {
      bank_code: "090436",
      bank_name: "Spectrum Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090436.svg",
    },
    {
      bank_code: "090437",
      bank_name: "Oakland Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090437.svg",
    },
    {
      bank_code: "090438",
      bank_name: "Futminna Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090438.svg",
    },
    {
      bank_code: "090439",
      bank_name: "IBETO  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090439.svg",
    },
    {
      bank_code: "090440",
      bank_name: "Cherish Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090440.svg",
    },
    {
      bank_code: "090441",
      bank_name: "Giwa Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090441.svg",
    },
    {
      bank_code: "090443",
      bank_name: "RIMA Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090443.svg",
    },
    {
      bank_code: "090444",
      bank_name: "BOI MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090444.svg",
    },
    {
      bank_code: "090445",
      bank_name: "Capstone MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090445.svg",
    },
    {
      bank_code: "090446",
      bank_name: "Support MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090446.svg",
    },
    {
      bank_code: "090448",
      bank_name: "Moyofade MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090448.svg",
    },
    {
      bank_code: "090449",
      bank_name: "SLS  MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090449.svg",
    },
    {
      bank_code: "090450",
      bank_name: "Kwasu MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090450.svg",
    },
    {
      bank_code: "090451",
      bank_name: "ATBU  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090451.svg",
    },
    {
      bank_code: "090452",
      bank_name: "Unilag  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090452.svg",
    },
    {
      bank_code: "090453",
      bank_name: "Uzondu MF Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090453.svg",
    },
    {
      bank_code: "090454",
      bank_name: "Borstal Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090454.svg",
    },
    {
      bank_code: "090456",
      bank_name: "Ospoly Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090456.svg",
    },
    {
      bank_code: "090459",
      bank_name: "Nice Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090459.svg",
    },
    {
      bank_code: "090460",
      bank_name: "OLUYOLE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090460.svg",
    },
    {
      bank_code: "090461",
      bank_name: "UNIIBADAN Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090461.svg",
    },
    {
      bank_code: "090462",
      bank_name: "Monarch Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090462.svg",
    },
    {
      bank_code: "090463",
      bank_name: "Rehoboth Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090463.svg",
    },
    {
      bank_code: "090464",
      bank_name: "Unimaid Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090464.svg",
    },
    {
      bank_code: "090465",
      bank_name: "Maintrust MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090465.svg",
    },
    {
      bank_code: "090466",
      bank_name: "YCT Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090466.svg",
    },
    {
      bank_code: "090467",
      bank_name: "Good Neighbours Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090467.svg",
    },
    {
      bank_code: "090468",
      bank_name: "OLOFIN OWENA Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090468.svg",
    },
    {
      bank_code: "090469",
      bank_name: "Aniocha MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090469.svg",
    },
    {
      bank_code: "090470",
      bank_name: "DOT MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090470.svg",
    },
    {
      bank_code: "090471",
      bank_name: "OLUCHUKWU Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090471.svg",
    },
    {
      bank_code: "090472",
      bank_name: "CARETAKER Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090472.svg",
    },
    {
      bank_code: "090473",
      bank_name: "ASSETS Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090473.svg",
    },
    {
      bank_code: "090474",
      bank_name: "Verdant Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090474.svg",
    },
    {
      bank_code: "090475",
      bank_name: "Giant Stride MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090475.svg",
    },
    {
      bank_code: "090476",
      bank_name: "Anchorage MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090476.svg",
    },
    {
      bank_code: "090477",
      bank_name: "Light Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090477.svg",
    },
    {
      bank_code: "090478",
      bank_name: "Avuenegbe MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090478.svg",
    },
    {
      bank_code: "090479",
      bank_name: "First Heritage MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090479.svg",
    },
    {
      bank_code: "090480",
      bank_name: "Cintrust MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090480.svg",
    },
    {
      bank_code: "090481",
      bank_name: "Prisco  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090481.svg",
    },
    {
      bank_code: "090482",
      bank_name: "Fedeth MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090482.svg",
    },
    {
      bank_code: "090483",
      bank_name: "Ada MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090483.svg",
    },
    {
      bank_code: "090484",
      bank_name: "Garki MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090484.svg",
    },
    {
      bank_code: "090485",
      bank_name: "Safegate Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090485.svg",
    },
    {
      bank_code: "090486",
      bank_name: "Fortress Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090486.svg",
    },
    {
      bank_code: "090487",
      bank_name: "Kingdom College  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090487.svg",
    },
    {
      bank_code: "090488",
      bank_name: "Ibu-Aje Microfinance",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090488.svg",
    },
    {
      bank_code: "090489",
      bank_name: "Alvana Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090489.svg",
    },
    {
      bank_code: "090490",
      bank_name: "Chukwunenye  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090490.svg",
    },
    {
      bank_code: "090491",
      bank_name: "Nsuk  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090491.svg",
    },
    {
      bank_code: "090492",
      bank_name: "Oraukwu  Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090492.svg",
    },
    {
      bank_code: "090493",
      bank_name: "Iperu Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090493.svg",
    },
    {
      bank_code: "090494",
      bank_name: "Boji Boji Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090494.svg",
    },
    {
      bank_code: "090495",
      bank_name: "Goodnews Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090495.svg",
    },
    {
      bank_code: "090496",
      bank_name: "Radalpha Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090496.svg",
    },
    {
      bank_code: "090497",
      bank_name: "Palmcoast Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090497.svg",
    },
    {
      bank_code: "090498",
      bank_name: "Catland Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090498.svg",
    },
    {
      bank_code: "090499",
      bank_name: "Pristine Divitis Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090499.svg",
    },
    {
      bank_code: "090500",
      bank_name: "Gwong Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090500.svg",
    },
    {
      bank_code: "090501",
      bank_name: "Boromu Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090501.svg",
    },
    {
      bank_code: "090502",
      bank_name: "Shalom Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090502.svg",
    },
    {
      bank_code: "090503",
      bank_name: "Projects Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090503.svg",
    },
    {
      bank_code: "090504",
      bank_name: "Zikora Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090504.svg",
    },
    {
      bank_code: "090505",
      bank_name: "Nigeria PrisonsMicrofinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090505.svg",
    },
    {
      bank_code: "090506",
      bank_name: "Solid Allianze MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090506.svg",
    },
    {
      bank_code: "090507",
      bank_name: "FIMS MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090507.svg",
    },
    {
      bank_code: "090508",
      bank_name: "Borno Renaissance Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090508.svg",
    },
    {
      bank_code: "090509",
      bank_name: "CAPITALMETRIQ SWIFT MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090509.svg",
    },
    {
      bank_code: "090510",
      bank_name: "Umunnachi Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090510.svg",
    },
    {
      bank_code: "090511",
      bank_name: "Cloverleaf  MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090511.svg",
    },
    {
      bank_code: "090512",
      bank_name: "BUBAYERO Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090512.svg",
    },
    {
      bank_code: "090513",
      bank_name: "SEAP Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090513.svg",
    },
    {
      bank_code: "090514",
      bank_name: "Umuchinemere Procredit Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090514.svg",
    },
    {
      bank_code: "090515",
      bank_name: "Rima Growth pathway Microfinance Bank ",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090515.svg",
    },
    {
      bank_code: "090516",
      bank_name: "Numo Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090516.svg",
    },
    {
      bank_code: "090517",
      bank_name: "Uhuru Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090517.svg",
    },
    {
      bank_code: "090518",
      bank_name: "Afemai Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090518.svg",
    },
    {
      bank_code: "090519",
      bank_name: "Ibom fadama Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090519.svg",
    },
    {
      bank_code: "090520",
      bank_name: "IC GLOBALMicrofinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090520.svg",
    },
    {
      bank_code: "090521",
      bank_name: "Foresight Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090521.svg",
    },
    {
      bank_code: "090523",
      bank_name: "Chase Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090523.svg",
    },
    {
      bank_code: "090524",
      bank_name: "Solidrock Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090524.svg",
    },
    {
      bank_code: "090525",
      bank_name: "Triple A Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090525.svg",
    },
    {
      bank_code: "090526",
      bank_name: "Crescent Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090526.svg",
    },
    {
      bank_code: "090527",
      bank_name: "Ojokoro Mfb",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090527.svg",
    },
    {
      bank_code: "090528",
      bank_name: "Mgbidi Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090528.svg",
    },
    {
      bank_code: "090529",
      bank_name: "Ampersand Microfinance bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090529.svg",
    },
    {
      bank_code: "090530",
      bank_name: "CONFIDENCE MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090530.svg",
    },
    {
      bank_code: "090531",
      bank_name: "Aku MFB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090531.svg",
    },
    {
      bank_code: "090532",
      bank_name: "IBOLO MICORFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090532.svg",
    },
    {
      bank_code: "090534",
      bank_name: "Polyibadan Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090534.svg",
    },
    {
      bank_code: "090535",
      bank_name: "Npolu-UST Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090535.svg",
    },
    {
      bank_code: "090536",
      bank_name: "Ikoyi-Osun Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090536.svg",
    },
    {
      bank_code: "090537",
      bank_name: "Lobrem Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090537.svg",
    },
    {
      bank_code: "090538",
      bank_name: "Blue Investments Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090538.svg",
    },
    {
      bank_code: "090539",
      bank_name: "Enrich Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090539.svg",
    },
    {
      bank_code: "090540",
      bank_name: "Aztec Microfinance Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090540.svg",
    },
    {
      bank_code: "090541",
      bank_name: "EXCELLENT MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090541.svg",
    },
    {
      bank_code: "090542",
      bank_name: "OTUO MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090542.svg",
    },
    {
      bank_code: "090543",
      bank_name: "IWOAMA MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090543.svg",
    },
    {
      bank_code: "090544",
      bank_name: "ASPIRE MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090544.svg",
    },
    {
      bank_code: "090545",
      bank_name: "ABULESORO MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090545.svg",
    },
    {
      bank_code: "090546",
      bank_name: "IJEBU-IFE MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090546.svg",
    },
    {
      bank_code: "090547",
      bank_name: "ROCKSHIELD MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090547.svg",
    },
    {
      bank_code: "090548",
      bank_name: "ALLY MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090548.svg",
    },
    {
      bank_code: "090549",
      bank_name: "KC MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090549.svg",
    },
    {
      bank_code: "090550",
      bank_name: "GREEN ENERGY MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090550.svg",
    },
    {
      bank_code: "090551",
      bank_name: "FAIRMONEY MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090551.svg",
    },
    {
      bank_code: "090552",
      bank_name: "EKIMOGUN MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090552.svg",
    },
    {
      bank_code: "090553",
      bank_name: "CONSISTENT TRUST MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090553.svg",
    },
    {
      bank_code: "090554",
      bank_name: "KAYVEE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090554.svg",
    },
    {
      bank_code: "090555",
      bank_name: "BISHOPGATE MICROFINANCE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090555.svg",
    },
    {
      bank_code: "090556",
      bank_name: "EGWAFIN MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090556.svg",
    },
    {
      bank_code: "090557",
      bank_name: "LIFEGATE MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090557.svg",
    },
    {
      bank_code: "090558",
      bank_name: "SHONGOM MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090558.svg",
    },
    {
      bank_code: "090560",
      bank_name: "TANADI MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090560.svg",
    },
    {
      bank_code: "090562",
      bank_name: "CEDAR MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090562.svg",
    },
    {
      bank_code: "090563",
      bank_name: "BALERA MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090563.svg",
    },
    {
      bank_code: "100001",
      bank_name: "FETS",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100001.svg",
    },
    {
      bank_code: "100002",
      bank_name: "PAGA",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100002.svg",
    },
    {
      bank_code: "100003",
      bank_name: "PARKWAY-READYCASH",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100003.svg",
    },
    {
      bank_code: "100004",
      bank_name: "OPAY",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100004.svg",
    },
    {
      bank_code: "100005",
      bank_name: "CELLULANT",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100005.svg",
    },
    {
      bank_code: "100006",
      bank_name: "ETRANZACT",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100006.svg",
    },
    {
      bank_code: "100007",
      bank_name: "STANBIC IBTC @EASE WALLET",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100007.svg",
    },
    {
      bank_code: "100008",
      bank_name: "ECOBANK XPRESS ACCOUNT",
      bank_logo_url: "https://nigerialogos.com/logos/ecobank/ecobank.png",
    },
    {
      bank_code: "100010",
      bank_name: "TEASYMOBILE",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100010.svg",
    },
    {
      bank_code: "100012",
      bank_name: "VTNetwork",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100012.svg",
    },
    {
      bank_code: "100013",
      bank_name: "ACCESSMONEY",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100013.svg",
    },
    {
      bank_code: "100014",
      bank_name: "FIRSTMONIE WALLET",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100014.svg",
    },
    {
      bank_code: "100016",
      bank_name: "FORTISMOBILE",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100016.svg",
    },
    {
      bank_code: "100019",
      bank_name: "FIDELITY MOBILE",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100019.svg",
    },
    {
      bank_code: "100022",
      bank_name: "GOMONEY",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100022.svg",
    },
    {
      bank_code: "100023",
      bank_name: "TAGPAY",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100023.svg",
    },
    {
      bank_code: "100024",
      bank_name: "IMPERIAL HOMES MORTGAGE BANK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100024.svg",
    },
    {
      bank_code: "100025",
      bank_name: "KONGAPAY",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100025.svg",
    },
    {
      bank_code: "100026",
      bank_name: "CARBON",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100026.svg",
    },
    {
      bank_code: "100027",
      bank_name: "INTELLIFIN",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100027.svg",
    },
    {
      bank_code: "100028",
      bank_name: "AG MORTGAGE BANK PLC",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100028.svg",
    },
    {
      bank_code: "100031",
      bank_name: "FCMB EASY ACCOUNT",
      bank_logo_url: "https://nigerialogos.com/logos/fcmb/fcmb.png",
    },
    {
      bank_code: "100032",
      bank_name: "CONTEC GLOBAL",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100032.svg",
    },
    {
      bank_code: "100033",
      bank_name: "PALMPAY",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100033.svg",
    },
    {
      bank_code: "100034",
      bank_name: "ZWallet",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100034.svg",
    },
    {
      bank_code: "100035",
      bank_name: "M36",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100035.svg",
    },
    {
      bank_code: "100036",
      bank_name: "KEGOW(CHAMSMOBILE)",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100036.svg",
    },
    {
      bank_code: "100039",
      bank_name: "TITAN-PAYSTACK",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100039.svg",
    },
    {
      bank_code: "100052",
      bank_name: "BETA-ACCESS YELLO",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_100052.svg",
    },
    {
      bank_code: "110010",
      bank_name: "Interswitch Financial Inclusion Services (IFIS)",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110010.svg",
    },
    {
      bank_code: "110011",
      bank_name: "Arca Payments",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110011.svg",
    },
    {
      bank_code: "110015",
      bank_name: "Vas2nets Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110015.svg",
    },
    {
      bank_code: "110017",
      bank_name: "CROWDFORCE",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110017.svg",
    },
    {
      bank_code: "110018",
      bank_name: "Microsystems Investment and Development Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110018.svg",
    },
    {
      bank_code: "110019",
      bank_name: "NIBSSUSSD PAYMENTS",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110019.svg",
    },
    {
      bank_code: "110021",
      bank_name: "Bud infrastructure Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110021.svg",
    },
    {
      bank_code: "110022",
      bank_name: "Koraypay",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110022.svg",
    },
    {
      bank_code: "110023",
      bank_name: "Capricorn Digital",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110023.svg",
    },
    {
      bank_code: "110024",
      bank_name: "Resident Fintech Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110024.svg",
    },
    {
      bank_code: "110025",
      bank_name: "NetApps Technology Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110025.svg",
    },
    {
      bank_code: "110026",
      bank_name: "SPAY business",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110026.svg",
    },
    {
      bank_code: "110027",
      bank_name: "Yello Digital financial services",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110027.svg",
    },
    {
      bank_code: "110028",
      bank_name: "Nomba financial services Limited",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110028.svg",
    },
    {
      bank_code: "110029",
      bank_name: "Woven Finance",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_110029.svg",
    },
    {
      bank_code: "120001",
      bank_name: "9 payment service Bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_120001.svg",
    },
    {
      bank_code: "120002",
      bank_name: "HopePSB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_120002.svg",
    },
    {
      bank_code: "120003",
      bank_name: "MoMo PSB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_120003.svg",
    },
    {
      bank_code: "120004",
      bank_name: "SmartCash Payment Service bank",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_120004.svg",
    },
    {
      bank_code: "120005",
      bank_name: "Money Master PSB",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_120005.svg",
    },
    {
      bank_code: "400001",
      bank_name: "FSDH",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_400001.svg",
    },
    {
      bank_code: "000033",
      bank_name: "e-Naira",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_000033.svg",
    },
    {
      bank_code: "050001",
      bank_name: "COUNTY FINANCE LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_050001.svg",
    },
    {
      bank_code: "050004",
      bank_name: "NEWEDGE FINANCE LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_050004.svg",
    },
    {
      bank_code: "090559",
      bank_name: "SHIELD MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090559.svg",
    },
    {
      bank_code: "090564",
      bank_name: "SUPREME MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090564.svg",
    },
    {
      bank_code: "090565",
      bank_name: "OKE-ARO OREDEGBE MICROFINANCE BANK LTD",
      bank_logo_url:
        "https://advanclystorage.blob.core.windows.net/advancly/banks_logo/bank_090565.svg",
    },
  ],
  message: "Success",
  status: true,
  status_code: 200,
};

export const mockSupportedBills = {
  data: ["electricity", "television", "telco"],
  message: "Supported bills fetched successfully",
  status: true,
  status_code: 200,
};

export const mockTVProviderList = {
  data: [
    {
      description: "Digital Satellite Television",
      operator_id: "op_pspQ6hAfsNd3mfKsY9rCik",
      name: "DSTV",
    },
    {
      description: "GoTV Nigeria",
      operator_id: "op_ry32iksGwx6nQQnVAHu8N7",
      name: "GOTV",
    },
    {
      description: "StarTimes Nigeria",
      operator_id: "op_BLVi7pAoj2LHbvNS89W8TU",
      name: "STARTIMES",
    },
  ],
  message: "Supported operators for television fetched successfully",
  status: true,
  status_code: 200,
};

export const mockElectricityProviderList = {
  data: [
    {
      description: "Ibadan Electricity Distribution Company",
      operator_id: "op_deR4dx7V5B28Fn5Pij9ach",
      name: "IBEDC",
    },
    {
      description: "Kano Electricity Distribution Company",
      operator_id: "op_8uNjD7x8s2SnSwPzquEcQi",
      name: "KEDCO",
    },
    {
      description: "Aba Power Limited Electric",
      operator_id: "op_7VCucgjYSDXrKKfDBrDey8",
      name: "APLE",
    },
    {
      description: "AES Jos Electricity Distribution Company",
      operator_id: "op_5rYjsdbMtTkgu7bcnkXuai",
      name: "AES_JEDC",
    },
    {
      description: "CEL Jos Electricity Distribution Company",
      operator_id: "op_pQJjkwnwhXrRdanyfvrXYB",
      name: "CEL_JEDC",
    },
    {
      description: "Benin Electricity Distribution Company",
      operator_id: "op_kXdyUvFZVzMgXDoFJabrv4",
      name: "BEDC",
    },
    {
      description: "Eko Electricity Distribution Company",
      operator_id: "op_xctXVabViUHmyLPdW9fDFJ",
      name: "EKEDC",
    },
    {
      description: "Kaduna Electricity Distribution Company",
      operator_id: "op_D4M9raYX3QGQ2eXCphkfB2",
      name: "KAEDCO",
    },
    {
      description: "Abuja Electricity Distribution Company",
      operator_id: "op_sBaoehA7TQCy8igjYKfC6v",
      name: "AEDC",
    },
    {
      description: "Port Harcourt Electricity Distribution Company",
      operator_id: "op_4NHWfrNyzT5kN8GsQYTZot",
      name: "PHEDC",
    },
    {
      description: "Ikeja Electricity Distribution Company",
      operator_id: "op_RszwtPMnaooVgCsEcJWcEF",
      name: "IKEDC",
    },
    {
      description: "Enugu Electricity Distribution Company",
      operator_id: "op_gprNgsEdQFeQ6K4p7ELSwS",
      name: "EEDC",
    },
  ],
  message: "Supported operators for electricity fetched successfully",
  status: true,
  status_code: 200,
};

export const mockAirtimeProviderList = {
  data: [
    {
      description: "MTN Nigeria",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      name: "MTN",
    },
    {
      description: "Airtel Nigeria",
      operator_id: "op_j3S5RJ2ZJYvfvchYq3fpFU",
      name: "Airtel",
    },
    {
      description: "9Mobile Nigeria",
      operator_id: "op_6ZwjemDTAYz5mZWJMLFRGc",
      name: "9Mobile",
    },
    {
      description: "Globacom (Glo Nigeria)",
      operator_id: "op_6jYrpyHjJoG2SdeyfzmMSS",
      name: "Glo",
    },
    {
      description: "Visafone Nigeria",
      operator_id: "op_jDMM6BQrbmVfS6eqXytVAL",
      name: "Visafone",
    },
  ],
  message: "Supported operators for telco fetched successfully",
  status: true,
  status_code: 200,
};

export const mockAirtimeProducts = {
  data: [
    {
      product_id: "prd_veH4vW7gbaLdxGpKdVANhF",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "RANGE",
      currency: "NGN",
      fee_amount: 0,
      name: "MTN -- Airtime",
      minimum_fee_amount: 1,
      maximum_fee_amount: 300000,
      data_expiry: null,
      data_value: null,
    },
    {
      product_id: "prd_23sxaVEYiVyQGaTj9giUiD",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 300,
      name: "MTN -- 1GB Daily Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "1 day",
      data_value: "1GB",
    },
    {
      product_id: "prd_hZWpK2tio9Amm2k67a99sg",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 100,
      name: "MTN -- 100MB Daily Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "1 day",
      data_value: "100MB",
    },
    {
      product_id: "prd_z3cHWZChKCdMna5KEqBVrN",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 300,
      name: "MTN -- 350MB Weekly data plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "7 days",
      data_value: "350MB",
    },
    {
      product_id: "prd_zsZLmFv2J8L68LPahKHpmr",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 1500,
      name: "MTN -- 6GB Weekly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "7 days",
      data_value: "6GB",
    },
    {
      product_id: "prd_eeFidq2pwdi5LJ2B9imQVZ",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 1200,
      name: "MTN -- 2GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "2GB",
    },
    {
      product_id: "prd_TKTo8P5AkF78FcUYxpb7Ky",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 3500,
      name: "MTN -- 12GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "12GB",
    },
    {
      product_id: "prd_Hnwg5PAeugqMtHKT8tZPsu",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 1500,
      name: "MTN -- 3GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "3GB",
    },
    {
      product_id: "prd_5nhRkDYsHQaYUtorGZWa99",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 6000,
      name: "MTN -- 25GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "25GB",
    },
    {
      product_id: "prd_PUXJxFC2SgEYs7JwzBhB3t",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 2500,
      name: "MTN -- 6GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "6GB",
    },
    {
      product_id: "prd_oiocNADYRT7PLiDiGW8z2Z",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 20000,
      name: "MTN -- 120GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "120GB",
    },
    {
      product_id: "prd_zdyPEDp2ms6R2Qn5kfUugX",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 75000,
      name: "MTN -- 250GB 3-Month Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "90 days",
      data_value: "250GB",
    },
    {
      product_id: "prd_D6HCQqzonNHV8TgoBGXBAd",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 50000,
      name: "MTN -- 150GB 3-Month Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "90 days",
      data_value: "150GB",
    },
    {
      product_id: "prd_qxUrGL98VkVxZRDx3mZozZ",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 500,
      name: "MTN -- 2.5GB 2-Day Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: null,
      data_value: "2.5GB",
    },
    {
      product_id: "prd_L5FSbSXMtiCevA3rbMMj7f",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 500,
      name: "MTN -- 1GB Weekly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "7 days",
      data_value: "1GB",
    },
    {
      product_id: "prd_99s9YUamMgVNFVpD9QUMZL",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 1000,
      name: "MTN -- 1.5GB Monthly Data plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "1.5GB",
    },
    {
      product_id: "prd_W9qP8huiLQcDvhR9c88LRs",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 2000,
      name: "MTN -- 4.5GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "4.5GB",
    },
    {
      product_id: "prd_Niy5uCtCvXwBAusywaRSdn",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 5000,
      name: "MTN -- 20GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "20GB",
    },
    {
      product_id: "prd_4r2Y29P2cRtToFYPqRUy7Y",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 10000,
      name: "MTN -- 40GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "40GB",
    },
    {
      product_id: "prd_vUE6D7UCFZgEFCkS2YFphM",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 15000,
      name: "MTN -- 75GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "75GB",
    },
    {
      product_id: "prd_tUbjHmTrK7dmMqtuVGhTMX",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 3000,
      name: "MTN -- 10GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "10GB",
    },
    {
      product_id: "prd_bWHZr2zDUVnhSmeykznNt8",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 20000,
      name: "MTN -- 110GB Monthly Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "30 days",
      data_value: "110GB",
    },
    {
      product_id: "prd_vxmMGVACM9w52TW8WQvAHN",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 200,
      name: "MTN -- 200MB 3-Day Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "3 days",
      data_value: "200MB",
    },
    {
      product_id: "prd_M5KwEwdYAeo3vou9SoJqeE",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 500,
      name: "MTN -- 750MB 2-Week Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "14 days",
      data_value: "750MB",
    },
    {
      product_id: "prd_io3HvC8oCrpsL4isWJSeY4",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 20000,
      name: "MTN -- 75GB 2-Month Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "60 days",
      data_value: "75GB",
    },
    {
      product_id: "prd_UgXkxyV9hjZ4QHcPdhbVUW",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 30000,
      name: "MTN -- 120GB 2-Month Plan",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: "60 days",
      data_value: "120GB",
    },
    {
      product_id: "prd_T3X8LStGcdGQTVa9FGTXnq",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 700,
      name: "MTN -- 3GB 2-Days Bundle",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: null,
      data_value: "3GB",
    },
    {
      product_id: "prd_RgmjfdwQp4QbM7B2kpCtCX",
      operator_id: "op_xoaaKAWhcZ3RwGfMKjpmag",
      fee_type: "FIXED",
      currency: "NGN",
      fee_amount: 1800,
      name: "MTN -- 7GB Weekly Bundle",
      minimum_fee_amount: 0,
      maximum_fee_amount: 0,
      data_expiry: null,
      data_value: "7GB",
    },
  ],
  message: "Supported products fetched successfully",
  status: true,
  status_code: 200,
};

export const mockValidateDevice = {
  message: "Customer television device number validated successfully",
  status: true,
  status_code: 200,
};

export const mockPostBillPayment = {
  message: "Bill payment was successful",
  status: true,
  status_code: 200,
};

export enum ESupportedBills {
  ELECTRICITY = "electricity",
  TELEVISION = "television",
  TELCO = "telco",
}

export const naira = "₦";
