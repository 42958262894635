import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getData, postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputFieldFormItem from "../../components/CustomHTMLElements/CustomInputFieldFormItem";
import LandingLayout from "../../components/LandingLayout/LandingLayout.component";
import { ROUTES } from "../../helpers/routes";
import { add } from "date-fns";
import { isUserActivated } from "./../../helpers/isUserActivated";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAirtimeProviders,
  getBankList,
  getCurrencies,
  getElectricityProviders,
  getSupportedBills,
  getTVProviders,
  getNairaWalletDetails,
  resetSignUp,
  setCurrentUser,
  setMinimumTopUpAmounts,
  setUserToken,
} from "../../redux/actions";
import { ReduxStoreModel } from "../../interfaces/redux";
import useWalletBalance from "../../hooks/custom-hooks/useWalletBalance";

const Login = () => {
  const user = useSelector((state: ReduxStoreModel) => state.user);
  const walletTopUpInfo = useSelector(
    (state: ReduxStoreModel) => state.walletTopup
  );

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackPageView({ name: "Login.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    const reqBody = {
      username: email,
      password,
    };
    try {
      const res: any = await postData(apiEndpoints.LOGIN, reqBody);
      dispatch(setUserToken(res?.sso_auth_token));
      const minimumTopUpAmounts = await getData(
        apiEndpoints.GET_MINIMUM_TOP_UP_AMOUNTS
      );
      const isValidUserType =
        res?.roles.includes("funder_user") ||
        res?.roles.includes("funder_admin");
      if (!isValidUserType) {
        setLoading(false);
        return message.error("Invalid login credentials.");
      }

      // Set when session will expire
      const session_timeout = add(new Date(), {
        seconds: res?.expiresIn,
      });

      const {
        data: {
          funder_id,
          funder_type_id,
          profile_completion_status,
          lcy_wallet_id,
          usd_wallet_id,
          biz_name,
          country,
          kyc_resolved_lastname,
          kyc_resolved_firstname,
          phone_no_country_dial_code,
          is_bvn_verified,
        },
      } = await getData(apiEndpoints.USER);

      const lcy_wallet = await getData(
        `${apiEndpoints.WALLET_BALANCE}?savingsWalletId=${lcy_wallet_id}&funderId=${funder_id}`
      );

      if (country === "NG" && !is_bvn_verified) {
        localStorage.setItem("bvnalert", "true");
      }
      dispatch(
        setMinimumTopUpAmounts({
          ...walletTopUpInfo,
          minimumTopUpAmounts: minimumTopUpAmounts.data,
        })
      );
      dispatch(
        setCurrentUser({
          ...user,
          first_name: res?.first_name,
          last_name: res?.last_name,
          email: res?.email,
          isBvnValidated: res?.isBvnValidated,
          isEmailValidated: res?.isEmailVerified,
          role: res?.roles[0],
          lcy_wallet_id,
          usd_wallet_id,
          session_timeout,
          funder_id,
          funder_type_id,
          country,
          phoneNoCountryDialCode: phone_no_country_dial_code,
          activated: isUserActivated({
            profile_completion_status,
          }),
          business_name: biz_name,
          forcePasswordChange: res.firstTimeLogin,
          kyc_resolved_lastname,
          kyc_resolved_firstname,
          token: res?.sso_auth_token,
          ssoUserId: res?.ssoUserId,
          customerAccountId: lcy_wallet.data?.customerAccountId,
        })
      );
      dispatch(getBankList());
      dispatch(getCurrencies());
      dispatch(getSupportedBills());
      dispatch(getTVProviders());
      dispatch(getAirtimeProviders());
      dispatch(getElectricityProviders());
      dispatch(getNairaWalletDetails(funder_id, lcy_wallet_id));
      setLoading(false);
      history.push(ROUTES.FUNDER);
    } catch (error) {
      setLoading(false);
      message.error(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,

        properties: { fileName: Login },
      });
    }
  };

  return (
    <LandingLayout>
      <Form
        layout="vertical"
        name="normal_login"
        className="login-form "
        requiredMark
        onFinish={(values) => onSubmit(values)}
        onFinishFailed={() => null}
        size="large"
      >
        <div className="mb-5">
          <h3 className="h3 font-weight-bold mb-3 text-dark">Welcome Back.</h3>
          <p>Login to manage your account.</p>
        </div>

        <CustomInputFieldFormItem
          fieldName="email"
          placeholder="Email"
          size="large"
          label="Email Address"
          rules={[
            {
              required: true,
              message: "Please enter your Email Address",
            },
          ]}
        />
        <CustomInputFieldFormItem
          fieldName="password"
          placeholder="Password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please enter your Password!",
            },
          ]}
          InputComponent={
            <Input.Password
              type="password"
              placeholder="Password"
              size="large"
            />
          }
        />

        <div className="d-flex justify-content-end align-items-center mb-3">
          <Link className="color-blue hover-class" to={ROUTES.RESET_PASSWORD}>
            Forgot Password?
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-5 flex-wrap">
          <p className="d-flex flex-wrap p-none">
            <span className="text-muted pr-2">Don't have an account? </span>
            <Link
              className="color-blue hover-class"
              to={ROUTES.SIGN_UP}
              onClick={() => {
                dispatch(resetSignUp());
              }}
            >
              Sign Up
            </Link>
          </p>
          <Button type="primary" htmlType="submit" loading={loading}>
            Log In
          </Button>
        </div>
      </Form>
      {/*  Redeploy*/}
    </LandingLayout>
  );
};

export default Login;
