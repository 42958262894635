import { encrypt } from "./../../helpers/encryptor";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { TUser } from "../../interfaces/user";
import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";

export const initialUserState = {
  first_name: "",
  last_name: "",
  email: "",
  role: "",
  session_timeout: "",
  funder_id: 0,
  funder_type_id: null,
  lcy_wallet_id: 0,
  usd_wallet_id: 0,
  activated: false,
  business_name: "",
  country: "",
  phoneNoCountryDialCode: "",
  forcePasswordChange: false,
  kyc_resolved_lastname: "",
  kyc_resolved_firstname: "",
  isBvnValidated: false,
  isEmailValidated: false,
  token: "",
  ssoUserId: "",
  showBalance: true,
  customerAccountId: 0,
};

let showBalance: boolean;

export const userReducer = (
  state: TUser = initialUserState,
  action: ReduxActionModel
): TUser => {
  switch (action.type) {
    // Set During Login, After Signup
    case actionType.SET_LOGGED_IN_USER:
      state.showBalance = showBalance;
      state = { ...state, ...action.payload };
      return state;

    case actionType.SET_LOGGED_IN_USER_TOKEN:
      const newToken = encrypt(action.payload);
      state = { ...state, token: action.payload };
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, newToken);
      return state;

    case actionType.SHOW_BALANCE:
      state.showBalance = action.payload;
      return state;

    // Set During Logout
    case actionType.CLEAR_LOGGED_IN_USER:
      showBalance = state.showBalance;
      state = initialUserState;
      localStorage.clear();
      return state;
    default:
      return state;
  }
};
