import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  bankCode: string | null;
  accountNumber: string;
  selectBeneficiaryData: any;
};

const BankAccountNameEnquiry = async ({ queryKey }: any) => {
  const { bankCode, accountNumber } = queryKey[1];

  const data = await getData(
    `${apiEndpoints.DIGITAL_BANK_GET_ACCOUNT_NAME}?account_number=${accountNumber}&bank_code=${bankCode}`
  );
  return data?.data;
};

export default function useBankAccountNameEnquiry({
  selectBeneficiaryData,
  bankCode,
  accountNumber,
}: paramProps) {
  return useQuery(
    [
      "BankAccountNameEnquiry",
      { selectBeneficiaryData, bankCode, accountNumber },
    ],
    BankAccountNameEnquiry,
    {
      enabled:
        !selectBeneficiaryData && bankCode && accountNumber?.length === 10
          ? true
          : false,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
}
