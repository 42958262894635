import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getData, postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputFieldFormItem from "../../components/CustomHTMLElements/CustomInputFieldFormItem";
import Loader from "../../components/Loader/Loader.component";
import { yupValidators } from "../../helpers/yupValidators";
// import { ReactComponent as Logo } from "../../assets/img/logo-blue.svg";
import { ROUTES } from "../../helpers/routes";
import Alert from "./../../components/Alert/Alert.component";
import { add } from "date-fns";
import { isUserActivated } from "./../../helpers/isUserActivated";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, setUserToken } from "../../redux/actions";
import { ReduxStoreModel } from "../../interfaces/redux";

interface IStepThreeProps {}

const schema = yup.object().shape({
  newPassword: yupValidators.newPassword,
  confirmPassword: yupValidators.confirmPassword,
});

const StepThree: React.FC<IStepThreeProps> = () => {
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { stateToken } = useSelector(
    (state: ReduxStoreModel) => state.resetPassword
  );
  const user = useSelector((state: ReduxStoreModel) => state?.user);

  useEffect(() => {
    appInsights.trackPageView({
      name: "ResetPasswordStepThree",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (!stateToken) {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    newPassword,
    confirmPassword,
  }: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    setError(null);
    setSuccess("");

    if (newPassword !== confirmPassword) {
      setLoading(false);
      return setError("Your passwords do not match.");
    }

    const reqBody = {
      reset_step: "third_step",
      password1: newPassword,
      password2: confirmPassword,
      state_token: stateToken,
    };
    try {
      const res = await postData(apiEndpoints.RESET_PASSWORD, reqBody);
      setSuccess(
        "You have set a new password. You will be redirected to your dashboard soon."
      );
      const isValidUserType =
        res?.roles.includes("funder_user") ||
        res?.roles.includes("funder_admin");

      if (!isValidUserType) {
        setLoading(false);
        return setError("Invalid login credentials.");
      }

      dispatch(setUserToken(res?.sso_auth_token));

      // Set when session will expire
      const session_timeout = add(new Date(), {
        seconds: res?.expiresIn,
      });

      const {
        data: {
          funder_id,
          funder_type_id,
          profile_completion_status,
          lcy_wallet_id,
          usd_wallet_id,
          biz_name,
          country,
        },
      } = await getData(apiEndpoints.USER);
      dispatch(
        setCurrentUser({
          ...user,
          first_name: res?.first_name,
          last_name: res?.last_name,
          email: res?.email,
          role: res?.roles[0],
          lcy_wallet_id,
          usd_wallet_id,
          session_timeout,
          funder_id,
          funder_type_id,
          country,
          activated: isUserActivated({
            profile_completion_status,
          }),
          business_name: biz_name,
        })
      );

      setTimeout(() => {
        setLoading(false);
        history.push(ROUTES.FUNDER);
      }, 2000);
    } catch (error) {
      setError(errorHandler(error));
      setLoading(false);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "ResetPasswordStepThree" },
      });
    }
  };

  const togglePasswordField = (field: number) => {
    if (field === 1) {
      if (newPasswordType === "password") {
        setNewPasswordType("text");
      } else {
        setNewPasswordType("password");
      }
    } else if (field === 2) {
      if (confirmPasswordType === "password") {
        setConfirmPasswordType("text");
      } else {
        setConfirmPasswordType("password");
      }
    }
  };

  return (
    <Fragment>
      <form
        className="my-5 w-100 centralize-page"
        onSubmit={handleSubmit(onSubmit)}
      >
        {error && <Alert message={error} />}
        {success && <Alert message={success} type="success" />}
        {/* <Link to={ROUTES.LOGIN} className="mb-5 text-center">
          <Logo />
        </Link> */}
        <div className="mb-3 text-center">
          <h3 className="font-weight-bold mb-3 color-dark-blue">
            Reset Password
          </h3>
          <p>Enter a new password</p>
        </div>
        <CustomInputFieldFormItem
          type={newPasswordType}
          maxLength={128}
          {...register("newPassword")}
          name="newPassword"
          placeholder="********"
          label="Password"
          errors={errors.newPassword}
          hasActionButton={true}
          actionButtonText={newPasswordType === "password" ? "show" : "hide"}
          onClickActionButton={() => togglePasswordField(1)}
        />
        <CustomInputFieldFormItem
          type={confirmPasswordType}
          maxLength={128}
          {...register("confirmPassword")}
          name="confirmPassword"
          placeholder="********"
          label="Re-enter Password"
          errors={errors.confirmPassword}
          hasActionButton={true}
          actionButtonText={
            confirmPasswordType === "password" ? "show" : "hide"
          }
          onClickActionButton={() => togglePasswordField(2)}
        />

        <div className="w-100 mt-2">
          <button
            type="submit"
            value="submit"
            className="btn advancly-btn btn-sm w-100"
            disabled={loading}
          >
            Continue
            {loading && <Loader />}
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default StepThree;
