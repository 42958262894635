import React, { Dispatch, SetStateAction } from "react";
import { Button, Card } from "antd";
import formatMoney from "../../../../helpers/formatMoney";
import { naira } from "../../../../Constants/mock";
import { ItransferBody } from "../NGNTransferDrawer/NGNTransferDrawer.component";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";

interface IProps {
  requestBody: ItransferBody | null;
  currency: "$" | "₦";
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
}

const TransferReviewPayment = ({
  requestBody,
  setCurrentStep,
  currency,
}: IProps) => {
  const { bankList } = useSelector((state: ReduxStoreModel) => state.bankList);
  return (
    <div className="w-100">
      <Card style={{ width: "100%" }}>
        <h4 className="text-center">
          {formatMoney(requestBody?.amount || 0, currency)}
        </h4>
        <p className="text-center">Transfer to bank</p>
        <div className="d-flex justify-content-between">
          <p>Bank</p>
          <p className="font-weight-bold">
            {
              bankList.find(
                (b: { bank_code: string }) =>
                  b.bank_code === requestBody?.recipient_bank_code
              )?.bank_name
            }
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Account Number</p>
          <p className="font-weight-bold">
            {requestBody?.recipient_bank_account_number}
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Account Name</p>
          <p className="font-weight-bold">
            {requestBody?.recipient_bank_account_name}
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Amount</p>
          <p className="font-weight-bold">
            {formatMoney(requestBody?.amount || 0, currency)}
          </p>
        </div>
      </Card>
      <Button
        block
        type="primary"
        className="mt-5"
        size="large"
        onClick={() => setCurrentStep("enter-transaction-pin")}
      >
        Continue
      </Button>
    </div>
  );
};

export default TransferReviewPayment;
