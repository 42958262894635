import React, { Dispatch, SetStateAction } from "react";
import styles from "../InvestmentModalStepThree.module.scss";
import { FormEvent } from "react";
import { usePaystackPayment } from "react-paystack";
import { paystackConfig } from "./../../../../../helpers/thirdPartyConfigs";
import { PAYMENT_OPTIONS } from "./../../../../../helpers/paymentOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvestmentStep,
  setOnlineInvestmentInfo,
} from "../../../../../redux/actions";
import { ReduxStoreModel } from "../../../../../interfaces/redux";

interface IInvestmentModalStepThreeInitiate {
  setSection: Dispatch<SetStateAction<"initiate" | "confirm" | "success">>;
  setError: Dispatch<SetStateAction<string>>;
}

const InvestmentModalStepThreeInitiate: React.FC<
  IInvestmentModalStepThreeInitiate
> = ({ setSection, setError }) => {
  const dispatch = useDispatch();
  const { amount, currency, paymentMethod, reference } = useSelector(
    (state: ReduxStoreModel) => state.investment.onlineInvestmentInfo
  );
  const email = useSelector((state: ReduxStoreModel) => state?.user?.email);

  let config = paystackConfig({
    email,
    amount: Number(amount),
    //@ts-ignore
    currency,
    //@ts-ignore
    reference,
  });

  const onPaystackSuccess = ({
    reference,
    status,
    message,
  }: {
    reference: string;
    status: string;
    message: string;
  }) => {
    dispatch(
      setOnlineInvestmentInfo({
        paymentGatewayReference: reference,
        status,
        response: message,
      })
    );
    setSection("success");
  };

  const onPaystackClose = () => {
    // console.log("closed");
  };

  //@ts-ignore
  const initializePayment = usePaystackPayment(config);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    if (paymentMethod === PAYMENT_OPTIONS.PAYSTACK) {
      return initializePayment(onPaystackSuccess, onPaystackClose);
    } else if (paymentMethod === PAYMENT_OPTIONS.WALLET) {
      return setSection("confirm");
    } else {
      return setError("Please choose a payment method");
    }
  };
  return (
    <div>
      <h5 className="page-subtitle mb-3">Payment Method</h5>
      <p className="form-label">How would you like to proceed with payment?</p>
      <form className="mt-4" onSubmit={onSubmit}>
        <div className="mb-5">
          <div
            className={`${styles.box} ${
              paymentMethod === PAYMENT_OPTIONS.WALLET && styles.active
            }`}
            onClick={() =>
              setOnlineInvestmentInfo({
                paymentMethod: PAYMENT_OPTIONS.WALLET,
              })
            }
          >
            <div className="form-check">
              <input
                className="form-check-input cursor-pointer"
                type="radio"
                checked={paymentMethod === PAYMENT_OPTIONS.WALLET}
                onChange={() => {}}
              />
              <label className="form-check-label">Pay with Wallet</label>
            </div>
          </div>
          {currency !== "USD" && (
            <div
              className={`${styles.box} ${
                paymentMethod === PAYMENT_OPTIONS.PAYSTACK && styles.active
              }`}
              onClick={() =>
                setOnlineInvestmentInfo({
                  paymentMethod: PAYMENT_OPTIONS.PAYSTACK,
                })
              }
            >
              <div className="form-check">
                <input
                  className="form-check-input cursor-pointer"
                  type="radio"
                  checked={paymentMethod === PAYMENT_OPTIONS.PAYSTACK}
                  onChange={() => {}}
                />
                <label className="form-check-label">
                  Pay with Paystack (Cards, Bank, Transfer)
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-end mt-5 mb-3">
          <button
            type="button"
            className="btn btn-sm advancly-nobg-btn mr-2"
            onClick={() => dispatch(setInvestmentStep(3))}
          >
            Go back
          </button>
          <button
            type="submit"
            className="btn btn-sm advancly-btn"
            disabled={!paymentMethod}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};
export default InvestmentModalStepThreeInitiate;
