import { Dispatch, Fragment, SetStateAction, useState } from "react";
import OfflineInvestmentStepThree from "./OfflineInvestmentStepThree.component";
import OfflineInvestmentStepOne from "./OfflineInvestmentStepOne.component";
import OfflineInvestmentStepTwo from "./OfflineInvestmentStepTwo.component";
import ProgressIndicator from "./../../ProgressIndicator/ProgressIndicator.component";
import { OfflineInvestmentBankDetails } from "./OfflineInvestmentBankDetails.component";
import TermsAndConditionOffline from "./TermsAndConditionsModalOffline/TermsAndConditionOffline";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux";

interface IOfflineInvestmentLanding {
  closeModal: () => void;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const OfflineInvestmentLanding: React.FC<IOfflineInvestmentLanding> = ({
  closeModal,
  error,
  setError,
  success,
  setSuccess,
}) => {
  const currentStep = useSelector(
    (state: ReduxStoreModel) => state.investment.currentStep
  );
  const [showBankDetails, setShowBankDetails] = useState(false);

  return (
    <Fragment>
      {(() => {
        switch (currentStep) {
          case 1:
            return (
              <WrapWithProgressIndicator>
                <OfflineInvestmentStepOne setError={setError} />
              </WrapWithProgressIndicator>
            );

          case 2:
            return (
              <Fragment>
                {showBankDetails ? (
                  <OfflineInvestmentBankDetails
                    setShowBankDetails={setShowBankDetails}
                  />
                ) : (
                  <WrapWithProgressIndicator>
                    <TermsAndConditionOffline
                      setShowBankDetails={setShowBankDetails}
                    />
                  </WrapWithProgressIndicator>
                )}
              </Fragment>
            );

          case 3:
            return (
              <WrapWithProgressIndicator>
                <OfflineInvestmentStepTwo setError={setError} />
              </WrapWithProgressIndicator>
            );

          case 4:
            return (
              <OfflineInvestmentStepThree
                closeModal={closeModal}
                error={error}
                setError={setError}
                success={success}
                setSuccess={setSuccess}
              />
            );
          default:
            return null;
        }
      })()}
    </Fragment>
  );
};

const WrapWithProgressIndicator: React.FC<{}> = ({ children }) => {
  return (
    <Fragment>
      <ProgressIndicator />
      {children}
    </Fragment>
  );
};

export default OfflineInvestmentLanding;
