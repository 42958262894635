import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import CustomInputFieldFormItem from "../../../../CustomHTMLElements/CustomInputFieldFormItem";
import { ReactComponent as AccountUserIcon } from "../../../../../assets/img/bankuser-icon.svg";
import useBankAccountNameEnquiry from "../../../../../hooks/custom-hooks/useBankAccountNameEnquiry";
import {
  ItransferBody,
  TransferNGNSteps,
} from "../NGNTransferDrawer.component";
import { IBeneficiaries } from "../NGNTransferDrawerStepOne/NGNTransferDrawerStepOne";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
  Switch,
} from "antd";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux";
import formatMoney from "../../../../../helpers/formatMoney";

interface IWalletTransferNGNStepThree {
  bankCodeState: string | null;
  selectBankName: boolean;
  activeTab: string;
  setBankTransferReqBody: Dispatch<SetStateAction<ItransferBody | null>>;
  setCurrentStep: Dispatch<SetStateAction<TransferNGNSteps>>;
  getBeneficiaryShortName: (name: string | undefined) => string | any;
  selectBeneficiary: boolean;
  selectBeneficiaryData: IBeneficiaries | any;
  setSaveBeneficiary: Dispatch<SetStateAction<boolean>>;
  setBankName: Dispatch<SetStateAction<string | null>>;
  setSelectBankName: Dispatch<SetStateAction<boolean>>;
  setSelectBeneficiary: Dispatch<SetStateAction<boolean>>;
  setSelectBeneficiaryData: Dispatch<SetStateAction<IBeneficiaries | null>>;
  setBankCode: Dispatch<SetStateAction<string | null>>;
  bankTransferReqBody: ItransferBody;
}

const NGNTransferDrawerStepThree: React.FC<IWalletTransferNGNStepThree> = ({
  bankCodeState,
  selectBankName,
  activeTab,
  setBankTransferReqBody,
  setCurrentStep,
  selectBeneficiary,
  selectBeneficiaryData,
  setSelectBeneficiaryData,
  setSaveBeneficiary,
  bankTransferReqBody,
}) => {
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const { wallet } = useSelector((state: ReduxStoreModel) => state.wallet);
  const { bankList } = useSelector((state: ReduxStoreModel) => state.bankList);
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const [form] = Form.useForm();

  const [currentAccountName, setCurrentAccountName] = useState<any>();
  const [accountNumber, setAccountNumber] = useState(
    bankTransferReqBody?.recipient_bank_account_number || ""
  );
  const [bankName, setBankName] = useState<string | null>(null);

  const {
    data: bankAccountNameEnquiryData,
    isLoading: bankAccountNameEnquiryLoading,
    error: bankAccountNameEquiryError,
  } = useBankAccountNameEnquiry({
    bankCode: bankName,
    accountNumber,
    selectBeneficiaryData,
  });

  useEffect(() => {
    if (bankAccountNameEquiryError) {
      message.error(errorHandler(bankAccountNameEquiryError));
    }
  }, [bankAccountNameEquiryError]);

  useEffect(() => {
    if (bankTransferReqBody) {
      const {
        amount,
        narration,
        recipient_bank_code,
        recipient_bank_account_number,
      } = bankTransferReqBody;
      form.setFieldsValue({
        account_number: recipient_bank_account_number,
        bank_name: recipient_bank_code,
        narration: narration,
        amount: amount,
      });
    } else {
      if (!selectBeneficiaryData) {
        if (bankCodeState) {
          form.setFieldsValue({
            bank_name: bankCodeState,
          });
        }
      } else if (currentAccountName && accountNumber && bankName) {
        form.setFieldsValue({
          account_number: accountNumber,
          bank_name: bankName,
        });
      }
    }
  }, [
    currentAccountName,
    accountNumber,
    bankName,
    bankCodeState,
    bankTransferReqBody,
  ]);

  useEffect(() => {
    if (selectBankName) {
      setTimeout(() => {
        setBankName(bankCodeState);
      }, 1000);
    }
  }, [selectBankName, bankCodeState]);
  //checking for both recent recipient and beneficiaries to autopopulate
  useEffect(() => {
    if (selectBeneficiary) {
      setTimeout(() => {
        if (
          selectBeneficiaryData?.bank_code &&
          selectBeneficiaryData?.account_number
        ) {
          setBankName(selectBeneficiaryData?.bank_code);
          setAccountNumber(selectBeneficiaryData?.account_number);
        } else {
          setBankName(selectBeneficiaryData?.recipient_bank_code);
          setAccountNumber(selectBeneficiaryData?.recipient_account_number);
        }
      }, 1000);
    }
  }, [selectBeneficiary, selectBeneficiaryData]);

  useEffect(() => {
    if (
      selectBeneficiaryData?.account_name ||
      selectBeneficiaryData?.recipient_account_name
    ) {
      selectBeneficiaryData?.account_name
        ? setCurrentAccountName(selectBeneficiaryData?.account_name)
        : setCurrentAccountName(selectBeneficiaryData?.recipient_account_name);
    } else {
      setCurrentAccountName(bankAccountNameEnquiryData?.account_name);
    }
  }, [
    selectBeneficiaryData,
    bankAccountNameEnquiryData,
    setCurrentAccountName,
  ]);

  const onSubmit = ({
    account_number,
    amount,
    bank_name,
    narration,
    save_beneficiary,
  }: any) => {
    if (save_beneficiary) {
      setSaveBeneficiary(save_beneficiary);
    }
    const transferReqBody = {
      customer_id: funderId,

      saving_wallet_id: activeTab === "USD" ? usd_wallet_id : lcy_wallet_id,

      amount: amount,

      narration: narration,

      recipient_bank_code: bank_name,

      recipient_bank_account_number: account_number,

      recipient_bank_account_name: currentAccountName,
    };
    setBankTransferReqBody(transferReqBody);
    setCurrentStep(TransferNGNSteps.ReviewPayment);
  };

  return (
    <div>
      <Fragment>
        <Form
          layout="vertical"
          name="normal_login"
          className="login-form "
          requiredMark
          onFinish={(values) => {
            onSubmit(values);
          }}
          onFinishFailed={() => null}
          size="large"
          form={form}
        >
          <CustomInputFieldFormItem
            fieldName="source_account"
            label="Source Account"
            initialValue={`${wallet?.accountNumber} - ${formatMoney(
              wallet?.availableBalance || 0,
              "NGN"
            )} (${wallet?.accountName})`}
            readOnly
            size="large"
          />
          <CustomInputFieldFormItem
            fieldName="bank_name"
            placeholder="Bank Name"
            label="Bank Name"
            rules={[
              {
                required: true,
                message: "Select Bank Name",
              },
            ]}
            InputComponent={
              <Select
                onChange={(val: any) => {
                  const bank = bankList.find(
                    (b: { bank_code: string }) => b.bank_code === val
                  );
                  if (bank) {
                    setBankName(bank.bank_code);
                    setSelectBeneficiaryData(null);
                  }
                }}
              >
                <Select.Option value="">Choose Bank</Select.Option>
                {bankList?.map(
                  (
                    {
                      bank_name,
                      bank_code,
                    }: { bank_name: string; bank_code: string },
                    id: any
                  ) => (
                    <Select.Option value={bank_code} key={id}>
                      {bank_name}
                    </Select.Option>
                  )
                )}
              </Select>
            }
          />
          <CustomInputFieldFormItem
            fieldName="account_number"
            type="number"
            maxLength={20}
            size="large"
            label="Enter Account number"
            suffix={bankAccountNameEnquiryLoading ? <Spin /> : null}
            rules={[
              {
                required: true,
                message: "Please enter your Account Number",
              },
            ]}
            onChange={(e) => {
              setAccountNumber(e.target.value);
              if (bankTransferReqBody) {
                const {
                  amount,
                  narration,
                  recipient_bank_account_name,
                  recipient_bank_code,
                  customer_id,
                  saving_wallet_id,
                } = bankTransferReqBody;
                setBankTransferReqBody({
                  amount,
                  narration,
                  recipient_bank_code,
                  customer_id,
                  saving_wallet_id,
                  recipient_bank_account_name,
                  recipient_bank_account_number: e.target.value,
                });
              }
            }}
          />
          {currentAccountName?.length > 1 && (
            <div className="d-flex align-items-center">
              <AccountUserIcon />{" "}
              <span
                className="pl-1"
                style={{ color: "#713FFF", fontSize: "0.875rem" }}
              >
                {currentAccountName}
              </span>
            </div>
          )}
          <CustomInputFieldFormItem
            fieldName="amount"
            placeholder="Enter Amount"
            type="number"
            size="large"
            label="Enter Amount"
            rules={[
              {
                required: true,
                message: "Please enter an Amount to transfer",
              },
            ]}
            InputComponent={
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
              />
            }
          />

          <CustomInputFieldFormItem
            fieldName="narration"
            placeholder="Narration"
            size="large"
            label="Narration"
            rules={[
              {
                required: true,
                message: "Please enter transfer narration",
              },
            ]}
            InputComponent={<Input.TextArea rows={2} />}
          />

          <CustomInputFieldFormItem
            fieldName="save_beneficiary"
            size="large"
            InputComponent={
              <div className="d-flex align-items-center mt-5">
                <Switch
                  onChange={(value) => {
                    form.setFieldsValue({
                      save_beneficiary: value,
                    });
                  }}
                />{" "}
                <span className="ml-3">Save Beneficiary</span>
              </div>
            }
          />

          <Button
            htmlType="submit"
            type="primary"
            block
            className="mt-5"
            disabled={bankAccountNameEnquiryLoading || !currentAccountName}
          >
            Continue
          </Button>
        </Form>
      </Fragment>
    </div>
  );
};

export default NGNTransferDrawerStepThree;
