import React, { useEffect, useState } from "react";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import { Card } from "antd";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { ROUTES } from "../../../helpers/routes";
import billsAd from "../../../assets/img/pay/billsAd.png";
import { GiElectric } from "react-icons/gi";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import AirtimeDrawer from "../../../components/Pay/AirtimeDrawers/AirtimeDrawer.component";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux";
import { ESupportedBills } from "../../../Constants/mock";
import TVDrawer from "../../../components/Pay/TVDrawers/TVDrawer.component";
import {
  getBillPaymentBeneficiary,
  getBillPaymentTransactions,
  getNairaWalletDetails,
} from "../../../redux/actions";
import ElectricityDrawer from "../../../components/Pay/ElectricityDrawers/ElectricityDrawer.component";
import { BiError } from "react-icons/bi";

const PayBills = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const customerAccountId = useSelector(
    (state: ReduxStoreModel) => state?.user?.customerAccountId
  );
  const currencyList = useSelector(
    (state: ReduxStoreModel) => state.currencies.availableCurrencies
  );
  const { supportedBills } = useSelector(
    (state: ReduxStoreModel) => state.supportedBillLists
  );

  const { lcy_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );

  const [activeTab, setActiveTab] = useState("");
  const [airtimeDrawerIsOpened, setAirtimeDrawerIsOpened] = useState(false);
  const [tvDrawerIsOpened, setTVDrawerIsOpened] = useState(false);
  const [electricDrawerIsOpened, setElectricDrawerIsOpened] = useState(false);

  useEffect(() => {
    dispatch(getNairaWalletDetails(funderId, lcy_wallet_id));
  }, []);

  useEffect(() => {
    if (currencyList.length > 0) {
      setActiveTab(currencyList[0]?.currencyCode);
    }
  }, [currencyList]);

  useEffect(() => {
    if (airtimeDrawerIsOpened) {
      dispatch(
        getBillPaymentTransactions(
          customerAccountId,
          "NGN",
          ESupportedBills.TELCO
        )
      );
    }
    if (tvDrawerIsOpened) {
      dispatch(
        getBillPaymentTransactions(
          customerAccountId,
          "NGN",
          ESupportedBills.TELEVISION
        )
      );
    }
    if (electricDrawerIsOpened) {
      dispatch(
        getBillPaymentTransactions(
          customerAccountId,
          "NGN",
          ESupportedBills.ELECTRICITY
        )
      );
    }
  }, [airtimeDrawerIsOpened, tvDrawerIsOpened, electricDrawerIsOpened]);

  useEffect(() => {
    appInsights.trackPageView({ name: "PayBills.tsx", isLoggedIn: true });
  }, []);
  return (
    <div className="container actions">
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => history.goBack()}
      >
        <PiCaretLeftBold size={24} className="text-muted mr-1" />
        <p className="text-muted p-none">Go back</p>
      </div>
      <br />
      <div className="page-title">Pay Bills</div>
      <br />

      <div className="row">
        <div className="col-md-6">
          <Card
            bordered={false}
            style={{
              overflow: "auto",
              minHeight: "210px",
            }}
            className="mb-3 mb-md-0 d-flex align-items-center justify-content-center"
          >
            {supportedBills && supportedBills.length > 0 ? (
              <div>
                {supportedBills?.includes(ESupportedBills.ELECTRICITY) && (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => setElectricDrawerIsOpened(true)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="icon">
                        <GiElectric />
                      </div>
                      <p className="p-none font-weight-bold dashboard-actions__text">
                        Electricity
                      </p>
                    </div>
                    <div>
                      <PiCaretRightBold className="text-muted" size={16} />
                    </div>
                  </div>
                )}
                {supportedBills?.includes(ESupportedBills.TELCO) && (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => setAirtimeDrawerIsOpened(true)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="icon">
                        <HiOutlineDevicePhoneMobile />
                      </div>
                      <p className="p-none font-weight-bold dashboard-actions__text">
                        Airtime & Data
                      </p>
                    </div>
                    <div>
                      <PiCaretRightBold className="text-muted" size={16} />
                    </div>
                  </div>
                )}

                {supportedBills?.includes(ESupportedBills.TELEVISION) && (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ marginBottom: "-20px" }}
                    onClick={() => setTVDrawerIsOpened(true)}
                  >
                    <div
                      className="d-flex align-items-center"
                      onClick={() => history.push(`${ROUTES.PAY}/bills`)}
                    >
                      <div className="icon">
                        <HiOutlineDesktopComputer />
                      </div>
                      <p className="p-none font-weight-bold dashboard-actions__text">
                        TV Bills
                      </p>
                    </div>
                    <div>
                      <PiCaretRightBold className="text-muted" size={16} />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-center">
                  <BiError size="30px" style={{ color: "red" }} />
                </div>
                <p className="font-weight-bold text-center">
                  Unavailable to fetch supported bills
                </p>
              </div>
            )}
          </Card>
        </div>

        <div className="col-md-6">
          <img
            src={billsAd}
            alt="Coming soon"
            className="mw-100 mb-3 mb-md-0 w-100"
            style={{ objectFit: "contain", height: "218px" }}
          />
        </div>
      </div>
      <AirtimeDrawer
        activeTab={activeTab}
        open={airtimeDrawerIsOpened}
        onClose={() => setAirtimeDrawerIsOpened(false)}
      />

      <TVDrawer
        activeTab={activeTab}
        open={tvDrawerIsOpened}
        onClose={() => setTVDrawerIsOpened(false)}
      />

      <ElectricityDrawer
        activeTab={activeTab}
        open={electricDrawerIsOpened}
        onClose={() => setElectricDrawerIsOpened(false)}
      />
    </div>
  );
};

export default PayBills;
