import React from "react";
import styles from "./LandingLayout.module.scss";
import logo from "../../assets/img/digital-logo.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import CarouselComponent from "../Carousel";
import { Col, Row } from "antd";

interface ILandingLayout {}

const LandingLayout: React.FC<ILandingLayout> = ({ children }) => {
  return (
    <div className="container my-5">
      <Row>
        <Col xs={24} sm={24} md={9}>
          <div className="h-100">
            <Link to={ROUTES.LOGIN}>
              <img
                className={styles.logoWhiteBgc}
                src={logo}
                alt="Login Logo"
              />
            </Link>
            <div
              className="h-100"
              style={{
                marginTop: "6rem",
              }}
            >
              <div className="w-100">{children}</div>
            </div>
          </div>
        </Col>

        <Col xs={0} sm={0} md={3} />
        <Col xs={0} sm={0} md={12} className="d-sm-none d-md-block">
          <CarouselComponent />
        </Col>
      </Row>
    </div>
  );
};
export default LandingLayout;
