import React from "react";
import { Modal } from "antd";
import { IoSendOutline } from "react-icons/io5";
import { MdOutlineCreditScore } from "react-icons/md";
import { AiOutlinePieChart } from "react-icons/ai";
import { BiChart } from "react-icons/bi";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../interfaces/redux";
import { ESupportedBills } from "../../Constants/mock";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const DashboardQuickActions = ({ isOpen, onClose }: IProps) => {
  const { supportedBills } = useSelector(
    (state: ReduxStoreModel) => state.supportedBillLists
  );
  return (
    <Modal open={isOpen} footer={null} onCancel={onClose} className="actions">
      <div className="d-flex align-items-center">
        <div className="icon">
          <IoSendOutline
            style={{
              transform: "rotate(316deg)",
            }}
          />
        </div>
        <p className="p-none font-weight-bold dashboard-actions__text">
          Transfer to bank
        </p>
      </div>

      {supportedBills?.includes(ESupportedBills.TELCO) && (
        <div className="d-flex align-items-center">
          <div className="icon">
            <IoSendOutline
              style={{
                transform: "rotate(137deg)",
              }}
            />
          </div>
          <p className="p-none font-weight-bold dashboard-actions__text">
            Buy Airtime
          </p>
        </div>
      )}

      <div className="d-flex align-items-center">
        <div className="icon">
          <MdOutlineCreditScore />
        </div>
        <p className="p-none font-weight-bold dashboard-actions__text">
          Send payment request
        </p>
      </div>

      <div className="d-flex align-items-center">
        <div className="icon">
          <MdOutlineCreditScore />
        </div>
        <p className="p-none font-weight-bold dashboard-actions__text">
          Save Money
        </p>
      </div>

      <div className="d-flex align-items-center">
        <div className="icon">
          <AiOutlinePieChart />
        </div>
        <p className="p-none font-weight-bold dashboard-actions__text">
          Fund Account
        </p>
      </div>

      <div className="d-flex align-items-center">
        <div className="icon">
          <BiChart />
        </div>
        <p className="p-none font-weight-bold dashboard-actions__text">
          Transfer between accounts
        </p>
      </div>
    </Modal>
  );
};

export default DashboardQuickActions;
