import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  walletId: number;
  page: number;
  size: number;
  funderId: number;
  queryClient?: any;
};

export const getWalletTransactions = async ({ queryKey }: any) => {
  const { walletId, page, size, funderId } = queryKey[1];
  const data = await getData(
    `${apiEndpoints.WALLET_TRANSACTIONS}?savingsWalletId=${walletId}&page=${page}&pageSize=${size}&funderId=${funderId}`
  );
  return data;
};

export default function useWalletTransactions({
  walletId,
  page,
  size,
  funderId,
}: paramProps) {
  return useQuery(
    ["getWalletTransactions", { walletId, page, size, funderId }],
    getWalletTransactions,
    {
      refetchOnWindowFocus: true, // Refetch on window focus
      enabled: walletId && funderId ? true : false,
    }
  );
}

export const fetchNextWalletTransactions = ({
  walletId,
  page,
  size,
  funderId,
  queryClient,
}: paramProps) => {
  queryClient.prefetchQuery(
    [
      "getWalletTransactions",
      {
        walletId,
        page: page + 1,
        size,
        funderId,
      },
    ],
    () =>
      getWalletTransactions({
        queryKey: [
          "getWalletTransactions",
          {
            walletId,
            page: page + 1,
            size,
            funderId,
          },
        ],
      })
  );
};
