import { Fragment, useEffect, useRef } from "react";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import WalletAddMoneyModal from "./WalletModalStepOne/WalletAddMoneyModal";
import { useDispatch } from "react-redux";
import { resetWithdrawal } from "../../../redux/actions";

export default function WalletModal({ walletDetails }: any) {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dispatch = useDispatch();

  const closeModal = () => {
    dismissRef?.current?.click();
    dispatch(resetWithdrawal());
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletModal.component.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <Fragment>
      <div
        className="modal fade"
        id="walletModal"
        tabIndex={-1}
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content p-2">
            <div className="modal-body">
              <button
                type="button"
                className="d-none"
                data-dismiss="modal"
                aria-label="Close"
                ref={dismissRef}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {
                <WalletAddMoneyModal
                  closeModal={closeModal}
                  walletDetails={walletDetails}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
