import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import { ReactComponent as StarGroup } from "../../assets/img/star-group.svg";

export default function DashboardCompleteProfileModal() {
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const history = useHistory();
  const redirectToSettingsPage = () => {
    dismissRef?.current?.click();
    history.push(ROUTES.PROFILE_SETTINGS);
  };
  return (
    <div
      className="modal fade"
      id="dashboardCompleteProfileModal"
      tabIndex={-1}
      aria-labelledby="dashboardCompleteProfileModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content p-4">
          <div className="modal-header border-bottom-0 py-0">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column text-center justify-content-center align-items-center">
              <StarGroup />
              <h6 className="font-weight-bold mt-3">Complete your Profile</h6>
              <p>
                "You are yet to activate your account. Kindly finish your
                account setup to initiate a transfer."
              </p>
              <button
                className="btn advancly-btn btn-sm w-100 mt-3"
                onClick={redirectToSettingsPage}
              >
                Go to Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
