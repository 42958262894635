import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import CustomInputFieldFormItem from "../../CustomHTMLElements/CustomInputFieldFormItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import { postData } from "./../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "./../../../helpers/errorHandler";
import Loader from "../../Loader/Loader.component";
import Alert from "../../Alert/Alert.component";
import PageLoader from "./../../PageLoader/PageLoader.component";
import useSavedSecurityQuestion from "../../../hooks/custom-hooks/useSavedSecurityQuestion";
import { appInsights } from "../../AppInsight/AppInsight";

const schema = yup.object().shape({
  answer: yupValidators.genericRequired({
    message: "Please enter your answer",
    max: 64,
  }),
});

interface IResetSecurityQuestionProps {
  setSection: Dispatch<SetStateAction<any>>;
}

export const ResetSecurityQuestion: React.FC<IResetSecurityQuestionProps> = ({
  setSection,
}) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ answer }: { answer: string }) => {
    setLoading(true);
    setError("");

    const reqBody = {
      questionId: data?.id,
      answer,
    };

    try {
      await postData(apiEndpoints.CONFIRM_SECURITY_QUESTION, reqBody);
      setLoading(false);
      setSection("pin");
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: ResetSecurityQuestion },
      });
    }
  };

  const {
    data,
    isLoading,
    error: securityQuestionError,
    isError,
  } = useSavedSecurityQuestion();

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {securityQuestionError && (
        <Alert message={errorHandler(securityQuestionError)} />
      )}
      {isLoading ? (
        <div className="p-5">
          <PageLoader />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            Kindly confirm your security question and answer before you reset
            your PIN
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <CustomInputFieldFormItem
                type="text"
                maxLength={64}
                label="Security Question"
                defaultValue={data?.question}
                readOnly
              />
            </div>
            <div className="col-12">
              <CustomInputFieldFormItem
                type="text"
                maxLength={64}
                {...register("answer")}
                label="Security Question Answer"
                errors={errors.answer}
              />
            </div>
          </div>
          <button
            className="btn btn-sm advancly-btn mt-3 w-100"
            disabled={loading || isLoading || isError}
          >
            Continue {loading && <Loader />}
          </button>
        </form>
      )}
    </Fragment>
  );
};
