import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { LiaWindowClose } from "react-icons/lia";
import { Drawer } from "antd";
import { PiCaretLeftBold } from "react-icons/pi";
import ElectricityDrawerStepOne from "./ElectricityDrawerStepOne/ElectricityDrawerStepOne";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux";
import ElectricityProviderListDrawer from "./ElectricityDrawerStepTwo/ElectricityProviderListDrawer";
import ElectricityForm from "./ElectricityDrawerStepThree/ElectricityForm";
import ElectricityReviewPayment from "./ElectricityDrawerStepFour/ElectricityReviewPayment";
import ElectricityTransactionPin from "./ElectricityDrawerStepFive/ElectricityTransactionPin";
import ElectricityBeneficiaryListDrawer from "./ElectricityDrawerBeneficiaryList/ElectricityBeneficiaryList";

export interface IElectricityDataRequestBody {
  customerAccountId: number;
  billType: string;
  productName: string;
  amount: number | undefined;
  operatorId: string;
  productId: string;
  deviceNumber: string;
  packageTitle?: string;
  meterType: string;
  genericOperatorName: string;
}

export interface ISelectedBeneficiary {
  customerId: number;
  currency: string;
  billType: string;
  productName: string;
  operatorId: string;
  deviceNumber: string;
  meterType: string;
  genericOperatorName: string;
  beneficiaryName: string;
}

interface IProps {
  activeTab: string;
  open: boolean;
  onClose: () => void;
}
export enum ElectricitySteps {
  ElectricityFirstScreen = "electricity-first-screen",
  SelectAProvider = "select-a-provider",
  ElectricityForm = "electricity-form",
  ReviewPayment = "review-payment",
  TransactionPinScreen = "enter-transaction-pin",
}

const ElectricityDrawer = ({ activeTab, onClose, open }: IProps) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const [selectedBeneficiaryData, setSelectedBeneficiaryData] =
    useState<ISelectedBeneficiary | null>(null);
  const [requestBody, setRequestBody] =
    useState<IElectricityDataRequestBody | null>(null);
  const [currentStep, setCurrentStep] = useState<ElectricitySteps>(
    ElectricitySteps.ElectricityFirstScreen
  );
  const [operator_id, setOperatorId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const closeDrawer = () => {
    onResetForm();
    onClose();
  };

  const onResetForm = () => {
    setRequestBody(null);
    setSelectedBeneficiaryData(null);
    setOperatorId("");
    setDeviceName("");
    setCurrentStep(ElectricitySteps.ElectricityFirstScreen);
  };

  useEffect(() => {
    if (currentStep === ElectricitySteps.ElectricityFirstScreen) {
      onResetForm();
    }
  }, [currentStep]);

  const handleTransferDrawerTitle = () => {
    switch (currentStep) {
      case ElectricitySteps.ElectricityFirstScreen:
        return "Electricity";

      case ElectricitySteps.ElectricityForm:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() =>
                setCurrentStep(ElectricitySteps.ElectricityFirstScreen)
              }
            />
            <p className="p-none">Electricity Bill Payment</p>
          </div>
        );
      case ElectricitySteps.SelectAProvider:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() =>
                setCurrentStep(ElectricitySteps.ElectricityFirstScreen)
              }
            />
            <p className="p-none">Select A Provider</p>
          </div>
        );
      case ElectricitySteps.ReviewPayment:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(ElectricitySteps.ElectricityForm)}
            />
            <p className="p-none">Review Payment</p>
          </div>
        );

      case ElectricitySteps.TransactionPinScreen:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(ElectricitySteps.ReviewPayment)}
            />
            <p className="p-none">Enter your transaction PIN</p>
          </div>
        );
    }
  };

  return (
    <div>
      <Drawer
        title={handleTransferDrawerTitle()}
        open={open}
        onClose={() => {
          closeDrawer();
        }}
        closeIcon={<LiaWindowClose size="24px" />}
      >
        {(() => {
          switch (currentStep) {
            case ElectricitySteps.ElectricityFirstScreen:
              return (
                <ElectricityDrawerStepOne
                  setCurrentStep={setCurrentStep}
                  activeTab={activeTab}
                  setOperatorId={setOperatorId}
                  setSelectedBeneficiaryData={setSelectedBeneficiaryData}
                />
              );

            case ElectricitySteps.SelectAProvider:
              return (
                <ElectricityProviderListDrawer
                  setCurrentStep={setCurrentStep}
                  activeTab={activeTab}
                  setOperatorId={setOperatorId}
                />
              );
            case ElectricitySteps.ElectricityForm:
              return (
                <ElectricityForm
                  setCurrentStep={setCurrentStep}
                  setRequestBody={setRequestBody}
                  requestBody={requestBody}
                  selectedBeneficiaryData={selectedBeneficiaryData}
                  setSelectedBeneficiaryData={setSelectedBeneficiaryData}
                  operator_id={operator_id}
                  setOperatorId={setOperatorId}
                  deviceName={deviceName}
                  setDeviceName={setDeviceName}
                />
              );
            case ElectricitySteps.ReviewPayment:
              return (
                <ElectricityReviewPayment
                  setCurrentStep={setCurrentStep}
                  requestBody={requestBody}
                />
              );
            case ElectricitySteps.TransactionPinScreen:
              return (
                <ElectricityTransactionPin
                  requestBody={requestBody}
                  selectedBeneficiaryData={selectedBeneficiaryData}
                  setCurrentStep={setCurrentStep}
                  closeDrawer={closeDrawer}
                />
              );
            default:
              return null;
          }
        })()}
      </Drawer>
    </div>
  );
};

export default ElectricityDrawer;
