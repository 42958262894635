import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Divider, Spin } from "antd";
import { Alert } from "reactstrap";
import { errorHandler } from "../../../../helpers/errorHandler";
import { IBeneficiaries } from "../../BankTransferDrawers/NGNTransferDrawer/NGNTransferDrawerStepOne/NGNTransferDrawerStepOne";
import {
  AirtimeSteps,
  getLogo,
  ISelectedBeneficiary,
} from "../AirtimeDrawer.component";
import { ReactComponent as RightArrowNew } from "../../../../assets/img/RightArrowNew.svg";
import useRecentRecipients from "../../../../hooks/custom-hooks/useRecentRecipients";
import { FiSmartphone, FiUser } from "react-icons/fi";
import nineMobile from "../../../../assets/img/pay/9Mobile.jpg";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import { TbHourglassEmpty } from "react-icons/tb";
import { ENetworkProviders } from "../AirtimeDrawerStepTwo";
import mtn from "../../../../assets/img/pay/mtn.png";
import glo from "../../../../assets/img/pay/glo.png";
import airtel from "../../../../assets/img/pay/airtel.png";
import other from "../../../../assets/img/pay/otherNetworks.png";
import styles from "../../BankTransferDrawers/NGNTransferDrawer/WithdrawalModalStepTwo/NGNTransferDrawerBankList.module.scss";
import { appInsights } from "../../../AppInsight/AppInsight";
import useWalletBalance from "../../../../hooks/custom-hooks/useWalletBalance";
import useOperatorProducts from "../../../../hooks/custom-hooks/useBillPayments";
import { ESupportedBills, naira } from "../../../../Constants/mock";
import { IAirtimeDataProduct } from "../../../../interfaces/billPayment";
import moment from "moment";
import formatMoney from "../../../../helpers/formatMoney";

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  activeTab: string;
  setOperatorId: Dispatch<SetStateAction<string>>;
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
  operator_id: string;
}

const AirtimeDrawerStepOne = ({
  setCurrentStep,
  activeTab,
  setOperatorId,
  setSelectedBeneficiaryData,
  operator_id,
}: IProps) => {
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const { transactions } = useSelector(
    (state: ReduxStoreModel) => state.billTransactions
  );

  const { data, isFetching } = useOperatorProducts({
    operator_id: operator_id,
    bill_name: ESupportedBills.TELCO,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "AirtimeDrawerStepOne.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleSelectProvider = (
    operator_id: string,
    deviceNumber: string | null
  ) => {
    setOperatorId(operator_id);
    setCurrentStep(AirtimeSteps.AirtimeForm);
    setSelectedBeneficiaryData({
      billType: "",
      currency: "",
      customerId: 0,
      operatorId: "",
      productId: "",
      productName: "",
      deviceNumber: deviceNumber ? deviceNumber : "",
      beneficiaryName: "",
      genericOperatorName: "",
    });
  };

  function getProductName(productId: string): IAirtimeDataProduct {
    let product;
    let response = data?.find(
      (d: { product_id: string }) => d.product_id === productId
    );
    if (response) {
      product = response;
    }
    return product;
  }

  return (
    <div>
      <div className="actions">
        <div
          className="choose_a_bank_wrapper"
          onClick={() => setCurrentStep(AirtimeSteps.SelectAProvider)}
        >
          <div className="choose_sub_wrapper">
            <div className="icon">
              <FiSmartphone />
            </div>
            <div className="choose_text_wrapper">
              <p className="choose_heading">Choose a provider</p>
              <p className="choose_text1">Select a provider to recharge</p>
            </div>
          </div>
          <div className="rigtArrow">
            <RightArrowNew />
          </div>
        </div>
      </div>
      <Divider />
      {/* Recent transactions */}
      <h6>Recent Transactions</h6>
      <br />
      <div>
        <div className="recentBankTransactionsName">
          {transactions && transactions.length === 0 ? (
            <div className="pageLoader pt-5">
              <h6>No recent transactions</h6>
            </div>
          ) : (
            transactions
              ?.slice(0, 3)
              ?.map(
                (
                  {
                    deviceNumber,
                    productName,
                    operatorId,
                    beneficiaryPhoneNumber,
                    productId,
                    transactionDate,
                    amount,
                    genericOperatorName,
                  },
                  index
                ) => {
                  return (
                    <div
                      className="recentTransactionGroup align-items-center"
                      key={index}
                      // TODO: add d click event back when filters are corrected
                      /*  onClick={() => {
                        handleSelectProvider(
                          operatorId,
                          beneficiaryPhoneNumber
                        );
                      }}*/
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={getLogo(genericOperatorName)}
                          alt="list of network provider icon"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "100%",
                            marginRight: "5px",
                          }}
                        />
                        <div>
                          <p className="p-none">
                            {getProductName(productId)?.name}
                          </p>
                          <p className="p-none">{formatMoney(amount, naira)}</p>
                          <p className="text-muted p-none">
                            {moment(transactionDate).format("ll")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default AirtimeDrawerStepOne;
