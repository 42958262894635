import { Fragment, useEffect } from "react";
import { EmailVerification } from "./EmailVerification.component";
import { OtpVerification } from "./OtpVerification.component";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux";

const StepOne = () => {
  const { emailVerified } = useSelector(
    (state: ReduxStoreModel) => state.signUp
  );
  useEffect(() => {
    appInsights.trackPageView({
      name: "StepOne.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      {!emailVerified ? <EmailVerification /> : <OtpVerification />}
    </Fragment>
  );
};

export default StepOne;
