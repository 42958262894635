import { useState, useRef, Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import { postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CustomInputFieldFormItem from "../CustomHTMLElements/CustomInputFieldFormItem";
import Loader from "../Loader/Loader.component";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import { useQueryClient } from "react-query";
import { appInsights } from "../AppInsight/AppInsight";
import { setCurrentUser } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../interfaces/redux";

const schema = yup.object().shape({
  bank_account_number: yupValidators.genericRequiredNumber({
    message: "Please enter account number",
    max: 20,
  }),
  bank_account_name: yupValidators.genericRequired({
    message: "Please enter account number",
    max: 128,
  }),
  bank_name: yupValidators.genericRequired({
    message: "Please enter account number",
    max: 128,
  }),
  routing_number: yupValidators.generic({}),
  swift_code: yupValidators.generic({}),
});

interface IManuallySpecifyBankInfoModal {
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
}

const ManuallySpecifyBankInfoModal: React.FC<IManuallySpecifyBankInfoModal> = ({
  setSuccess,
  setError,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStoreModel) => state?.user);
  const [loading, setLoading] = useState(false);
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const dismissModal = () => {
    dismissRef?.current?.click();
  };

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    bank_account_number,
    bank_account_name,
    bank_name,
    routing_number,
    swift_code,
  }: {
    bank_account_number: string;
    bank_account_name: "string";
    bank_name: "string";
    routing_number: "string";
    swift_code: "string";
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      bank_account_number,
      bank_account_name,
      bank_name,
      routing_number,
      swift_code,
    };

    try {
      await postData(apiEndpoints.UPDATE_USER_BANK_INFO, reqBody);
      queryClient.invalidateQueries("getFunder");
      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        dispatch(setCurrentUser({ ...user, activated }));
      }

      reset();
      setSuccess("Bank information updated successfully.");
      setLoading(false);
      dismissModal();
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      dismissModal();
      appInsights.trackException({
        exception: error,
        properties: { fileName: ManuallySpecifyBankInfoModal },
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="manuallySpecifyBankInfoModal"
      tabIndex={-1}
      aria-labelledby="manuallySpecifyBankInfoModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-4">
          <div className="modal-header p-0 pb-2">
            <h5 className="page-subtitle">New Bank Information</h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body px-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomInputFieldFormItem
                type="number"
                maxLength={20}
                placeholder="Enter Account number"
                label="Account Number"
                errors={errors.bank_account_number}
                {...register("bank_account_number")}
                showRequiredIcon
              />
              <CustomInputFieldFormItem
                type="text"
                maxLength={128}
                placeholder="Account Name"
                label="Account Name"
                errors={errors.bank_account_name}
                {...register("bank_account_name")}
                showRequiredIcon
              />
              <CustomInputFieldFormItem
                type="text"
                maxLength={128}
                placeholder="Bank Name"
                label="Bank Name"
                errors={errors.bank_name}
                {...register("bank_name")}
                showRequiredIcon
              />
              <CustomInputFieldFormItem
                type="number"
                maxLength={9}
                placeholder="Routing Number"
                label="Routing Number"
                errors={errors.routing_number}
                {...register("routing_number")}
              />
              <CustomInputFieldFormItem
                type="text"
                maxLength={11}
                placeholder="Swift Code"
                label="Swift Code"
                errors={errors.swift_code}
                {...register("swift_code")}
              />
              <button
                className="btn btn-sm advancly-btn mt-3 w-100"
                disabled={loading}
              >
                Update Bank Information {loading && <Loader />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManuallySpecifyBankInfoModal;
