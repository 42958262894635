import React from "react";
import loginImageOne from "../../assets/img/login/loginImage1.png";
import loginTextOne from "../../assets/img/login/loginText1.svg";
import loginImageTwo from "../../assets/img/login/loginImage2.png";
import loginTextTwo from "../../assets/img/login/loginText2.svg";
import loginImageThree from "../../assets/img/login/loginImage3.png";
import loginTextThree from "../../assets/img/login/loginText3.svg";
import { Carousel } from "antd";

const CarouselComponent = () => {
  return (
    <Carousel
      autoplay
      dots={false}
      style={{
        backgroundColor: "#eafcec",
        borderRadius: "40px",
        minHeight: "90vh",
      }}
      className="h-100"
    >
      <div>
        <div className="d-flex justify-content-center">
          <img
            className="mw-100 carousel-img"
            src={loginImageOne}
            alt="login"
            style={{
              width: "500px",
              height: "500px",
              marginBottom: "0px",
            }}
          />
        </div>
        <div className="d-flex justify-content-center">
          <img className="mw-100 w-75" src={loginTextOne} alt="login" />
        </div>
      </div>

      <div>
        <div className="d-flex justify-content-center">
          <img
            className="mw-100 carousel-img"
            src={loginImageTwo}
            alt="login"
          />
        </div>
        <div className="d-flex justify-content-center">
          <img className="mw-100 w-75" src={loginTextTwo} alt="login" />
        </div>
      </div>

      <div>
        <div className="d-flex justify-content-center">
          <img
            className="mw-100 carousel-img"
            src={loginImageThree}
            alt="login"
          />
        </div>
        <div className="d-flex justify-content-center">
          <img className="mw-100 w-75" src={loginTextThree} alt="login" />
        </div>
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
