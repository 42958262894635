import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  AirtimeSteps,
  IAirtimeDataRequestBody,
  ISelectedBeneficiary,
} from "../AirtimeDrawer.component";
import { Button, Card, Form, message } from "antd";
import { CustomPinInputField } from "../../../CustomHTMLElements/CustomPinInputField.component";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../helpers/errorHandler";
import { appInsights } from "../../../AppInsight/AppInsight";
import { IApiResponse } from "../../../../interfaces/billPayment";
import useWalletBalance from "../../../../hooks/custom-hooks/useWalletBalance";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import { getNairaWalletDetails } from "../../../../redux/actions";

interface IProps {
  requestBody: IAirtimeDataRequestBody | null;
  selectedBeneficiaryData: ISelectedBeneficiary | null;
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  closeDrawer: () => void;
}

const AirtimeTransactionPin = ({
  requestBody,
  setCurrentStep,
  selectedBeneficiaryData,
  closeDrawer,
}: IProps) => {
  const dispatch = useDispatch();
  const { lcy_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const customerAccountId = useSelector(
    (state: ReduxStoreModel) => state?.user?.customerAccountId
  );

  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState<string | null>(null);
  const [isValidPin, setIsValidPin] = useState(false);
  useEffect(() => {
    if (pin?.length === 4) {
      setIsValidPin(true);
    } else {
      setIsValidPin(false);
    }
  }, [pin]);

  const onSubmit = async () => {
    setIsLoading(true);
    const reqBody = {
      ...requestBody,
      customerAccountId,
      pin: pin?.toString(),
    };
    try {
      if (selectedBeneficiaryData?.customerId) {
        await postData(
          apiEndpoints.BILL_PAYMENT_BENEFICIARY,
          selectedBeneficiaryData
        );
      }
      const response: IApiResponse = await postData(
        apiEndpoints.POST_BILL_PAYMENT,
        reqBody
      );

      message.success(
        response.message || "Bill Payment Successful",
        undefined,
        () => {
          setPin(null);
          closeDrawer();
        }
      );
      dispatch(getNairaWalletDetails(funderId, lcy_wallet_id));
      closeDrawer();
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "AirtimeTransactionPin.tsx" },
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="w-100">
      <Form onFinish={onSubmit}>
        <CustomPinInputField
          setValue={(value: string) => setPin(value)}
          length={4}
          label="Enter transaction PIN to proceed"
          focus={true}
        />

        <Button
          htmlType="submit"
          type="primary"
          size="large"
          block
          disabled={!isValidPin || !pin}
          loading={isLoading}
          className="mt-5"
        >
          Continue
        </Button>
      </Form>
    </div>
  );
};

export default AirtimeTransactionPin;
