import { useQuery } from "react-query";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getData } from "../../apis/apiMethods";

type paramProps = {
  investmentId: number;
};

const getAnInvestment = async ({ queryKey }: any) => {
  const { investmentId } = queryKey[1];
  const { data } = await getData(
    `${apiEndpoints.AVAILABLE_INVESTMENTS}/${investmentId}`
  );
  return data;
};

export default function useInvestment({ investmentId }: paramProps) {
  return useQuery(["getAnInvestment", { investmentId }], getAnInvestment, {
    refetchOnWindowFocus: false, // Refetch on window focus
  });
}
