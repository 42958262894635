import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { appInsights } from "../../../AppInsight/AppInsight";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import useWalletBalance from "../../../../hooks/custom-hooks/useWalletBalance";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import formatMoney from "../../../../helpers/formatMoney";
import { IBillProvider, IProduct } from "../../../../interfaces/billPayment";
import { getData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { ESupportedBills, naira } from "../../../../Constants/mock";
import { errorHandler } from "../../../../helpers/errorHandler";
import {
  ElectricitySteps,
  IElectricityDataRequestBody,
  ISelectedBeneficiary,
} from "../ElectricityDrawer.component";
import { validatePhoneNumber } from "../../../../helpers";
import useBankAccountNameEnquiry from "../../../../hooks/custom-hooks/useBankAccountNameEnquiry";
import { useValidateMeterNumber } from "../../../../hooks/custom-hooks/useBillPayments";
import { FaUserLarge } from "react-icons/fa6";
import { getBillPaymentBeneficiary } from "../../../../redux/actions";
import ElectricityBeneficiaryList from "../ElectricityDrawerBeneficiaryList/ElectricityBeneficiaryList";

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  setRequestBody: Dispatch<SetStateAction<IElectricityDataRequestBody | null>>;
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
  operator_id: string;
  requestBody: IElectricityDataRequestBody | null;
  selectedBeneficiaryData: ISelectedBeneficiary | null;
  setOperatorId: Dispatch<SetStateAction<string>>;
  setDeviceName: (deviceName: string) => void;
  deviceName: string;
}
const ElectricityForm = ({
  operator_id,
  setCurrentStep,
  setRequestBody,
  setSelectedBeneficiaryData,
  requestBody,
  selectedBeneficiaryData,
  deviceName,
  setDeviceName,
}: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { lcy_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const { supportedElectricityProviders } = useSelector(
    (state: ReduxStoreModel) => state.electricityProviders
  );
  const { wallet } = useSelector((state: ReduxStoreModel) => state.wallet);
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const customerAccountId = useSelector(
    (state: ReduxStoreModel) => state?.user?.customerAccountId
  );

  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<
    IBillProvider | undefined
  >();
  const [meterNumber, setMeterNumber] = useState("");
  const [meterType, setMeterType] = useState("");
  const [showBeneficiaryList, setShowBeneficiaryList] = useState(false);
  const [showBeneficiaryInput, setShowBeneficiaryInput] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({
      name: "ElectricityForm.tsx",
      isLoggedIn: true,
    });
  }, []);

  const fetchProducts = async () => {
    try {
      setIsFetchingProducts(true);
      const { data } = await getData(
        `${apiEndpoints.GET_PROVIDER_LIST_AND_PRODUCTS}/${operator_id}/products?bill_name=${ESupportedBills.ELECTRICITY}`
      );
      console.log({ data });
      const provider = supportedElectricityProviders?.find(
        (s) => s.operator_id === operator_id
      );
      console.log({
        provider,
      });
      if (provider) {
        setSelectedProvider({
          ...provider,
          product_id: data[0]?.product_id || "",
        });
      }
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "ElectricityForm.tsx" },
      });
    } finally {
      setIsFetchingProducts(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSubmit = async (values: {
    deviceNumber: string;
    operatorId: string;
    meterType: string;
    save_beneficiary: boolean | undefined;
    amount: number;
    beneficiaryName: string;
  }) => {
    const {
      deviceNumber,
      save_beneficiary,
      operatorId: productName,
      meterType,
      amount,
      beneficiaryName,
    } = values;
    console.log({
      selectedProvider,
    });
    if (selectedProvider) {
      const { name, operator_id } = selectedProvider;
      const reqBody = {
        customerAccountId,
        billType: ESupportedBills.ELECTRICITY,
        productName,
        amount: Number(amount),
        deviceNumber,
        operatorId: operator_id,
        packageTitle: name,
        meterType,
        productId: selectedProvider.product_id || "",
        genericOperatorName: selectedProvider.generic_operator_name,
      };
      if (save_beneficiary) {
        setSelectedBeneficiaryData({
          productName: name,
          billType: ESupportedBills.ELECTRICITY,
          currency: "NGN",
          customerId: funderId,
          deviceNumber,
          meterType,
          operatorId: operator_id,
          beneficiaryName,
          genericOperatorName: selectedProvider.generic_operator_name,
        });
      }
      setRequestBody(reqBody);
      setCurrentStep(ElectricitySteps.ReviewPayment);
    } else {
      message.error(
        "Something went wrong, please try again or reload the page"
      );
    }
  };

  const { data, isLoading, error } = useValidateMeterNumber({
    device_number: meterNumber,
    operator_id: selectedProvider?.operator_id || "",
    meter_type: meterType,
  });

  useEffect(() => {
    if (error) {
      console.log("error");
      message.error("Error validating device number");
    }
  }, [error]);

  const fetchBeneficiary = () => {
    dispatch(
      getBillPaymentBeneficiary(
        funderId,
        "NGN",
        selectedProvider?.generic_operator_name || ""
      )
    );
  };

  useEffect(() => {
    if (selectedBeneficiaryData?.deviceNumber) {
      form.setFieldValue("deviceNumber", selectedBeneficiaryData.deviceNumber);
    }
    if (requestBody?.deviceNumber) {
      form.setFieldValue("deviceNumber", requestBody.deviceNumber);
    }
  }, [selectedBeneficiaryData, requestBody]);

  useEffect(() => {
    if (data?.name) {
      setDeviceName(data?.name);
    }
    if (selectedBeneficiaryData?.beneficiaryName) {
      setDeviceName(selectedBeneficiaryData.beneficiaryName);
    }
    console.log({
      selectedBeneficiaryData,
    });
  }, [data, isLoading, selectedBeneficiaryData]);

  const ProductsLoader = () => {
    return (
      <div className="mt-5">
        <div className="w-100 d-flex justify-content-center">
          <Spin size="large" />
        </div>
        <p className="text-center">Fetching Products</p>
      </div>
    );
  };

  return (
    <div className="w-100">
      <>
        {isFetchingProducts ? (
          <ProductsLoader />
        ) : (
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form "
            requiredMark
            onFinish={handleSubmit}
            onFinishFailed={() => null}
            onFieldsChange={(values) => {
              // @ts-ignore
              if (values[0]?.name[0] === "save_beneficiary") {
                setShowBeneficiaryInput(values[0]?.value);
              }
            }}
            size="large"
            form={form}
          >
            <CustomInputFieldFormItem
              fieldName="source_account"
              label="Source Account"
              initialValue={`${wallet?.accountNumber} - ${formatMoney(
                wallet?.availableBalance || 0,
                "NGN"
              )} (${wallet?.accountName})`}
              readOnly
              size="large"
            />

            <CustomInputFieldFormItem
              fieldName="provider"
              label="Provider"
              readOnly
              size="large"
              initialValue={selectedProvider?.name}
            />

            <CustomInputFieldFormItem
              fieldName="meterType"
              placeholder="Select Meter Type"
              size="large"
              label="Meter Type"
              rules={[
                {
                  required: true,
                  message: "Please select a package",
                },
              ]}
              initialValue={
                selectedBeneficiaryData?.meterType || requestBody?.meterType
              }
              InputComponent={
                <Select onChange={(val) => setMeterType(val)}>
                  <Select.Option value="prepaid">Prepaid</Select.Option>
                  <Select.Option value="postpaid">Postpaid</Select.Option>
                </Select>
              }
            />
            <Tooltip title="Select a beneficiary from your beneficiary list">
              <Typography.Link
                href="#"
                onClick={() => {
                  setShowBeneficiaryList(true);
                  fetchBeneficiary();
                }}
              >
                Find Beneficiary?
              </Typography.Link>
            </Tooltip>
            <Form.Item label="Meter Number">
              <Form.Item
                initialValue={
                  selectedBeneficiaryData?.deviceNumber ||
                  requestBody?.deviceNumber
                }
                name="deviceNumber"
                rules={[
                  { required: true, message: "meter number is required" },
                ]}
                hasFeedback={!data?.name}
                validateStatus={
                  error
                    ? "error"
                    : isLoading
                    ? "validating"
                    : (data?.name || selectedBeneficiaryData?.deviceNumber) &&
                      "success"
                }
              >
                <Input
                  onBlur={() => {
                    const num = form.getFieldValue("deviceNumber");
                    setMeterNumber(num);
                  }}
                  size="large"
                />
              </Form.Item>
            </Form.Item>

            {deviceName && (
              <p
                className="text-primary font-weight-bold"
                style={{ marginTop: "-30px" }}
              >
                {" "}
                <FaUserLarge className="mr-1" />
                {deviceName}
              </p>
            )}

            <CustomInputFieldFormItem
              fieldName="amount"
              placeholder="Enter Amount"
              type="number"
              size="large"
              label="Enter Amount"
              rules={[{ required: true, message: "amount is required" }]}
              initialValue={requestBody?.amount}
              InputComponent={<Input type="number" />}
            />
            <Input.Group compact>
              <Form.Item
                label="Save Beneficiary"
                name="save_beneficiary"
                valuePropName="checked"
                className="mr-4"
              >
                <Switch />
              </Form.Item>

              {showBeneficiaryInput ? (
                <CustomInputFieldFormItem
                  fieldName="beneficiaryName"
                  label="Beneficiary Name"
                  size="large"
                  style={{
                    width: "21.4rem",
                  }}
                  rules={[
                    { required: true, message: "Beneficiary name is required" },
                  ]}
                />
              ) : null}
            </Input.Group>

            <Button
              disabled={!deviceName}
              htmlType="submit"
              type="primary"
              block
              className="mt-5"
            >
              Continue
            </Button>
          </Form>
        )}
        <ElectricityBeneficiaryList
          setSelectedBeneficiaryData={setSelectedBeneficiaryData}
          setShowBeneficiaryList={setShowBeneficiaryList}
          showBeneficiaryList={showBeneficiaryList}
        />
      </>
    </div>
  );
};

export default ElectricityForm;
