import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
  Switch,
  Tabs,
  TabsProps,
  Tooltip,
  Typography,
} from "antd";
import { appInsights } from "../../../AppInsight/AppInsight";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import formatMoney from "../../../../helpers/formatMoney";
import { IBillProvider, IProduct } from "../../../../interfaces/billPayment";
import { getData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { ESupportedBills, naira } from "../../../../Constants/mock";
import { errorHandler } from "../../../../helpers/errorHandler";
import {
  AirtimeSteps,
  IAirtimeDataRequestBody,
  ISelectedBeneficiary,
} from "../AirtimeDrawer.component";
import { validatePhoneNumber } from "../../../../helpers";
import AirtimeBeneficiaryListDrawer from "../AirtimeDrawerBeneficiaryList";
import { getBillPaymentBeneficiary } from "../../../../redux/actions";

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  setRequestBody: Dispatch<SetStateAction<IAirtimeDataRequestBody | null>>;
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
  setAirtimeActiveTab: Dispatch<SetStateAction<"airtime" | "data" | string>>;
  airtimeActiveTab: "airtime" | "data" | string;
  operator_id: string;
  requestBody: IAirtimeDataRequestBody | null;
  selectedBeneficiaryData: ISelectedBeneficiary | null;
  setOperatorId: Dispatch<SetStateAction<string>>;
}
const AirtimeForm = ({
  operator_id,
  setCurrentStep,
  setRequestBody,
  setSelectedBeneficiaryData,
  setAirtimeActiveTab,
  airtimeActiveTab,
  requestBody,
  selectedBeneficiaryData,
  setOperatorId,
}: IProps) => {
  const dispatch = useDispatch();
  const [airtimeForm] = Form.useForm();
  const [dataForm] = Form.useForm();
  const { lcy_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const { supportedAirtimeProviders } = useSelector(
    (state: ReduxStoreModel) => state.airtimeProviders
  );
  const { wallet } = useSelector((state: ReduxStoreModel) => state.wallet);
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );

  const [selectedAirtimeProduct, setSelectedAirtimeProduct] = useState<
    IProduct | undefined
  >();
  const [selectedDataPlanProduct, setSelectedDataPlanProduct] = useState<
    IProduct | undefined
  >();
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [minimumAirtimeAmount, setMinimumAirtimeAmount] = useState<number>(0);
  const [maximumAirtimeAmount, setMaximumAirtimeAmount] = useState<number>(0);
  const [dataPlans, setDataPlans] = useState<IProduct[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<
    IBillProvider | undefined
  >();
  const [showBeneficiaryList, setShowBeneficiaryList] = useState(false);
  const [showBeneficiaryInput, setShowBeneficiaryInput] = useState(false);

  useEffect(() => {
    setAirtimeActiveTab("airtime");
  }, []);

  const fetchProducts = async () => {
    try {
      setIsFetchingProducts(true);
      const { data } = await getData(
        `${apiEndpoints.GET_PROVIDER_LIST_AND_PRODUCTS}/${operator_id}/products?bill_name=${ESupportedBills.TELCO}`
      );
      switch (airtimeActiveTab) {
        case "airtime":
          const product = data.find(
            (f: { fee_type: string }) => f.fee_type === "RANGE"
          );
          if (product) {
            setSelectedAirtimeProduct(product);
            setMinimumAirtimeAmount(product.minimum_fee_amount);
            setMaximumAirtimeAmount(product.maximum_fee_amount);
          }
          break;
        case "data":
          const dataArr = data.filter(
            (f: { fee_type: string }) => f.fee_type === "FIXED"
          );
          if (dataArr) {
            setDataPlans(dataArr);
          }
          const id = requestBody?.productId;
          const dataProduct = dataArr.find(
            (d: { product_id: string | undefined }) => d.product_id === id
          );
          if (dataProduct) {
            setSelectedDataPlanProduct(dataProduct);
          }
          break;
      }
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "AirtimeForm" },
      });
    } finally {
      setIsFetchingProducts(false);
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "AirtimeForm.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [airtimeActiveTab]);

  const formatBeneficiaryPhoneNumber = (
    beneficiaryPhoneNumber: number | string
  ): string => {
    let num: number | string;
    if (beneficiaryPhoneNumber.toString().startsWith("0")) {
      num = beneficiaryPhoneNumber.toString();
    } else {
      num = `0${beneficiaryPhoneNumber}`;
    }
    return num;
  };

  const handleGenerateDataBeneficiaryAndRequestBody = (
    beneficiaryPhoneNumber: number,
    save_beneficiary: boolean | undefined,
    beneficiaryName: string
  ) => {
    if (selectedProvider && selectedDataPlanProduct) {
      if (save_beneficiary) {
        setSelectedBeneficiaryData({
          productName: selectedProvider.name,
          billType: ESupportedBills.TELCO,
          currency: "NGN",
          customerId: funderId,
          productId: selectedDataPlanProduct.product_id,
          deviceNumber: formatBeneficiaryPhoneNumber(beneficiaryPhoneNumber),
          genericOperatorName: selectedProvider.generic_operator_name,
          operatorId: selectedProvider.operator_id,
          beneficiaryName,
        });
      }
      setRequestBody({
        customerAccountId: funderId,
        billType: ESupportedBills.TELCO,
        productName: selectedProvider.name,
        productId: selectedDataPlanProduct.product_id,
        amount: selectedDataPlanProduct.fee_amount,
        operatorId: selectedProvider.operator_id,
        genericOperatorName: selectedProvider.generic_operator_name,
        beneficiaryPhoneNumber: formatBeneficiaryPhoneNumber(
          beneficiaryPhoneNumber
        ),
        dataPlanTitle: selectedDataPlanProduct.name,
      });
      setCurrentStep(AirtimeSteps.ReviewPayment);
    } else {
      message.error(
        "Something went wrong, please try again or reload the page"
      );
    }
  };

  const handleGenerateAirtimeBeneficiaryAndRequestBody = (
    beneficiaryPhoneNumber: number,
    save_beneficiary: boolean | undefined,
    amount: string,
    beneficiaryName: string
  ) => {
    if (selectedProvider && selectedAirtimeProduct) {
      if (save_beneficiary) {
        setSelectedBeneficiaryData({
          productName: selectedProvider.name,
          billType: ESupportedBills.TELCO,
          currency: "NGN",
          customerId: funderId,
          productId: selectedAirtimeProduct.product_id,
          deviceNumber: formatBeneficiaryPhoneNumber(beneficiaryPhoneNumber),
          genericOperatorName: selectedProvider.generic_operator_name,
          operatorId: selectedProvider.operator_id,
          beneficiaryName,
        });
      }
      setRequestBody({
        customerAccountId: funderId,
        billType: ESupportedBills.TELCO,
        productName: selectedProvider.name,
        productId: selectedAirtimeProduct.product_id,
        amount: Number(amount),
        operatorId: selectedProvider.operator_id,
        genericOperatorName: selectedProvider.generic_operator_name,
        beneficiaryPhoneNumber: formatBeneficiaryPhoneNumber(
          beneficiaryPhoneNumber
        ),
      });
      setCurrentStep(AirtimeSteps.ReviewPayment);
    } else {
      message.error(
        "Something went wrong, please try again or reload the page"
      );
    }
  };

  const handleSubmit = async (values: {
    amount: string;
    beneficiaryPhoneNumber: number;
    save_beneficiary: boolean | undefined;
    beneficiaryName: string;
  }) => {
    const {
      amount,
      beneficiaryPhoneNumber,
      save_beneficiary,
      beneficiaryName,
    } = values;
    if (airtimeActiveTab === "data") {
      handleGenerateDataBeneficiaryAndRequestBody(
        beneficiaryPhoneNumber,
        save_beneficiary,
        beneficiaryName
      );
    } else if (airtimeActiveTab === "airtime") {
      handleGenerateAirtimeBeneficiaryAndRequestBody(
        beneficiaryPhoneNumber,
        save_beneficiary,
        amount,
        beneficiaryName
      );
    }
  };

  const getProviderNameById = (id: string): string => {
    let providerName = "";
    const provider = supportedAirtimeProviders?.find(
      (s) => s.operator_id === id
    );
    if (provider) {
      setSelectedProvider(provider);
      providerName = provider.name;
    }
    return providerName;
  };

  const validateAmount = (rule: any, value: string) => {
    const amount = parseFloat(value);
    if (!value) {
      return Promise.reject("Amount is required");
    } else if (isNaN(amount) || amount < minimumAirtimeAmount) {
      return Promise.reject(`Minimum amount is ${minimumAirtimeAmount}`);
    } else if (amount > maximumAirtimeAmount) {
      return Promise.reject(`Maximum amount is ${maximumAirtimeAmount}`);
    } else {
      return Promise.resolve();
    }
  };

  const handleSelectDataPlan = (productId: string) => {
    const product = dataPlans.find((d) => d.product_id === productId);
    if (product) {
      setSelectedDataPlanProduct(product);
    }
  };

  const ProductsLoader = () => {
    return (
      <div className="mt-5">
        <div className="w-100 d-flex justify-content-center">
          <Spin size="large" />
        </div>
        <p className="text-center">Fetching Products</p>
      </div>
    );
  };

  const fetchBeneficiary = () => {
    dispatch(
      getBillPaymentBeneficiary(
        funderId,
        "NGN",
        selectedProvider?.generic_operator_name || ""
      )
    );
  };

  useEffect(() => {
    if (airtimeActiveTab === "airtime") {
      if (selectedBeneficiaryData?.deviceNumber) {
        airtimeForm.setFieldValue(
          "beneficiaryPhoneNumber",
          selectedBeneficiaryData.deviceNumber
        );
      }
      if (requestBody?.beneficiaryPhoneNumber) {
        airtimeForm.setFieldValue(
          "beneficiaryPhoneNumber",
          requestBody.beneficiaryPhoneNumber
        );
      }
    } else if (airtimeActiveTab === "data") {
      if (selectedBeneficiaryData?.deviceNumber) {
        dataForm.setFieldValue(
          "beneficiaryPhoneNumber",
          selectedBeneficiaryData.deviceNumber
        );
      }
      if (requestBody?.beneficiaryPhoneNumber) {
        dataForm.setFieldValue(
          "beneficiaryPhoneNumber",
          requestBody.beneficiaryPhoneNumber
        );
      }
    }
  }, [selectedBeneficiaryData, requestBody, airtimeActiveTab]);

  const Airtime = () => {
    return (
      <>
        {isFetchingProducts ? (
          <ProductsLoader />
        ) : (
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form "
            requiredMark
            onFinish={handleSubmit}
            onFinishFailed={() => null}
            onFieldsChange={(values) => {
              // @ts-ignore
              if (values[0]?.name[0] === "save_beneficiary") {
                setShowBeneficiaryInput(values[0]?.value);
              }
            }}
            size="large"
            form={airtimeForm}
          >
            <CustomInputFieldFormItem
              fieldName="source_account"
              label="Source Account"
              initialValue={`${wallet?.accountNumber} - ${formatMoney(
                wallet?.availableBalance || 0,
                "NGN"
              )} (${wallet?.accountName})`}
              readOnly
              size="large"
            />

            <CustomInputFieldFormItem
              fieldName=""
              label="Provider"
              initialValue={getProviderNameById(operator_id)}
              readOnly
              size="large"
            />
            <Tooltip title="Select a beneficiary from your beneficiary list">
              <Typography.Link
                href="#"
                onClick={() => {
                  setShowBeneficiaryList(true);
                  fetchBeneficiary();
                }}
              >
                Find Beneficiary?
              </Typography.Link>
            </Tooltip>
            <Form.Item label="Phone Number">
              <Input.Group compact>
                <Form.Item noStyle>
                  <Input style={{ width: "20%" }} placeholder="+234" readOnly />
                </Form.Item>
                <Form.Item
                  name="beneficiaryPhoneNumber"
                  noStyle
                  rules={[
                    { required: true, message: "Phone number is required" },
                    { validator: validatePhoneNumber },
                  ]}
                >
                  <InputNumber style={{ width: "80%" }} size="large" />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <CustomInputFieldFormItem
              fieldName="amount"
              placeholder="Enter Amount"
              type="number"
              size="large"
              label="Enter Amount"
              rules={[{ validator: validateAmount }]}
              initialValue={requestBody?.amount}
              InputComponent={<Input type="number" />}
            />
            <Input.Group compact>
              <Form.Item
                label="Save Beneficiary"
                name="save_beneficiary"
                valuePropName="checked"
                className="mr-4"
              >
                <Switch />
              </Form.Item>

              {showBeneficiaryInput ? (
                <CustomInputFieldFormItem
                  fieldName="beneficiaryName"
                  label="Beneficiary Name"
                  size="large"
                  style={{
                    width: "21.4rem",
                  }}
                  rules={[
                    { required: true, message: "Beneficiary name is required" },
                  ]}
                />
              ) : null}
            </Input.Group>

            <Button htmlType="submit" type="primary" block className="mt-5">
              Continue
            </Button>
          </Form>
        )}
      </>
    );
  };

  const Data = () => {
    return (
      <>
        {isFetchingProducts ? (
          <ProductsLoader />
        ) : (
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form "
            requiredMark
            onFinish={handleSubmit}
            onFinishFailed={() => null}
            onFieldsChange={(values) => {
              // @ts-ignore
              if (values[0]?.name[0] === "save_beneficiary") {
                setShowBeneficiaryInput(values[0]?.value);
              }
            }}
            size="large"
            form={dataForm}
          >
            <CustomInputFieldFormItem
              fieldName="source_account"
              label="Source Account"
              initialValue={`${wallet?.accountNumber} - ${formatMoney(
                wallet?.availableBalance || 0,
                "NGN"
              )} (${wallet?.accountName})`}
              readOnly
              size="large"
            />

            <CustomInputFieldFormItem
              fieldName="operatorId"
              label="Provider"
              initialValue={getProviderNameById(operator_id)}
              readOnly
              size="large"
            />
            <Tooltip title="Select a beneficiary from your beneficiary list">
              <Typography.Link
                href="#"
                onClick={() => {
                  setShowBeneficiaryList(true);
                  fetchBeneficiary();
                }}
              >
                Find Beneficiary?
              </Typography.Link>
            </Tooltip>
            <Form.Item label="Phone Number">
              <Input.Group compact>
                <Form.Item noStyle>
                  <Input style={{ width: "20%" }} placeholder="+234" readOnly />
                </Form.Item>
                <Form.Item
                  initialValue={Number(requestBody?.beneficiaryPhoneNumber)}
                  name="beneficiaryPhoneNumber"
                  noStyle
                  rules={[
                    { required: true, message: "Phone number is required" },
                    { validator: validatePhoneNumber },
                  ]}
                >
                  <InputNumber style={{ width: "80%" }} size="large" />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <CustomInputFieldFormItem
              fieldName="amount"
              placeholder="Enter Amount"
              type="number"
              size="large"
              label="Data Plan"
              rules={[
                {
                  required: true,
                  message: "Please select a package",
                },
              ]}
              initialValue={requestBody?.productId}
              InputComponent={
                <Select onChange={handleSelectDataPlan}>
                  <Select.Option value="">Select Data Plan</Select.Option>
                  {dataPlans?.map(({ name, fee_amount, product_id }) => (
                    <Select.Option value={product_id} key={product_id}>
                      {`${name} - ${formatMoney(fee_amount, naira)}`}
                    </Select.Option>
                  ))}
                </Select>
              }
            />

            <Input.Group compact>
              <Form.Item
                label="Save Beneficiary"
                name="save_beneficiary"
                valuePropName="checked"
                className="mr-4"
              >
                <Switch />
              </Form.Item>

              {showBeneficiaryInput ? (
                <CustomInputFieldFormItem
                  fieldName="beneficiaryName"
                  label="Beneficiary Name"
                  size="large"
                  style={{
                    width: "21.4rem",
                  }}
                  rules={[
                    { required: true, message: "Beneficiary name is required" },
                  ]}
                />
              ) : null}
            </Input.Group>

            <Button htmlType="submit" type="primary" block className="mt-5">
              Continue
            </Button>
          </Form>
        )}
      </>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "airtime",
      label: `Airtime`,
      children: <Airtime />,
    },
    {
      key: "data",
      label: `Data`,
      children: <Data />,
    },
  ];

  let handleSwitchTabs = (tab: React.SetStateAction<string>) => {
    setRequestBody(null);
    setSelectedBeneficiaryData(null);
    airtimeForm.resetFields([
      "beneficiaryPhoneNumber",
      "amount",
      "save_beneficiary",
    ]);
    dataForm.resetFields([
      "beneficiaryPhoneNumber",
      "amount",
      "save_beneficiary",
    ]);
    setAirtimeActiveTab(tab);
  };
  return (
    <div className="w-100">
      <Tabs
        items={items}
        defaultActiveKey={airtimeActiveTab}
        onChange={handleSwitchTabs}
      />
      {showBeneficiaryList && (
        <AirtimeBeneficiaryListDrawer
          setSelectedBeneficiaryData={setSelectedBeneficiaryData}
          setShowBeneficiaryList={setShowBeneficiaryList}
          showBeneficiaryList={showBeneficiaryList}
          setOperatorId={setOperatorId}
        />
      )}
    </div>
  );
};

export default AirtimeForm;
