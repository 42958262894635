import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import {appInsights} from "../components/AppInsight/AppInsight";

export const isUserActivated = ({
  profile_completion_status,
}: {
  profile_completion_status: string;
}) => (profile_completion_status?.toLowerCase() === "completed" ? true : false);

export const reValidateUserActivationStatus = async () => {
  try {
    const {
      data: { profile_completion_status },
    } = await getData(apiEndpoints.USER);
    return isUserActivated({
      profile_completion_status,
    });
  } catch (error) {
    appInsights.trackException({
      exception: error,
      properties: {fileName : isUserActivated}
    })
    return false;
  }
};
