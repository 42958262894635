import React, { Fragment, useEffect, useRef, useState } from "react";
import NGNTransferDrawerStepOne from "./NGNTransferDrawerStepOne/NGNTransferDrawerStepOne";
import NGNTransferDrawerBankList from "./WithdrawalModalStepTwo/NGNTransferDrawerBankList";
import NGNTransferDrawerBeneficiaryList from "./WithdrawalModalStepTwo/NGNTransferDrawerBeneficiaryList";
import NGNTransferDrawerStepThree from "./NGNTransferDrawerStepThree/NGNTransferDrawerStepThree";
import NGNTransferDrawerStepFourPin from "./NGNTransferDrawerStepFour/NGNTransferDrawerStepFourPin";
import { Drawer } from "antd";
import { LiaWindowClose } from "react-icons/lia";
import { PiCaretLeftBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import {
  getBankList,
  getBankTransferBeneficiary,
} from "../../../../redux/actions";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import TransferReviewPayment from "../components/TransferReviewPayment";
export enum TransferNGNSteps {
  FirstScreenBankTransferScreen = "first-screen-bank-transfer",
  ChooseABankScreen = "choose-a-bank",
  ChooseABeneficiaryScreen = "choose-a-beneficiary",
  BankTransferFormScreen = "bank-transfer-form",
  ReviewPayment = "review-payment",
  TransactionPinScreen = "enter-transaction-pin",
}
export interface ItransferBody {
  customer_id: string;

  saving_wallet_id: string;

  amount: number;

  narration: string;

  recipient_bank_code: string;

  recipient_bank_account_number: string;

  recipient_bank_account_name: string;
}

interface IProps {
  activeTab: string;
  open: boolean;
  onClose: () => void;
}

export default function NGNTransferDrawer({
  activeTab,
  onClose,
  open,
}: IProps) {
  const dispatch = useDispatch();
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const [currentStep, setCurrentStep] = useState<TransferNGNSteps>(
    TransferNGNSteps.FirstScreenBankTransferScreen
  );

  const [selectBankName, setSelectBankName] = useState<boolean>(false);
  const [selectBeneficiary, setSelectBeneficiary] = useState<boolean>(false);
  const [bankCodeState, setBankCode] = useState<string | null>(null);
  const [selectBeneficiaryData, setSelectBeneficiaryData] = useState<
    null | any
  >(null);
  const [bankTransferReqBody, setBankTransferReqBody] =
    useState<ItransferBody | null>(null);
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);

  const [saveBankName, setBankName] = useState<string | any>(null);
  const getBeneficiaryShortName = (name: string | undefined) => {
    if (name) {
      return name
        .split(" ")
        .map((word) => word[0])
        .join("");
    }
  };
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );

  useEffect(() => {
    dispatch(getBankList());
    dispatch(getBankTransferBeneficiary(String(funderId), "NGN"));
  }, [funderId]);

  const closeDrawer = () => {
    dismissRef?.current?.click();
    setTimeout(() => {
      setCurrentStep(TransferNGNSteps.FirstScreenBankTransferScreen);
    }, 1000);
    setBankTransferReqBody(null);
    setSelectBankName(false);
    setSelectBeneficiary(false);
    setSelectBeneficiaryData(null);
    setBankCode(null);
    setSaveBeneficiary(false);
    setBankName("");
    onClose();
  };

  const onResetForm = () => {
    setCurrentStep(TransferNGNSteps.FirstScreenBankTransferScreen);
    setBankTransferReqBody(null);
    setSelectBeneficiaryData(null);
  };

  const handleTransferDrawerTitle = () => {
    switch (currentStep) {
      case TransferNGNSteps.FirstScreenBankTransferScreen:
        return "Transfer to bank";
      case TransferNGNSteps.ChooseABankScreen:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() =>
                setCurrentStep(TransferNGNSteps.FirstScreenBankTransferScreen)
              }
            />
            <p className="p-none">Choose a bank</p>
          </div>
        );
      case TransferNGNSteps.BankTransferFormScreen:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => {
                onResetForm();
              }}
            />
            <p className="p-none">Bank Transfer</p>
          </div>
        );
      case TransferNGNSteps.ReviewPayment:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() =>
                setCurrentStep(TransferNGNSteps.BankTransferFormScreen)
              }
            />
            <p className="p-none">Review Payment</p>
          </div>
        );
      case TransferNGNSteps.TransactionPinScreen:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(TransferNGNSteps.ReviewPayment)}
            />
            <p className="p-none">Transaction PIN</p>
          </div>
        );
    }
  };

  return (
    <Fragment>
      <Drawer
        title={handleTransferDrawerTitle()}
        open={open}
        onClose={() => {
          onResetForm();
          onClose();
        }}
        closeIcon={<LiaWindowClose size="24px" />}
      >
        {(() => {
          switch (currentStep) {
            case TransferNGNSteps.FirstScreenBankTransferScreen:
              return (
                <NGNTransferDrawerStepOne
                  setCurrentStep={setCurrentStep}
                  getBeneficiaryShortName={getBeneficiaryShortName}
                  setSelectBeneficiary={setSelectBeneficiary}
                  setSelectBeneficiaryData={setSelectBeneficiaryData}
                  activeTab={activeTab}
                />
              );
            case TransferNGNSteps.ChooseABankScreen:
              return (
                <NGNTransferDrawerBankList
                  setCurrentStep={setCurrentStep}
                  setSelectBankName={setSelectBankName}
                  setBankCode={setBankCode}
                  getBeneficiaryShortName={getBeneficiaryShortName}
                />
              );
            case TransferNGNSteps.ChooseABeneficiaryScreen:
              return (
                <NGNTransferDrawerBeneficiaryList
                  setCurrentStep={setCurrentStep}
                  getBeneficiaryShortName={getBeneficiaryShortName}
                  setSelectBeneficiary={setSelectBeneficiary}
                  setSelectBeneficiaryData={setSelectBeneficiaryData}
                />
              );

            case TransferNGNSteps.ReviewPayment:
              return (
                <TransferReviewPayment
                  setCurrentStep={setCurrentStep}
                  requestBody={bankTransferReqBody}
                  currency="₦"
                />
              );

            case TransferNGNSteps.BankTransferFormScreen:
              return (
                <NGNTransferDrawerStepThree
                  bankCodeState={bankCodeState}
                  selectBankName={selectBankName}
                  setBankTransferReqBody={setBankTransferReqBody}
                  bankTransferReqBody={bankTransferReqBody}
                  activeTab={activeTab}
                  setCurrentStep={setCurrentStep}
                  getBeneficiaryShortName={getBeneficiaryShortName}
                  selectBeneficiaryData={selectBeneficiaryData}
                  selectBeneficiary={selectBeneficiary}
                  setSaveBeneficiary={setSaveBeneficiary}
                  setBankName={setBankName}
                  setSelectBankName={setSelectBankName}
                  setSelectBeneficiary={setSelectBeneficiary}
                  setSelectBeneficiaryData={setSelectBeneficiaryData}
                  setBankCode={setBankCode}
                />
              );
            case TransferNGNSteps.TransactionPinScreen:
              return (
                <NGNTransferDrawerStepFourPin
                  closeModal={closeDrawer}
                  bankTransferReqBody={bankTransferReqBody}
                  activeTab={activeTab}
                  setCurrentStep={setCurrentStep}
                  saveBeneficiary={saveBeneficiary}
                  saveBankName={saveBankName}
                  setSaveBeneficiary={setSaveBeneficiary}
                />
              );

            default:
              return null;
          }
        })()}
      </Drawer>
    </Fragment>
  );
}
