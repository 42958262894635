import { useState } from "react";
import UpdateBankInfoModal from "./UpdateBankInfoModal.component";
import Alert from "./../Alert/Alert.component";
import ManuallySpecifyBankInfoModal from "./ManuallySpecifyBankInfoModal.component";

interface ILinkedBankAccounts {
  funder: any;
}

const LinkedBankAccounts: React.FC<ILinkedBankAccounts> = ({ funder }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const isBankAccountAvailable =
    funder?.bank_name && funder?.account_number && funder?.account_name;
  const isNigerianAccount = funder?.country === "NG";

  return (
    <div>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <h5 className="page-subtitle">Linked Bank Accounts</h5>
      <p>
        Your settlement account is the default bank account into which your
        wallet funds will be paid.
      </p>

      {isBankAccountAvailable ? (
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-lighter-grey">
              <tr>
                <th>Bank</th>
                <th>Account Number</th>
                <th>Account Name</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{funder?.bank_name}</th>
                <td>{funder?.account_number}</td>
                <td>{funder?.account_name}</td>
                <td
                  className="text-center text-underline-hover color-blue cursor-pointer"
                  data-toggle="modal"
                  data-target={
                    isNigerianAccount
                      ? "#updateBankInfoModal"
                      : "#manuallySpecifyBankInfoModal"
                  }
                >
                  Change Account
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-sm advancly-white-btn"
            data-toggle="modal"
            data-target={
              isNigerianAccount
                ? "#updateBankInfoModal"
                : "#manuallySpecifyBankInfoModal"
            }
          >
            Add New Bank
          </button>
        </div>
      )}

      <UpdateBankInfoModal setError={setError} setSuccess={setSuccess} />
      <ManuallySpecifyBankInfoModal
        setError={setError}
        setSuccess={setSuccess}
      />
    </div>
  );
};
export default LinkedBankAccounts;
