import React, { Fragment, useRef, useState } from "react";
import useWalletBalance from "../../../../hooks/custom-hooks/useWalletBalance";
import USDTransferDrawerStepOne from "./USDTransferDrawerStepOne/USDTransferDrawerStepOne";
import { LiaWindowClose } from "react-icons/lia";
import { Drawer } from "antd";
import { PiCaretLeftBold } from "react-icons/pi";
import USDTransferDrawerStepTwo from "./USDTransferDrawerStepTwo/USDTransferDrawerStepTwo";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import TransferReviewPayment from "../components/TransferReviewPayment";
import { TransferNGNSteps } from "../NGNTransferDrawer/NGNTransferDrawer.component";
export enum TransferUSDSteps {
  BankTransferFormScreen = "bank-transfer-form",
  ReviewPayment = "review-payment",
  TransactionPinScreen = "enter-transaction-pin",
}

interface IProps {
  activeTab: string;
  open: boolean;
  onClose: () => void;
}

const USDTransferDrawer = ({ activeTab, open, onClose }: IProps) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const [bankTransferReqBody, setBankTransferReqBody] = useState<null | any>(
    null
  );

  const [currentStep, setCurrentStep] = useState(
    TransferUSDSteps.BankTransferFormScreen
  );
  const [saveBeneficiary, setBeneficiary] = useState(false);
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );

  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const walletId = activeTab !== "USD" ? lcy_wallet_id : usd_wallet_id;
  const {
    data: wallet,
    error: walletError,
    isLoading: walletLoading,
  } = useWalletBalance({
    walletId,
    funderId,
  });

  const closeDrawer = () => {
    dismissRef?.current?.click();
    setTimeout(() => {
      setBankTransferReqBody(null);
      setCurrentStep(TransferUSDSteps.BankTransferFormScreen);
    }, 1000);
  };

  const onResetForm = () => {
    setCurrentStep(TransferUSDSteps.BankTransferFormScreen);
    setBankTransferReqBody(null);
  };

  const handleTransferDrawerTitle = () => {
    switch (currentStep) {
      case TransferUSDSteps.BankTransferFormScreen:
        return "Transfer to bank";
      case TransferUSDSteps.ReviewPayment:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() =>
                setCurrentStep(TransferUSDSteps.BankTransferFormScreen)
              }
            />
            <p className="p-none">Review Payment</p>
          </div>
        );
      case TransferUSDSteps.TransactionPinScreen:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() =>
                setCurrentStep(TransferUSDSteps.BankTransferFormScreen)
              }
            />
            <p className="p-none">Transaction PIN</p>
          </div>
        );
    }
  };

  return (
    <Fragment>
      <Drawer
        title={handleTransferDrawerTitle()}
        open={open}
        onClose={() => {
          onResetForm();
          onClose();
        }}
        closeIcon={<LiaWindowClose size="24px" />}
      >
        {(() => {
          switch (currentStep) {
            case TransferUSDSteps.BankTransferFormScreen:
              return (
                <USDTransferDrawerStepOne
                  closeModal={closeDrawer}
                  setCurrentStepUSD={setCurrentStep}
                  activeTab={activeTab}
                  setBankTransferReqBody={setBankTransferReqBody}
                  bankTransferReqBody={bankTransferReqBody}
                  setBeneficiary={setBeneficiary}
                  wallet={wallet}
                  walletError={walletError}
                  walletLoading={walletLoading}
                />
              );

            case TransferUSDSteps.ReviewPayment:
              return (
                <TransferReviewPayment
                  setCurrentStep={setCurrentStep}
                  requestBody={bankTransferReqBody}
                  currency="$"
                />
              );

            case TransferUSDSteps.TransactionPinScreen:
              return (
                <USDTransferDrawerStepTwo
                  closeModal={closeDrawer}
                  activeTab={activeTab}
                  bankTransferReqBody={bankTransferReqBody}
                  setBankTransferReqBody={setBankTransferReqBody}
                  setCurrentStepUSD={setCurrentStep}
                  saveBeneficiary={saveBeneficiary}
                  setBeneficiary={setBeneficiary}
                  wallet={wallet}
                />
              );

            default:
              return null;
          }
        })()}
      </Drawer>
    </Fragment>
  );
};

export default USDTransferDrawer;
