import { isBase64 } from "./base64";
import { getData } from "../apis/apiMethods";
import { errorHandler } from "./errorHandler";
import {appInsights} from "../components/AppInsight/AppInsight";

const prepareDownloadLink = (content: string, fileName: string) => {
  const appendSource = "application/pdf";
  const linkSource = `data:${appendSource};base64,${content}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadPdfFile = async ({
  url,
  name,
  pathname,
}: {
  url: string;
  name?: string;
  pathname?: string;
}) => {
  // Download base64 directly
  if (pathname && isBase64(pathname)) {
    return prepareDownloadLink(pathname, name ? name : "");
  }
  try {
    const { data } = await getData(url);
    prepareDownloadLink(data.base64_string, name ? name : data.file_name);
    return data;
  } catch (error) {
    appInsights.trackException({
      exception: error,
      properties: {fileName : downloadPdfFile}
    })
    throw new Error(errorHandler(error));
  }
};
