import React, { Dispatch, SetStateAction, useState } from "react";
import CustomInputFieldFormItem from "../../../../CustomHTMLElements/CustomInputFieldFormItem";
import styles from "./NGNTransferDrawerBankList.module.scss";
import { ReactComponent as GreenTick } from "../../../../../assets/img/green-tick.svg";
import { TransferNGNSteps } from "../NGNTransferDrawer.component";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux";
interface ITransferNGNModalStepTwoBeneficiaries {
  setCurrentStep: Dispatch<SetStateAction<TransferNGNSteps>>;
  getBeneficiaryShortName: (name: string | undefined) => string | any;
  setSelectBeneficiary: Dispatch<SetStateAction<boolean>>;
  setSelectBeneficiaryData: Dispatch<SetStateAction<IBeneficiaries | null>>;
}
interface IBeneficiaries {
  id?: number;
  account_name: string | null;
  bank_code: string | null;
  account_number: string;
  bank_name?: string | any;
  currency?: string;
}
const NGNTransferDrawerBeneficiaryList: React.FC<
  ITransferNGNModalStepTwoBeneficiaries
> = ({
  setCurrentStep,
  getBeneficiaryShortName,
  setSelectBeneficiary,
  setSelectBeneficiaryData,
}) => {
  const [searchBeneficiariesQuery, setBeneficiariesQuery] = useState("");

  const { beneficiaryList } = useSelector(
    (state: ReduxStoreModel) => state.beneficiaryList
  );
  const handleBeneficiarySearch = (event: any) => {
    setBeneficiariesQuery(event.target.value);
  };
  const handlerTransferForm = () => {
    setSelectBeneficiary(true);
    setCurrentStep(TransferNGNSteps.BankTransferFormScreen);
  };
  return (
    <div>
      <div className="modal-header p-0 mb-0">
        <h5 className="page-subtitle">Choose a beneficiary</h5>
      </div>
      <div className="modal-body p-0 ">
        <div
          className="cursor-pointer d-flex align-items-center  color-blue my-3"
          onClick={() =>
            setCurrentStep(TransferNGNSteps.FirstScreenBankTransferScreen)
          }
        >
          <i className="ri-arrow-left-line ri-1x mr-3"></i>
          Go Back
        </div>
        {/* search by bank */}
        <div className="w-100">
          <CustomInputFieldFormItem
            type="search"
            maxLength={15}
            placeholder="Search for a beneficiary"
            onChange={handleBeneficiarySearch}
          />
        </div>
        <div className={styles.list_of_beneficiary_container}>
          {/* List of Banks */}
          <ul className={styles.list_of_Beneficiary}>
            {beneficiaryList
              .filter(({ account_name }: any) =>
                account_name.toLowerCase().includes(searchBeneficiariesQuery)
              )
              .map(
                ({
                  id,
                  account_name,
                  account_number,
                  bank_name,
                  bank_code,
                }: IBeneficiaries) => {
                  return (
                    <li
                      key={id}
                      className={styles.list_of_beneficiaries_li}
                      onClick={() => {
                        setSelectBeneficiaryData({
                          account_name,
                          account_number,
                          bank_code,
                        });
                        handlerTransferForm();
                      }}
                    >
                      <div className={styles.list_bene_names}>
                        <div className={styles.Beneficiary_logo}>
                          {/* <span> {account_name?.match(/\b\w/g).join("")}</span> */}
                          {getBeneficiaryShortName(bank_name)?.length > 2
                            ? `${getBeneficiaryShortName(bank_name)[0]}${
                                getBeneficiaryShortName(bank_name)[1]
                              }`
                            : getBeneficiaryShortName(bank_name)}
                        </div>
                        <div className={styles.list_bene_names_details}>
                          <h6>{account_name}</h6>
                          <p>
                            {account_number} ({bank_name})
                          </p>
                        </div>
                      </div>
                      <div className="pr-2">
                        <GreenTick />
                      </div>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NGNTransferDrawerBeneficiaryList;
