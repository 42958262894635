import styles from "./ProgressIndicator.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setInvestmentStep } from "../../redux/actions";
import { ReduxStoreModel } from "../../interfaces/redux";

const ProgressIndicator = () => {
  const steps = useSelector((state: ReduxStoreModel) => state.investment.steps);
  const currentStep = useSelector(
    (state: ReduxStoreModel) => state.investment.currentStep
  );
  const dispatch = useDispatch();
  const validBox = (i: number) => currentStep - 1 > i;
  return (
    <div className={styles.stepperBox}>
      <div className={styles.lineSeparatorBackground}></div>
      {steps.map((_, i: any) => {
        return (
          <div
            key={i}
            className={`${styles.stepperArea} ${
              i + 1 === 1 && styles.firstElement
            } ${i + 1 === steps.length && styles.lastElement} ${
              currentStep > i + 1 && "cursor-pointer"
            }`}
            // @ts-ignore
            onClick={() =>
              currentStep > i + 1 && dispatch(setInvestmentStep(i + 1))
            }
          >
            <div
              className={`${styles.label} ${validBox(i) && styles.active} ${
                currentStep === i + 1 && styles.current
              }`}
            >
              {validBox(i) ? "✔" : i + 1}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ProgressIndicator;
