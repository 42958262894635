import React, { Dispatch, SetStateAction } from "react";
import { TVSteps, ITVDataRequestBody } from "../TVDrawer.component";
import { Button, Card } from "antd";
import formatMoney from "../../../../helpers/formatMoney";
import { naira } from "../../../../Constants/mock";

interface IProps {
  requestBody: ITVDataRequestBody | null;
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  airtimeActiveTab: "airtime" | "data" | string;
}

const TVReviewPayment = ({
  requestBody,
  setCurrentStep,
  airtimeActiveTab,
}: IProps) => {
  return (
    <div className="w-100">
      <Card style={{ width: "100%" }}>
        <h4 className="text-center">
          {requestBody?.packageTitle ||
            formatMoney(requestBody?.amount || 0, naira)}
        </h4>
        <p className="text-center">{requestBody?.productName} Subscription</p>
        <div className="d-flex justify-content-between">
          <p>Provider</p>
          <p className="font-weight-bold">{requestBody?.productName}</p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Device Number</p>
          <p className="font-weight-bold">{requestBody?.deviceNumber}</p>
        </div>

        <div className="d-flex justify-content-between">
          <p>Amount</p>
          <p className="font-weight-bold">
            {formatMoney(requestBody?.amount || 0, naira)}
          </p>
        </div>
      </Card>
      <Button
        block
        type="primary"
        className="mt-5"
        size="large"
        onClick={() => setCurrentStep(TVSteps.TransactionPinScreen)}
      >
        Continue
      </Button>
    </div>
  );
};

export default TVReviewPayment;
