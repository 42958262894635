import { useState, useEffect } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputFieldFormItem from "../CustomHTMLElements/CustomInputFieldFormItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../helpers/yupValidators";
import Loader from "../Loader/Loader.component";
import Alert from "./../Alert/Alert.component";
import { putData } from "./../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { reValidateUserActivationStatus } from "../../helpers/isUserActivated";
import CustomSelectDropdown from "./../CustomHTMLElements/CustomSelectDropdown";
import useFunderAddress from "../../hooks/custom-hooks/useFunderAddress";
import PageLoader from "../PageLoader/PageLoader.component";
import useCountryStates from "../../hooks/custom-hooks/useCountryStates";
import useWorldCountries from "../../hooks/custom-hooks/useWorldCountries";
import { appInsights } from "../AppInsight/AppInsight";
import { setCurrentUser } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../interfaces/redux";

const schema = yup.object().shape({
  address: yupValidators.genericRequired({
    message: "Please enter your address",
    min: 1,
    max: 100,
  }),
  city: yupValidators.genericRequired({
    message: "Please enter your city",
    min: 1,
    max: 30,
  }),
  country: yupValidators.generic({}),
  state: yupValidators.genericRequired({
    message: "Please select your state",
    min: 1,
    max: 30,
  }),
  postal_code: yupValidators.generic({
    min: 0,
    max: 20,
  }),
});

interface IAddressInformation {
  funder: any;
}

const AddressInformation: React.FC<IAddressInformation> = ({ funder }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const user = useSelector((state: ReduxStoreModel) => state?.user);
  const { data: countries, isLoading: isLoadingCountries } =
    useWorldCountries();

  const resolveCountryInfo = countries?.find(
    ({ iso2 }: { iso2: string }) => iso2 === funder?.country
  );

  const { data: states, isLoading: isStatesLoading } = useCountryStates({
    countryId: resolveCountryInfo?.id,
  });

  const { data, isLoading, error: addressError } = useFunderAddress();

  useEffect(() => {
    if (data?.state && !isLoading) {
      setValue("state", data?.state);
    }
    // eslint-disable-next-line
  }, [data, states]);

  useEffect(() => {
    if (!isLoadingCountries) {
      setValue("country", resolveCountryInfo?.id);
    }
    // eslint-disable-next-line
  }, [countries]);

  const onSubmit = async ({
    address,
    state,
    city,
    postal_code,
  }: {
    address: string;
    state: string;
    city: string;
    postal_code: string;
  }) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const reqBody = {
      state,
      address,
      city,
      postal_code,
    };

    try {
      await putData(apiEndpoints.FUNDER_ADDRESS_INFORMATION, reqBody);

      // Revalidate User Status if he is initally not activated
      if (!user.activated) {
        const activated = await reValidateUserActivationStatus();
        dispatch(setCurrentUser({ ...user, activated }));
      }
      setSuccess("Address Information updated successfully.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: AddressInformation },
      });
    }
  };
  return (
    <div className="mb-3">
      {addressError && <Alert message={errorHandler(addressError)} />}
      {isLoading ? (
        <PageLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {error && <Alert message={error} />}
          {success && <Alert message={success} type="success" />}

          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <CustomInputFieldFormItem
                type="text"
                maxLength={100}
                placeholder="e.g. 45, Adekunle Road"
                label="Address"
                defaultValue={data?.address}
                {...register("address")}
                errors={errors.address}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomInputFieldFormItem
                type="text"
                maxLength={30}
                placeholder="e.g. Ikeja"
                label="City"
                defaultValue={data?.city}
                {...register("city")}
                errors={errors.city}
              />
            </div>
            <div className="col-12 col-md-6">
              <CustomSelectDropdown
                label="State"
                defaultValue={data?.state}
                {...register("state")}
                errors={errors.state}
                extraLabel={isStatesLoading ? "Loading..." : ""}
              >
                <option value="">State</option>
                {states?.map(({ name, id }: { name: string; id: number }) => {
                  return (
                    <option value={name} key={id}>
                      {name}
                    </option>
                  );
                })}
              </CustomSelectDropdown>
            </div>
            <div className="col-12 col-md-6">
              <CustomSelectDropdown
                label="Country"
                {...register("country")}
                errors={errors.country}
                readOnly
              >
                <option value="">Country</option>
                {countries?.map(
                  ({ name, id }: { name: string; id: number }) => {
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  }
                )}
              </CustomSelectDropdown>
            </div>
            <div className="col-12 col-md-6">
              <CustomInputFieldFormItem
                type="text"
                placeholder=""
                label="Zip Code"
                defaultValue={data?.postal_code}
                {...register("postal_code")}
                errors={errors.postal_code}
                extraLabel="(Optional)"
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-sm advancly-white-btn"
            disabled={loading || isStatesLoading}
          >
            Update
            {loading && <Loader />}
          </button>
        </form>
      )}
    </div>
  );
};
export default AddressInformation;
