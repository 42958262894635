import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { userReducer } from "./userReducer";
import { signUpReducer } from "./signUpReducer";
import { investmentReducer } from "./investmentReducer";
import { withdrawalReducer } from "./withdrawalReducer";
import { getNairaWalletReducer, getWalletTopUp } from "./walletReducer";
import { resetPasswordReducer } from "./resetPasswordReducer";
import { userCurrenciesReducer } from "./userAvailableCurrenciesReducer";
import {
  getBankListReducer,
  getBeneficiaryListReducer,
} from "./bankTransferReducer";
import {
  airtimeProvidersReducer,
  billBeneficiaryReducer,
  billTransactionsReducer,
  electricityProvidersReducer,
  supportedBillsReducer,
  tVProvidersReducer,
} from "./billsPaymentReducer";

const persistWithConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "this_is_actually_a_very_secretive_password",
      onError: function (error) {
        // console.log(error);
      },
    }),
  ],
};

const persistCurrenciesWithConfig = {
  key: "currencies",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "this_is_actually_a_very_secretive_password_for_currencies",
      onError: function (error) {
        // console.log(error);
      },
    }),
  ],
};

const persistWalletWithConfig = {
  key: "wallet",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "this_is_actually_a_very_secretive_password_for_wallet",
      onError: function (error) {
        // console.log(error);
      },
    }),
  ],
};

const reducers = combineReducers({
  user: persistReducer(persistWithConfig, userReducer),
  currencies: persistReducer(
    persistCurrenciesWithConfig,
    userCurrenciesReducer
  ),
  signUp: signUpReducer,
  investment: investmentReducer,
  withdrawal: withdrawalReducer,
  walletTopup: getWalletTopUp,
  // currencies: userCurrenciesReducer,
  resetPassword: persistReducer(persistWithConfig, resetPasswordReducer),
  bankList: getBankListReducer,
  beneficiaryList: getBeneficiaryListReducer,
  supportedBillLists: supportedBillsReducer,
  tvProviders: tVProvidersReducer,
  airtimeProviders: airtimeProvidersReducer,
  electricityProviders: electricityProvidersReducer,
  billBeneficiaries: billBeneficiaryReducer,
  billTransactions: billTransactionsReducer,
  wallet: persistReducer(persistWalletWithConfig, getNairaWalletReducer),
});

export default reducers;

export type TRootState = ReturnType<typeof reducers>;
