import { Component } from "react";

// Router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.component";
import Signup from "./pages/SignUp/Signup";
import Login from "./pages/Login/Login";
import { ROUTES } from "./helpers/routes";
import AuthRoutesRoot from "./components/AuthRoutesRoot/AuthRoutesRoot.component";
import Terms from "./pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import CONFIG, {getAppInsightKey} from "./helpers/config";
import PageNotFound from "./components/PageNotFound/PageNotFound.component";
import React from "react";

class App extends Component {
  render() {
      console.log({ appInsightKey: getAppInsightKey() });
    return (
      <Router>
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.SIGN_UP} component={Signup} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <Route path={ROUTES.FUNDER} component={AuthRoutesRoot} />
          <Route path={ROUTES.MOBILE_TERMS_OF_USE} component={Terms} />
          <Route
            path={ROUTES.MOBILE_PRIVACY_POLICY}
            component={PrivacyPolicy}
          />
          <Route
            path={ROUTES.MOBILE}
            component={() => {
              window.location.href = CONFIG.ADVANCLY_MOBILE_ONELINK;
              return null;
            }}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
        <ScrollToTop />
      </Router>
    );
  }
}

export default App;
