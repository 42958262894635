import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import { appInsights } from "../../../AppInsight/AppInsight";
import {
  AirtimeSteps,
  getLogo,
  ISelectedBeneficiary,
} from "../AirtimeDrawer.component";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import { RiSearch2Line } from "react-icons/ri";
import styles from "../../BankTransferDrawers/NGNTransferDrawer/WithdrawalModalStepTwo/NGNTransferDrawerBankList.module.scss";
import { Modal, Spin } from "antd";

interface IProps {
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
  setOperatorId: Dispatch<SetStateAction<string>>;
  setShowBeneficiaryList: Dispatch<SetStateAction<boolean>>;
  showBeneficiaryList: boolean;
}

const AirtimeBeneficiaryListDrawer = ({
  setOperatorId,
  setSelectedBeneficiaryData,
  setShowBeneficiaryList,
  showBeneficiaryList,
}: IProps) => {
  const { beneficiaries, isLoadingBeneficiary } = useSelector(
    (state: ReduxStoreModel) => state.billBeneficiaries
  );
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    appInsights.trackPageView({
      name: "AirtimeBeneficiaryList.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const handleSelectProvider = (
    operator_id: string,
    deviceNumber: string,
    generic_operator_name: string | null
  ) => {
    setSelectedBeneficiaryData({
      billType: "",
      currency: "",
      customerId: 0,
      operatorId: operator_id,
      productId: "",
      productName: "",
      genericOperatorName: generic_operator_name || "",
      deviceNumber: deviceNumber,
      beneficiaryName: "",
    });
    setShowBeneficiaryList(false);
  };

  return (
    <Modal
      open={showBeneficiaryList}
      onCancel={() => {
        setShowBeneficiaryList(false);
      }}
      footer={null}
      closeIcon={null}
    >
      <div className="mt-5">
        <CustomInputFieldFormItem
          size="large"
          maxLength={15}
          placeholder="Search"
          onChange={handleSearch}
          prefix={<RiSearch2Line size="20px" className="text-muted" />}
          fieldName="search"
          allowClear
        />
      </div>
      {isLoadingBeneficiary ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : beneficiaries && beneficiaries.length === 0 ? (
        <p className="text-center font-weight-bold">No Beneficiary Available</p>
      ) : (
        <div className={styles.list_of_banks_container}>
          {/* List of Banks */}
          <ul className={styles.listOfBanks}>
            {beneficiaries &&
              beneficiaries
                .filter(({ device_number, operator_id, beneficiary_name }) => {
                  return beneficiary_name?.toLowerCase()?.includes(searchQuery);
                })
                .map(
                  ({
                    product_name,
                    operator_id,
                    device_number,
                    generic_operator_name,
                    beneficiary_name,
                  }) => {
                    return (
                      <div key={operator_id}>
                        {
                          <li
                            className={styles.list_of_banks_li}
                            onClick={() => {
                              handleSelectProvider(
                                operator_id,
                                device_number,
                                generic_operator_name
                              );
                            }}
                          >
                            <div className={styles.list_of_banks_sub_container}>
                              <img
                                src={getLogo(
                                  generic_operator_name || product_name
                                )}
                                alt="list of network provider icon"
                                className={styles.list_of_bank_logo}
                              />
                              <p className={`${styles.bank_name} p-none`}>
                                {beneficiary_name || "N/A"} -
                              </p>
                              <p className={`${styles.bank_name} p-none`}>
                                ({device_number})
                              </p>
                            </div>
                          </li>
                        }
                      </div>
                    );
                  }
                )}
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default AirtimeBeneficiaryListDrawer;
