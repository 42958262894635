import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { LiaWindowClose } from "react-icons/lia";
import { Drawer } from "antd";
import { PiCaretLeftBold } from "react-icons/pi";
import TVDrawerStepOne from "./TVDrawerStepOne/TVDrawerStepOne";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux";
import TVProviderListDrawer, {
  ETVProviders,
} from "./TVDrawerStepTwo/TVProviderListDrawer";
import TVForm from "./TVDrawerStepThree/TVForm";
import TVReviewPayment from "./TVDrawerStepFour/TVReviewPayment";
import TVTransactionPin from "./TVDrawerStepFive/TVTransactionPin";
import TVBeneficiaryListDrawer from "./TVDrawerBeneficiaryList/TVBeneficiaryList";
import dstv from "../../../assets/img/pay/dstv.png";
import gotv from "../../../assets/img/pay/gotv.png";
import startimes from "../../../assets/img/pay/startimes.png";
import other from "../../../assets/img/pay/otherNetworks.png";

export interface ITVDataRequestBody {
  customerAccountId: number;
  billType: string;
  productName: string;
  productId: string;
  amount: number;
  operatorId: string;
  deviceNumber: string;
  packageTitle?: string;
  genericOperatorName: string;
}

export interface ISelectedBeneficiary {
  customerId: number;
  currency: string;
  billType: string;
  productName: string;
  productId: string;
  operatorId: string;
  deviceNumber: string;
  genericOperatorName: string;
  beneficiaryName: string;
}

interface IProps {
  activeTab: string;
  open: boolean;
  onClose: () => void;
}
export enum TVSteps {
  TVFirstScreen = "tv-first-screen",
  SelectAProvider = "select-a-provider",
  TVForm = "tv-form",
  ReviewPayment = "review-payment",
  TransactionPinScreen = "enter-transaction-pin",
}

export const getLogo = (genericOperatorName: string | null) => {
  if (genericOperatorName) {
    switch (genericOperatorName) {
      case ETVProviders.DSTV:
        return dstv;
      case ETVProviders.GOTV:
        return gotv;
      case ETVProviders.STARTIMES:
        return startimes;
      default:
        return other;
    }
  } else {
    return other;
  }
};

const TVDrawer = ({ activeTab, onClose, open }: IProps) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const [selectedBeneficiaryData, setSelectedBeneficiaryData] =
    useState<ISelectedBeneficiary | null>(null);
  const [requestBody, setRequestBody] = useState<ITVDataRequestBody | null>(
    null
  );
  const [airtimeActiveTab, setAirtimeActiveTab] = useState<
    "airtime" | "data" | string
  >("");
  const [currentStep, setCurrentStep] = useState<TVSteps>(
    TVSteps.TVFirstScreen
  );
  const [operator_id, setOperatorId] = useState("");
  const [deviceName, setDeviceName] = useState("");

  const closeDrawer = () => {
    onResetForm();
    onClose();
  };

  const onResetForm = () => {
    setRequestBody(null);
    setSelectedBeneficiaryData(null);
    setAirtimeActiveTab("");
    setOperatorId("");
    setDeviceName("");
    setCurrentStep(TVSteps.TVFirstScreen);
  };
  useEffect(() => {
    if (currentStep === TVSteps.TVFirstScreen) {
      onResetForm();
    }
  }, [currentStep]);
  const handleTransferDrawerTitle = () => {
    switch (currentStep) {
      case TVSteps.TVFirstScreen:
        return "TV Bills";

      case TVSteps.TVForm:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(TVSteps.TVFirstScreen)}
            />
            <p className="p-none">TV Bill Payment</p>
          </div>
        );
      case TVSteps.SelectAProvider:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(TVSteps.TVFirstScreen)}
            />
            <p className="p-none">Select A Provider</p>
          </div>
        );
      case TVSteps.ReviewPayment:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(TVSteps.TVForm)}
            />
            <p className="p-none">Review Payment</p>
          </div>
        );

      case TVSteps.TransactionPinScreen:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(TVSteps.ReviewPayment)}
            />
            <p className="p-none">Enter your transaction PIN</p>
          </div>
        );
    }
  };

  return (
    <div>
      <Drawer
        title={handleTransferDrawerTitle()}
        open={open}
        onClose={() => {
          closeDrawer();
        }}
        closeIcon={<LiaWindowClose size="24px" />}
      >
        {(() => {
          switch (currentStep) {
            case TVSteps.TVFirstScreen:
              return (
                <TVDrawerStepOne
                  setCurrentStep={setCurrentStep}
                  activeTab={activeTab}
                  setOperatorId={setOperatorId}
                  setSelectedBeneficiaryData={setSelectedBeneficiaryData}
                />
              );

            case TVSteps.SelectAProvider:
              return (
                <TVProviderListDrawer
                  setCurrentStep={setCurrentStep}
                  activeTab={activeTab}
                  setOperatorId={setOperatorId}
                />
              );
            case TVSteps.TVForm:
              return (
                <TVForm
                  setCurrentStep={setCurrentStep}
                  setRequestBody={setRequestBody}
                  requestBody={requestBody}
                  selectedBeneficiaryData={selectedBeneficiaryData}
                  setSelectedBeneficiaryData={setSelectedBeneficiaryData}
                  operator_id={operator_id}
                  deviceName={deviceName}
                  setDeviceName={setDeviceName}
                  setOperatorId={setOperatorId}
                />
              );
            case TVSteps.ReviewPayment:
              return (
                <TVReviewPayment
                  setCurrentStep={setCurrentStep}
                  requestBody={requestBody}
                  airtimeActiveTab={airtimeActiveTab}
                />
              );
            case TVSteps.TransactionPinScreen:
              return (
                <TVTransactionPin
                  requestBody={requestBody}
                  selectedBeneficiaryData={selectedBeneficiaryData}
                  setCurrentStep={setCurrentStep}
                  closeDrawer={closeDrawer}
                />
              );
            default:
              return null;
          }
        })()}
      </Drawer>
    </div>
  );
};

export default TVDrawer;
