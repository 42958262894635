import React, { Fragment } from "react";
import styles from "./IdentityInformation.module.scss";
import { ROUTES } from "../../helpers/routes";
import useFunderKycDocuments from "../../hooks/custom-hooks/useFunderKycDocuments";
import PageLoader from "../PageLoader/PageLoader.component";
import Alert from "../Alert/Alert.component";
import { errorHandler } from "../../helpers/errorHandler";
import { KycDocumentCard } from "../KycDocumentCard/KycDocumentCard.component";
import CONFIG from "../../helpers/config";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../interfaces/redux";

interface IdentityInformationProps {
  funder: any;
}

const IdentityInformationCorporate: React.FC<IdentityInformationProps> = () => {
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const { data, isLoading, error } = useFunderKycDocuments({ funderId });
  return (
    <Fragment>
      {error && <Alert message={errorHandler(error)} />}
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className={`two-grid-layout ${styles.contactLanding}`}>
          <KycDocumentCard
            route={ROUTES.PROFILE_SETTINGS_BUSINESS_DOCUMENT}
            iconClass="ri-file-text-line"
            title=" Business Registration Document"
            subTitle="Upload your business registration document or any document
                  that stipulates your company as a legal entity"
            data={data}
            field={CONFIG.DOCUMENT_CODES.businessRegistrationDocument}
          />
          <KycDocumentCard
            route={ROUTES.PROFILE_SETTINGS_ADDRESS_PROOF_CORPORATE}
            iconClass="ri-file-3-line"
            title="Proof of Address"
            subTitle="Upload your proof of address. Your electric, water or waste
                  bill is fine or any other document that shows"
            data={data}
            field={CONFIG.DOCUMENT_CODES.proofOfAddressCorporate}
          />
        </div>
      )}
    </Fragment>
  );
};

export default IdentityInformationCorporate;
