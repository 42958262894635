import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ReactComponent as SuccessIcon } from "../../../../../assets/img/coin-icon.svg";
import { CustomPinInputField } from "../../../../CustomHTMLElements/CustomPinInputField.component";
import { appInsights } from "../../../../AppInsight/AppInsight";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData } from "../../../../../apis/apiMethods";
import { TransferUSDSteps } from "../USDTransferDrawer.component";
import { IUSDReqBody } from "../USDTransferDrawerStepOne/USDTransferDrawerStepOne";
import { message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../interfaces/redux";
import TransferSuccessfulComponent from "../../components/TransferSuccessful.component";
import { getNairaWalletDetails } from "../../../../../redux/actions";

interface IWalletTransferModal {
  closeModal: () => void;
  bankTransferReqBody: IUSDReqBody;
  activeTab: string;
  setBankTransferReqBody: Dispatch<SetStateAction<IUSDReqBody | null>>;
  setCurrentStepUSD: Dispatch<SetStateAction<TransferUSDSteps>>;
  saveBeneficiary: boolean;
  setBeneficiary: Dispatch<SetStateAction<boolean>>;
  wallet: { gatewayBankCode: string };
}

const USDTransferDrawerStepTwo: React.FC<IWalletTransferModal> = ({
  closeModal,
  bankTransferReqBody,
  activeTab,
  setBankTransferReqBody,
  setCurrentStepUSD,
  saveBeneficiary,
  setBeneficiary,
}) => {
  const dispatch = useDispatch();
  const { lcy_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<null | any>();
  const [error, setError] = useState<null | any>(null);
  const [pin, setPin] = useState<string | null>(null);
  const [successResponse, setSuccessResponse] = useState<boolean | any>(null);
  const [isValidPin, setIsValidPin] = useState(false);
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  useEffect(() => {
    if (pin?.length === 4) {
      setIsValidPin(true);
    } else {
      setIsValidPin(false);
    }
  }, [pin]);

  const onSubmit = async () => {
    setLoading(true);
    setError("");
    setSuccess("");
    const { bank_code, ...restBankTransferReqBody } = bankTransferReqBody;
    const reqBody = {
      recipient_bank_code: bank_code,
      ...restBankTransferReqBody,
      pin: pin?.toString(),
    };
    try {
      if (saveBeneficiary) {
        await postData(apiEndpoints.DIGITAL_ADD_BENEFICIARY, {
          customer_id: funderId,
          currency: activeTab,
          bank_code: bank_code,
          account_number: reqBody?.recipient_bank_account_number,
          account_name: reqBody?.recipient_bank_account_name,
        });
      }
      let response = await postData(
        apiEndpoints.DIGITAL_BANK_POST_TRANSFER,
        reqBody
      );
      setSuccess(true);
      setSuccessResponse(response);
      dispatch(getNairaWalletDetails(funderId, lcy_wallet_id));
      setPin(null);
      setTimeout(() => {
        closeModal();
        setBankTransferReqBody(null);
      }, 40000);
    } catch (error) {
      setSuccessResponse(null);
      message.error(errorHandler(error));
      setPin(null);
      setBeneficiary(false);
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "WalletTransferModalUSDStepThree.tsx" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="d-flex text-center justify-content-center align-items-center flex-column py-5 ">
        {loading ? (
          <Spin />
        ) : success ? (
          <TransferSuccessfulComponent
            bankTransferReqBody={bankTransferReqBody}
            closeModal={closeModal}
            activeTab={activeTab}
          />
        ) : (
          <div className="w-100">
            <form onSubmit={onSubmit}>
              <CustomPinInputField
                setValue={(value: string) => setPin(value)}
                length={4}
                label="Enter transaction PIN to proceed"
                focus={true}
              />

              <div className="d-flex justify-content-between mt-5">
                <button
                  type="button"
                  className="btn btn-sm advancly-white-btn w-50 mr-2"
                  onClick={() => {
                    setCurrentStepUSD(TransferUSDSteps.ReviewPayment);
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-sm advancly-btn w-50 ml-2"
                  disabled={loading || !isValidPin}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default USDTransferDrawerStepTwo;
