import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { LiaWindowClose } from "react-icons/lia";
import { Drawer } from "antd";
import { PiCaretLeftBold } from "react-icons/pi";
import AirtimeDrawerStepOne from "./AirtimeDrawerStepOne/AirtimeDrawerStepOne";
import AirtimeProviderListDrawer, {
  ENetworkProviders,
} from "./AirtimeDrawerStepTwo";
import AirtimeForm from "./AirtimeDrawerStepThree";
import AirtimeReviewPayment from "./AirtimeDrawerStepFour";
import AirtimeTransactionPin from "./AirtimeDrawerStepFive";
import AirtimeBeneficiaryListDrawer from "./AirtimeDrawerBeneficiaryList";
import mtn from "../../../assets/img/pay/mtn.png";
import glo from "../../../assets/img/pay/glo.png";
import airtel from "../../../assets/img/pay/airtel.png";
import nineMobile from "../../../assets/img/pay/9Mobile.jpg";
import other from "../../../assets/img/pay/otherNetworks.png";

export interface IAirtimeDataRequestBody {
  customerAccountId: number;
  billType: string;
  productName: string;
  productId: string;
  amount: number;
  operatorId: string;
  beneficiaryPhoneNumber: string;
  dataPlanTitle?: string;
  genericOperatorName: string;
}

export interface ISelectedBeneficiary {
  customerId: number;
  currency: string;
  billType: string;
  productName: string;
  productId: string;
  operatorId: string;
  deviceNumber: string;
  genericOperatorName: string;
  beneficiaryName: string;
}

interface IProps {
  activeTab: string;
  open: boolean;
  onClose: () => void;
}
export enum AirtimeSteps {
  AirtimeFirstScreen = "airtime-first-screen",
  SelectAProvider = "select-a-provider",
  AirtimeForm = "airtime-form",
  ReviewPayment = "review-payment",
  TransactionPinScreen = "enter-transaction-pin",
}

export const getLogo = (name: string | null) => {
  const provider = name?.toLowerCase();
  switch (provider) {
    case ENetworkProviders.MTN:
      return mtn;
    case ENetworkProviders.GLO:
      return glo;
    case ENetworkProviders.AIRTEL:
      return airtel;
    case ENetworkProviders["9Mobile"]:
      return nineMobile;
    default:
      return other;
  }
};

const AirtimeDrawer = ({ activeTab, onClose, open }: IProps) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);
  const [selectedBeneficiaryData, setSelectedBeneficiaryData] =
    useState<ISelectedBeneficiary | null>(null);
  const [requestBody, setRequestBody] =
    useState<IAirtimeDataRequestBody | null>(null);
  const [airtimeActiveTab, setAirtimeActiveTab] = useState<
    "airtime" | "data" | string
  >("airtime");
  const [currentStep, setCurrentStep] = useState<AirtimeSteps>(
    AirtimeSteps.AirtimeFirstScreen
  );
  const [operator_id, setOperatorId] = useState("");

  const closeDrawer = () => {
    onResetForm();
    onClose();
  };

  const onResetForm = () => {
    setRequestBody(null);
    setSelectedBeneficiaryData(null);
    setAirtimeActiveTab("");
    setOperatorId("");
    setCurrentStep(AirtimeSteps.AirtimeFirstScreen);
  };

  useEffect(() => {
    if (currentStep === AirtimeSteps.AirtimeFirstScreen) {
      onResetForm();
    }
  }, [currentStep]);

  const handleTransferDrawerTitle = () => {
    switch (currentStep) {
      case AirtimeSteps.AirtimeFirstScreen:
        return "Airtime & Data";

      case AirtimeSteps.AirtimeForm:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(AirtimeSteps.AirtimeFirstScreen)}
            />
            <p className="p-none">Airtime & Data</p>
          </div>
        );
      case AirtimeSteps.SelectAProvider:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(AirtimeSteps.AirtimeFirstScreen)}
            />
            <p className="p-none">Select A Provider</p>
          </div>
        );
      case AirtimeSteps.ReviewPayment:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(AirtimeSteps.AirtimeForm)}
            />
            <p className="p-none">Review Payment</p>
          </div>
        );

      case AirtimeSteps.TransactionPinScreen:
        return (
          <div>
            <PiCaretLeftBold
              size={20}
              className="text-muted mb-2 cursor-pointer"
              onClick={() => setCurrentStep(AirtimeSteps.ReviewPayment)}
            />
            <p className="p-none">Enter your transaction PIN</p>
          </div>
        );
    }
  };

  return (
    <div>
      <Drawer
        title={handleTransferDrawerTitle()}
        open={open}
        onClose={() => {
          closeDrawer();
        }}
        closeIcon={<LiaWindowClose size="24px" />}
      >
        {(() => {
          switch (currentStep) {
            case AirtimeSteps.AirtimeFirstScreen:
              return (
                <AirtimeDrawerStepOne
                  setCurrentStep={setCurrentStep}
                  activeTab={activeTab}
                  setOperatorId={setOperatorId}
                  operator_id={operator_id}
                  setSelectedBeneficiaryData={setSelectedBeneficiaryData}
                />
              );

            case AirtimeSteps.SelectAProvider:
              return (
                <AirtimeProviderListDrawer
                  setCurrentStep={setCurrentStep}
                  activeTab={activeTab}
                  setOperatorId={setOperatorId}
                />
              );
            case AirtimeSteps.AirtimeForm:
              return (
                <AirtimeForm
                  setCurrentStep={setCurrentStep}
                  setRequestBody={setRequestBody}
                  requestBody={requestBody}
                  selectedBeneficiaryData={selectedBeneficiaryData}
                  setSelectedBeneficiaryData={setSelectedBeneficiaryData}
                  setAirtimeActiveTab={setAirtimeActiveTab}
                  airtimeActiveTab={airtimeActiveTab}
                  operator_id={operator_id}
                  setOperatorId={setOperatorId}
                />
              );
            case AirtimeSteps.ReviewPayment:
              return (
                <AirtimeReviewPayment
                  setCurrentStep={setCurrentStep}
                  requestBody={requestBody}
                  airtimeActiveTab={airtimeActiveTab}
                />
              );
            case AirtimeSteps.TransactionPinScreen:
              return (
                <AirtimeTransactionPin
                  requestBody={requestBody}
                  selectedBeneficiaryData={selectedBeneficiaryData}
                  setCurrentStep={setCurrentStep}
                  closeDrawer={closeDrawer}
                />
              );
            default:
              return null;
          }
        })()}
      </Drawer>
    </div>
  );
};

export default AirtimeDrawer;
