import React, { useEffect, useState } from "react";
import creditAd from "../../assets/img/pay/ad.png";
import { Card } from "antd";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { TfiReceipt } from "react-icons/tfi";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import { PiCaretRightBold } from "react-icons/pi";
import { BsBank2 } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import NGNTransferDrawer from "../../components/Pay/BankTransferDrawers/NGNTransferDrawer/NGNTransferDrawer.component";
import USDTransferDrawer from "../../components/Pay/BankTransferDrawers/USDTransferDrawer/USDTransferDrawer.component";
import AirtimeDrawer from "../../components/Pay/AirtimeDrawers/AirtimeDrawer.component";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../interfaces/redux";
import { ESupportedBills } from "../../Constants/mock";
import {
  getBillPaymentBeneficiary,
  getBillPaymentTransactions,
  getNairaWalletDetails,
} from "../../redux/actions";

const Pay = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currencyList = useSelector(
    (state: ReduxStoreModel) => state.currencies.availableCurrencies
  );
  const { supportedBills } = useSelector(
    (state: ReduxStoreModel) => state.supportedBillLists
  );

  const customerAccountId = useSelector(
    (state: ReduxStoreModel) => state?.user?.customerAccountId
  );

  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );

  const { lcy_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );

  const [activeTab, setActiveTab] = useState("");
  const [NGNTransferDrawerIsOpened, setNGNTransferDrawerIsOpened] =
    useState(false);
  const [USDTransferDrawerIsOpened, setUSDTransferDrawerIsOpened] =
    useState(false);
  const [airtimeDrawerIsOpened, setAirtimeDrawerIsOpened] = useState(false);

  useEffect(() => {
    appInsights.trackPageView({ name: "Pay.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    dispatch(getNairaWalletDetails(funderId, lcy_wallet_id));
  }, []);

  useEffect(() => {
    if (currencyList.length > 0) {
      setActiveTab(currencyList[0]?.currencyCode);
    }
  }, [currencyList]);

  useEffect(() => {
    if (airtimeDrawerIsOpened) {
      dispatch(
        getBillPaymentTransactions(
          customerAccountId,
          "NGN",
          ESupportedBills.TELCO
        )
      );
    }
  }, [airtimeDrawerIsOpened]);
  return (
    <div className="container actions">
      <div className="page-title">Pay</div>
      <br />
      <div className="row">
        <div className="col-md-6">
          <Card
            bordered={false}
            style={{
              overflow: "auto",
            }}
            className="mb-3 mb-md-0"
          >
            <div
              className="d-flex justify-content-between align-items-center"
              style={
                currencyList[0]?.currencyCode !== "NGN"
                  ? { marginBottom: "-20px" }
                  : {}
              }
              onClick={() => setUSDTransferDrawerIsOpened(true)}
            >
              <div className="d-flex align-items-center">
                <div className="icon">
                  <BiTransfer
                    style={{
                      transform: "rotate(35deg)",
                    }}
                  />
                </div>
                <p className="p-none font-weight-bold dashboard-actions__text">
                  Foreign Transfer
                </p>
              </div>
              <div>
                <PiCaretRightBold className="text-muted" size={16} />
              </div>
            </div>

            {currencyList[0]?.currencyCode === "NGN" && (
              <>
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => setNGNTransferDrawerIsOpened(true)}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon">
                      <BsBank2 />
                    </div>
                    <p className="p-none font-weight-bold dashboard-actions__text">
                      {`${activeTab} Transfer`}
                    </p>
                  </div>
                  <div>
                    <PiCaretRightBold className="text-muted" size={16} />
                  </div>
                </div>

                {supportedBills?.includes(ESupportedBills.TELCO) && (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    onClick={() => setAirtimeDrawerIsOpened(true)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="icon">
                        <HiOutlineDevicePhoneMobile />
                      </div>
                      <p className="p-none font-weight-bold dashboard-actions__text">
                        Get Airtime
                      </p>
                    </div>
                    <div>
                      <PiCaretRightBold className="text-muted" size={16} />
                    </div>
                  </div>
                )}

                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => history.push(`${ROUTES.PAY}/bills`)}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon">
                      <TfiReceipt />
                    </div>
                    <p className="p-none font-weight-bold dashboard-actions__text">
                      Pay Bills
                    </p>
                  </div>
                  <div>
                    <PiCaretRightBold className="text-muted" size={16} />
                  </div>
                </div>
              </>
            )}
          </Card>
        </div>
        <div className="col-md-6">
          <img
            src={creditAd}
            alt="Coming soon"
            className="mw-100 mb-3 mb-md-0 w-100"
            style={{ objectFit: "contain", height: "574px" }}
          />
        </div>
      </div>
      <NGNTransferDrawer
        activeTab={activeTab}
        open={NGNTransferDrawerIsOpened}
        onClose={() => setNGNTransferDrawerIsOpened(false)}
      />

      <USDTransferDrawer
        activeTab={activeTab}
        open={USDTransferDrawerIsOpened}
        onClose={() => setUSDTransferDrawerIsOpened(false)}
      />

      <AirtimeDrawer
        activeTab={activeTab}
        open={airtimeDrawerIsOpened}
        onClose={() => setAirtimeDrawerIsOpened(false)}
      />
    </div>
  );
};

export default Pay;
