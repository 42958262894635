import { Dispatch } from "redux";
import { TSignUp } from "../../interfaces/individualSignUp";
import {
  TInvestmentStep,
  TInvestmentType,
  TOfflineInvestmentInfo,
  TOnlineInvestmentInfo,
  TSelectedInvestment,
} from "../../interfaces/investment";
import { TResetPassword } from "../../interfaces/resetPassword";
import { TWithdrawal } from "../../interfaces/withdrawal";
import { TWalletTopUp } from "../../interfaces/wallet";
import { getData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { mockUserAvailableCurrencies } from "../../pages/Login/service/mock";
import {
  ESupportedBills,
  mockAirtimeProviderList,
  mockBankList,
  mockBeneficiaryList,
  mockElectricityProviderList,
  mockSupportedBills,
  mockTVProviderList,
} from "../../Constants/mock";
import { actionType } from "./types";

const handleMock = (dispatch: Dispatch, type: any, payload: any) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(
        dispatch({
          type,
          payload,
        })
      );
    }, 3000);
  });
};
export const setCurrentUser = (payload: {
  business_name: any;
  country: any;
  lcy_wallet_id: any;
  role: any;
  isEmailValidated: any;
  funder_type_id: any;
  mobile?: number;
  last_name: any;
  funder_id: any;
  phoneNoCountryDialCode: any;
  token: any;
  usd_wallet_id: any;
  showBalance: boolean;
  forcePasswordChange: any;
  kyc_resolved_lastname: any;
  isBvnValidated: any;
  kyc_resolved_firstname: any;
  first_name: any;
  ssoUserId: any;
  email: any;
  session_timeout: Date;
  activated: boolean;
  customerAccountId: number;
}) => {
  console.log({
    payload,
  });
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_LOGGED_IN_USER,
      payload,
    });
  };
};
export const setUserToken = (payload: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_LOGGED_IN_USER_TOKEN,
      payload,
    });
  };
};

export const clearUserInfo = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.CLEAR_LOGGED_IN_USER,
    });
  };
};

export const setSignUp = (payload: TSignUp) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_SIGN_UP,
      payload,
    });
  };
};

export const resetSignUp = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.RESET_SIGN_UP,
    });
  };
};

export const selectInvestment = (payload: TSelectedInvestment) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SELECT_INVESTMENT,
      payload,
    });
  };
};

export const setInvestmentStep = (payload: TInvestmentStep) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_INVESTMENT_STEP,
      payload,
    });
  };
};
export const setInvestmentAgreementCheck = (payload: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_INVESTMENT_AGREEMENT_CHECK,
      payload,
    });
  };
};
export const setInvestmentType = (payload: TInvestmentType) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_INVESTMENT_TYPE,
      payload,
    });
  };
};

export const setOnlineInvestmentInfo = (payload: TOnlineInvestmentInfo) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_ONLINE_INVESTMENT_INFO,
      payload,
    });
  };
};

export const setOfflineInvestmentInfo = (payload: TOfflineInvestmentInfo) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_OFFLINE_INVESTMENT_INFO,
      payload,
    });
  };
};

export const resetInvestment = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.RESET_INVESTMENT,
    });
  };
};
export const setWithdrawalInfo = (payload: TWithdrawal) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_WITHDRAWAL_INFO,
      payload,
    });
  };
};

export const resetWithdrawal = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.RESET_WITHDRAWAL,
    });
  };
};

export const setWalletTopUpInfo = (payload: TWalletTopUp) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_WALLET_TOPUP_INFO,
      payload,
    });
  };
};

export const resetWalletTopUp = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.RESET_WALLET_TOPUP,
    });
  };
};

export const setResetPasswordInfo = (payload: TResetPassword) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_RESET_PASSWORD_INFO,
      payload,
    });
  };
};

export const setShowBalance = (payload: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SHOW_BALANCE,
      payload,
    });
  };
};

export const setMinimumTopUpAmounts = (payload: TWalletTopUp) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actionType.SET_MINIMUM_TOP_UP_AMOUNTS,
      payload,
    });
  };
};

export const getCurrencies = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_USER_AVAILABLE_CURRENCIES,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(
              dispatch({
                type: actionType.SET_USER_AVAILABLE_CURRENCIES,
                payload: mockUserAvailableCurrencies.data,
              })
            );
          }, 3000);
        });
      } else {
        const response = await getData(apiEndpoints.CURRENCIES);
        console.log({
          currencies: response,
        });
        dispatch({
          type: actionType.SET_USER_AVAILABLE_CURRENCIES,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_USER_AVAILABLE_CURRENCIES_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_USER_AVAILABLE_CURRENCIES,
        payload: false,
      });
    }
  };
};

export const getBankList = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_BANK_LIST,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        await handleMock(dispatch, actionType.SET_BANK_LIST, mockBankList.data);
      } else {
        const response = await getData(apiEndpoints.DIGITAL_BANK_LIST);
        dispatch({
          type: actionType.SET_BANK_LIST,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_BANK_LIST_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_BANK_LIST,
        payload: false,
      });
    }
  };
};

export const getBankTransferBeneficiary = (
  funderId: string,
  currency_code: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_BENEFICIARY_LIST,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        await handleMock(
          dispatch,
          actionType.SET_BENEFICIARY_LIST,
          mockBeneficiaryList.data
        );
      } else {
        const response = await getData(
          `${apiEndpoints.DIGITAL_GET_BENEFICIARY}?customer_id=${funderId}&currency_code=${currency_code}`
        );

        dispatch({
          type: actionType.SET_BENEFICIARY_LIST,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_BENEFICIARY_LIST_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_BENEFICIARY_LIST,
        payload: false,
      });
    }
  };
};

export const getSupportedBills = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_SUPPORTED_BILLS,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        await handleMock(
          dispatch,
          actionType.SET_SUPPORTED_BILLS,
          mockSupportedBills.data
        );
      } else {
        const response = await getData(apiEndpoints.GET_SUPPORTED_BILLS);
        dispatch({
          type: actionType.SET_SUPPORTED_BILLS,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_SUPPORTED_BILLS_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_SUPPORTED_BILLS,
        payload: false,
      });
    }
  };
};

export const getTVProviders = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_TV_PROVIDERS,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        await handleMock(
          dispatch,
          actionType.SET_TV_PROVIDERS,
          mockTVProviderList.data
        );
      } else {
        const response = await getData(
          `${apiEndpoints.GET_PROVIDER_LIST_AND_PRODUCTS}?bill_name=${ESupportedBills.TELEVISION}`
        );
        dispatch({
          type: actionType.SET_TV_PROVIDERS,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_TV_PROVIDERS_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_TV_PROVIDERS,
        payload: false,
      });
    }
  };
};

export const getAirtimeProviders = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_AIRTIME_PROVIDERS,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        await handleMock(
          dispatch,
          actionType.SET_AIRTIME_PROVIDERS,
          mockAirtimeProviderList.data
        );
      } else {
        const response = await getData(
          `${apiEndpoints.GET_PROVIDER_LIST_AND_PRODUCTS}?bill_name=${ESupportedBills.TELCO}`
        );
        dispatch({
          type: actionType.SET_AIRTIME_PROVIDERS,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_AIRTIME_PROVIDERS_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_AIRTIME_PROVIDERS,
        payload: false,
      });
    }
  };
};

export const getElectricityProviders = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_ELECTRICITY_PROVIDERS,
        payload: true,
      });
      if (CONFIG.USE_MOCK) {
        await handleMock(
          dispatch,
          actionType.SET_ELECTRICITY_PROVIDERS,
          mockElectricityProviderList.data
        );
      } else {
        const response = await getData(
          `${apiEndpoints.GET_PROVIDER_LIST_AND_PRODUCTS}?bill_name=${ESupportedBills.ELECTRICITY}`
        );
        dispatch({
          type: actionType.SET_ELECTRICITY_PROVIDERS,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_ELECTRICITY_PROVIDERS_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_ELECTRICITY_PROVIDERS,
        payload: false,
      });
    }
  };
};

export const getBillPaymentBeneficiary = (
  funderId: number,
  currency_code: string,
  generic_operator_name: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_BILL_BENEFICIARY_LIST,
        payload: true,
      });
      const response = await getData(
        `${apiEndpoints.BILL_PAYMENT_BENEFICIARY}?customer_id=${funderId}&currency_code=${currency_code}&generic_operator_name=${generic_operator_name}`
      );

      dispatch({
        type: actionType.SET_BILL_BENEFICIARY_LIST,
        payload: response?.data,
      });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_BILL_BENEFICIARY_LIST_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_BILL_BENEFICIARY_LIST,
        payload: false,
      });
    }
  };
};

export const getBillPaymentTransactions = (
  funderId: number,
  currency_code: string,
  bill_type: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_BILL_TRANSACTIONS,
        payload: true,
      });
      const response = await getData(
        `${apiEndpoints.BILL_PAYMENT_RECENT_TRANSACTIONS}?customer_account_id=${funderId}&currency_code=${currency_code}&bill_type=${bill_type}`
      );

      dispatch({
        type: actionType.SET_BILL_TRANSACTIONS,
        payload: response?.data,
      });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: actionType.SET_BILL_TRANSACTIONS_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_BILL_TRANSACTIONS,
        payload: false,
      });
    }
  };
};

export const getNairaWalletDetails = (funderId: number, walletId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actionType.LOADING_WALLET,
        payload: true,
      });
      const response = await getData(
        `${apiEndpoints.WALLET_BALANCE}?savingsWalletId=${walletId}&funderId=${funderId}`
      );

      dispatch({
        type: actionType.SET_WALLET,
        payload: response?.data,
      });
    } catch (error) {
      dispatch({
        type: actionType.SET_WALLET_FAILED,
      });
    } finally {
      dispatch({
        type: actionType.LOADING_WALLET,
        payload: false,
      });
    }
  };
};
