import { TWithdrawal } from "../../interfaces/withdrawal";
import { ReduxActionModel } from "../../interfaces/redux";
import { actionType } from "../actions/types";

export const initialState: TWithdrawal = {
  currentStep: 1,
  currency: "",
  walletId: null,
  amount: "",
  accountDetails: {
    type: "",
    accountNumber: "",
    bankId: "",
    accountName: "",
  },
  comment: "",
};

export const withdrawalReducer = (
  state: TWithdrawal = initialState,
  action: ReduxActionModel
): TWithdrawal => {
  switch (action.type) {
    case actionType.SET_WITHDRAWAL_INFO:
      state = {
        ...state,
        ...action.payload,
      };
      return state;

    case actionType.RESET_WITHDRAWAL:
      return initialState;
    default:
      return state;
  }
};
