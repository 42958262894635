import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { appInsights } from "../../../AppInsight/AppInsight";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import useWalletBalance from "../../../../hooks/custom-hooks/useWalletBalance";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import formatMoney from "../../../../helpers/formatMoney";
import { IBillProvider, IProduct } from "../../../../interfaces/billPayment";
import { getData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { ESupportedBills, naira } from "../../../../Constants/mock";
import { errorHandler } from "../../../../helpers/errorHandler";
import {
  TVSteps,
  ITVDataRequestBody,
  ISelectedBeneficiary,
} from "../TVDrawer.component";
import { validatePhoneNumber } from "../../../../helpers";
import { useValidateTVDeviceNumber } from "../../../../hooks/custom-hooks/useBillPayments";
import { FaUserLarge } from "react-icons/fa6";
import { getBillPaymentBeneficiary } from "../../../../redux/actions";
import TVBeneficiaryList from "../TVDrawerBeneficiaryList/TVBeneficiaryList";

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<string | any>>;
  setRequestBody: Dispatch<SetStateAction<ITVDataRequestBody | null>>;
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
  operator_id: string;
  requestBody: ITVDataRequestBody | null;
  selectedBeneficiaryData: ISelectedBeneficiary | null;
  setDeviceName: (deviceName: string) => void;
  deviceName: string;
  setOperatorId: Dispatch<SetStateAction<string>>;
}
const TVForm = ({
  operator_id,
  setCurrentStep,
  setRequestBody,
  setSelectedBeneficiaryData,
  requestBody,
  selectedBeneficiaryData,
  deviceName,
  setDeviceName,
  setOperatorId,
}: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { lcy_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const { supportedTVProviders } = useSelector(
    (state: ReduxStoreModel) => state.tvProviders
  );
  const { wallet } = useSelector((state: ReduxStoreModel) => state.wallet);
  const funderId = useSelector(
    (state: ReduxStoreModel) => state?.user?.funder_id
  );
  const customerAccountId = useSelector(
    (state: ReduxStoreModel) => state?.user?.customerAccountId
  );

  const [selectedPackage, setSelectedPackage] = useState<
    IProduct | undefined
  >();
  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [packages, setPackages] = useState<IProduct[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<
    IBillProvider | undefined
  >();
  const [tvDeviceNumber, setTvDeviceNumber] = useState("");
  const [showBeneficiaryList, setShowBeneficiaryList] = useState(false);
  const [showBeneficiaryInput, setShowBeneficiaryInput] = useState(false);

  const fetchProducts = async () => {
    try {
      setIsFetchingProducts(true);
      const { data } = await getData(
        `${apiEndpoints.GET_PROVIDER_LIST_AND_PRODUCTS}/${operator_id}/products?bill_name=${ESupportedBills.TELEVISION}`
      );
      setPackages(data);
      const provider = supportedTVProviders?.find(
        (s) => s.operator_id === operator_id
      );
      console.log({
        provider,
      });
      if (provider) {
        setSelectedProvider(provider);
      }
    } catch (error) {
      message.error(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "AirtimeForm" },
      });
    } finally {
      setIsFetchingProducts(false);
    }
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "TVForm.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    console.log({
      productId: requestBody?.productId,
      packages,
    });
    if (requestBody?.productId) {
      handleSelectPackage(requestBody?.productId);
    }
  }, [requestBody, packages]);

  const handleSubmit = async (values: {
    deviceNumber: string;
    operatorId: string;
    save_beneficiary: boolean | undefined;
    beneficiaryName: string;
  }) => {
    const {
      deviceNumber,
      save_beneficiary,
      operatorId: productName,
      beneficiaryName,
    } = values;
    console.log({ values, selectedPackage });
    if (selectedProvider && selectedPackage) {
      const { name, fee_amount, product_id, operator_id } = selectedPackage;
      const reqBody = {
        customerAccountId,
        billType: ESupportedBills.TELEVISION,
        productName,
        productId: product_id,
        amount: fee_amount,
        deviceNumber,
        operatorId: operator_id,
        packageTitle: name,
        genericOperatorName: selectedProvider.generic_operator_name,
      };
      if (save_beneficiary) {
        setSelectedBeneficiaryData({
          productName: name,
          billType: ESupportedBills.TELEVISION,
          currency: "NGN",
          customerId: funderId,
          productId: product_id,
          deviceNumber,
          operatorId: operator_id,
          genericOperatorName: selectedProvider.generic_operator_name,
          beneficiaryName,
        });
      }
      setRequestBody(reqBody);
      setCurrentStep(TVSteps.ReviewPayment);
    } else {
      message.error(
        "Something went wrong, please try again or reload the page"
      );
    }
  };

  const handleSelectPackage = (productId: string) => {
    const product = packages.find((d) => d.product_id === productId);
    if (product) {
      setSelectedPackage(product);
    }
  };

  const { data, isLoading, error } = useValidateTVDeviceNumber({
    device_number: tvDeviceNumber,
    operator_id: selectedProvider?.operator_id || "",
  });

  useEffect(() => {
    if (data?.name) {
      setDeviceName(data?.name);
    }
    if (selectedBeneficiaryData?.beneficiaryName) {
      setDeviceName(selectedBeneficiaryData.beneficiaryName);
    }
    console.log({
      selectedBeneficiaryData,
    });
  }, [data, isLoading, selectedBeneficiaryData]);

  useEffect(() => {
    if (error) {
      console.log("error");
      message.error("Error validating device number");
    }
  }, [error]);

  const fetchBeneficiary = () => {
    dispatch(
      getBillPaymentBeneficiary(
        funderId,
        "NGN",
        selectedProvider?.generic_operator_name || ""
      )
    );
  };

  useEffect(() => {
    if (selectedBeneficiaryData?.deviceNumber) {
      form.setFieldValue("deviceNumber", selectedBeneficiaryData.deviceNumber);
    }
    if (requestBody?.deviceNumber) {
      form.setFieldValue("deviceNumber", requestBody.deviceNumber);
    }
  }, [selectedBeneficiaryData, requestBody]);

  const ProductsLoader = () => {
    return (
      <div className="mt-5">
        <div className="w-100 d-flex justify-content-center">
          <Spin size="large" />
        </div>
        <p className="text-center">Fetching Products</p>
      </div>
    );
  };

  return (
    <div className="w-100">
      <>
        {isFetchingProducts ? (
          <ProductsLoader />
        ) : (
          <Form
            layout="vertical"
            name="normal_login"
            className="login-form "
            requiredMark
            onFinish={handleSubmit}
            onFinishFailed={() => null}
            onFieldsChange={(values) => {
              // @ts-ignore
              if (values[0]?.name[0] === "save_beneficiary") {
                setShowBeneficiaryInput(values[0]?.value);
              }
            }}
            size="large"
            form={form}
          >
            <CustomInputFieldFormItem
              fieldName="source_account"
              label="Source Account"
              initialValue={`${wallet?.accountNumber} - ${formatMoney(
                wallet?.availableBalance || 0,
                "NGN"
              )} (${wallet?.accountName})`}
              readOnly
              size="large"
            />

            <CustomInputFieldFormItem
              fieldName="operatorId"
              label="Provider"
              initialValue={selectedProvider?.name}
              readOnly
              size="large"
            />
            <Tooltip title="Select a beneficiary from your beneficiary list">
              <Typography.Link
                href="#"
                onClick={() => {
                  setShowBeneficiaryList(true);
                  fetchBeneficiary();
                }}
              >
                Find Beneficiary?
              </Typography.Link>
            </Tooltip>
            <Form.Item label="Smartcard Number">
              <Form.Item
                name="deviceNumber"
                rules={[
                  { required: true, message: "Smartcard number is required" },
                ]}
                hasFeedback={!data?.name}
                validateStatus={
                  error
                    ? "error"
                    : isLoading
                    ? "validating"
                    : (data?.name || selectedBeneficiaryData?.deviceNumber) &&
                      "success"
                }
              >
                <Input
                  size="large"
                  onBlur={() => {
                    const num = form.getFieldValue("deviceNumber");
                    setTvDeviceNumber(num);
                  }}
                />
              </Form.Item>
            </Form.Item>

            {deviceName && (
              <p
                className="text-primary font-weight-bold"
                style={{ marginTop: "-30px" }}
              >
                {" "}
                <FaUserLarge className="mr-1" />
                {deviceName}
              </p>
            )}

            <CustomInputFieldFormItem
              fieldName="productId"
              placeholder="Select Package"
              type="number"
              size="large"
              label="Package"
              rules={[
                {
                  required: true,
                  message: "Please select a package",
                },
              ]}
              initialValue={requestBody?.productId}
              InputComponent={
                <Select onChange={handleSelectPackage}>
                  <Select.Option value="">Select Data Plan</Select.Option>
                  {packages?.map(({ name, fee_amount, product_id }) => (
                    <Select.Option value={product_id} key={product_id}>
                      {`${name} - ${formatMoney(fee_amount, naira)}`}
                    </Select.Option>
                  ))}
                </Select>
              }
            />

            <Input.Group compact>
              <Form.Item
                label="Save Beneficiary"
                name="save_beneficiary"
                valuePropName="checked"
                className="mr-4"
              >
                <Switch />
              </Form.Item>

              {showBeneficiaryInput ? (
                <CustomInputFieldFormItem
                  fieldName="beneficiaryName"
                  label="Beneficiary Name"
                  size="large"
                  style={{
                    width: "21.4rem",
                  }}
                  rules={[
                    { required: true, message: "Beneficiary name is required" },
                  ]}
                />
              ) : null}
            </Input.Group>

            <Button
              disabled={!deviceName}
              htmlType="submit"
              type="primary"
              block
              className="mt-5"
            >
              Continue
            </Button>
          </Form>
        )}
        <TVBeneficiaryList
          setSelectedBeneficiaryData={setSelectedBeneficiaryData}
          setShowBeneficiaryList={setShowBeneficiaryList}
          showBeneficiaryList={showBeneficiaryList}
        />
      </>
    </div>
  );
};

export default TVForm;
