import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux";
import { appInsights } from "../../../AppInsight/AppInsight";
import {
  ElectricitySteps,
  ISelectedBeneficiary,
} from "../ElectricityDrawer.component";
import CustomInputFieldFormItem from "../../../CustomHTMLElements/CustomInputFieldFormItem";
import { RiSearch2Line } from "react-icons/ri";
import styles from "../../BankTransferDrawers/NGNTransferDrawer/WithdrawalModalStepTwo/NGNTransferDrawerBankList.module.scss";
import { IBillBeneficiary } from "../../../../interfaces/billPayment";
import { ESupportedBills } from "../../../../Constants/mock";
import { getLogo } from "../../../../helpers";
import { Modal, Spin } from "antd";

interface IProps {
  setSelectedBeneficiaryData: Dispatch<
    SetStateAction<ISelectedBeneficiary | null>
  >;
  setShowBeneficiaryList: Dispatch<SetStateAction<boolean>>;
  showBeneficiaryList: boolean;
}

const ElectricityBeneficiaryListDrawer = ({
  setSelectedBeneficiaryData,
  setShowBeneficiaryList,
  showBeneficiaryList,
}: IProps) => {
  const { beneficiaries, isLoadingBeneficiary } = useSelector(
    (state: ReduxStoreModel) => state.billBeneficiaries
  );
  const { supportedElectricityProviders } = useSelector(
    (state: ReduxStoreModel) => state.electricityProviders
  );

  useEffect(() => {
    console.log({
      beneficiaries,
    });
  }, [beneficiaries]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    appInsights.trackPageView({
      name: "ElectricityBeneficiaryListDrawer.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log({
      value: e.target.value,
    });
    setSearchQuery(e.target.value);
  };
  const handleSelectProvider = (
    operator_id: string,
    deviceNumber: string,
    meter_type: string | null,
    generic_operator_name: string | null,
    beneficiaryName: string | null
  ) => {
    setSelectedBeneficiaryData({
      billType: "",
      currency: "",
      customerId: 0,
      operatorId: operator_id,
      productName: "",
      deviceNumber: deviceNumber,
      meterType: meter_type || "",
      beneficiaryName: beneficiaryName || "",
      genericOperatorName: generic_operator_name || "",
    });
    setShowBeneficiaryList(false);
  };

  return (
    <Modal
      open={showBeneficiaryList}
      onCancel={() => {
        setShowBeneficiaryList(false);
      }}
      footer={null}
      closeIcon={null}
    >
      <div className="mt-5">
        <CustomInputFieldFormItem
          size="large"
          maxLength={15}
          placeholder="Search"
          onChange={handleSearch}
          prefix={<RiSearch2Line size="20px" className="text-muted" />}
          fieldName="search"
          allowClear
        />
      </div>
      {isLoadingBeneficiary ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : beneficiaries && beneficiaries.length === 0 ? (
        <p className="text-center font-weight-bold">No Beneficiary Available</p>
      ) : (
        <div className={styles.list_of_banks_container}>
          {/* List of Banks */}
          <ul className={styles.listOfBanks}>
            {beneficiaries &&
              beneficiaries
                .filter(({ device_number, operator_id }) => {
                  return device_number?.toLowerCase()?.includes(searchQuery);
                })
                .map(
                  (
                    {
                      product_name,
                      operator_id,
                      device_number,
                      meter_type,
                      generic_operator_name,
                      beneficiary_name,
                    },
                    index
                  ) => {
                    return (
                      <div key={index}>
                        {
                          <li
                            className={styles.list_of_banks_li}
                            onClick={() => {
                              handleSelectProvider(
                                operator_id,
                                device_number,
                                meter_type,
                                generic_operator_name,
                                beneficiary_name
                              );
                            }}
                          >
                            <div className={styles.list_of_banks_sub_container}>
                              <img
                                src={getLogo(generic_operator_name || "")}
                                alt="list of network provider icon"
                                className={styles.list_of_bank_logo}
                              />
                              <p className={`${styles.bank_name} p-none`}>
                                {beneficiary_name || "N/A"} -
                              </p>
                              <p className={`${styles.bank_name} p-none`}>
                                ({device_number})
                              </p>
                            </div>
                          </li>
                        }
                      </div>
                    );
                  }
                )}
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default ElectricityBeneficiaryListDrawer;
