import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputFieldFormItem from "../../../components/CustomHTMLElements/CustomInputFieldFormItem";
import Loader from "../../../components/Loader/Loader.component";
import { getData, postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { yupValidators } from "../../../helpers/yupValidators";
import { ROUTES } from "../../../helpers/routes";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import { TSignUp } from "../../../interfaces/individualSignUp";
import Alert from "../../../components/Alert/Alert.component";
import { add } from "date-fns";
import useWorldCountries from "../../../hooks/custom-hooks/useWorldCountries";
import useGenderOptions from "../../../hooks/custom-hooks/useGenderOptions";
import countryCodeEmoji from "country-code-emoji";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler.component";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { useDispatch, useSelector } from "react-redux";
import {
  getNairaWalletDetails,
  setCurrentUser,
  setSignUp,
  setUserToken,
} from "../../../redux/actions";
import { ReduxStoreModel } from "../../../interfaces/redux";

const schema = yup.object().shape({
  biz_name: yupValidators.genericRequired({
    message: "Please enter your business name",
    min: 2,
    max: 128,
  }),
  phone_number: yupValidators.phoneNumber,
  email: yupValidators.email,
  password: yupValidators.newPassword,
  first_name: yupValidators.genericRequired({
    message: "Please enter your first name",
    min: 1,
    max: 30,
  }),
  last_name: yupValidators.genericRequired({
    message: "Please enter your last name",
    min: 1,
    max: 30,
  }),
  gender: yupValidators.genericRequired({
    message: "Please choose your gender",
  }),
  dob: yupValidators.genericRequired({ message: "Please enter Date Of Birth" }),
});

const CorporateInvestor = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>("");
  const [success, setSuccess] = useState("");
  const signUpDetails = useSelector((state: ReduxStoreModel) => state.signUp);
  const user = useSelector((state: ReduxStoreModel) => state?.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "CorporateInvestor.component.tsx",
      isLoggedIn: true,
    });
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const country = signUpDetails.country;
  const {
    data: genders,
    isLoading: isLoadingGender,
    error: genderError,
    isError: isGenderError,
  } = useGenderOptions({
    countryCode: country,
  });

  const onSubmit = async ({
    first_name,
    last_name,
    email,
    phone_number,
    password,
    biz_name,
    gender,
    dob,
  }: TSignUp) => {
    setLoading(true);
    setError(null);
    const reqBody = {
      first_name,
      phone_number,
      email,
      password,
      last_name,
      gender,
      biz_name,
      country: signUpDetails.country,
      identification_number: signUpDetails.bvn,
      dob,
      funder_type_id: 2, // 2 is for corporate
      phone_no_country_dial_code: signUpDetails?.phoneNoCountryDialCode,
    };

    try {
      await postData(apiEndpoints.SIGN_UP, reqBody);
      setSuccess(
        "Account creation is successful. You will be redirected to your dashboard soon, please wait..."
      );

      // Log user in manually
      const res: any = await postData(apiEndpoints.LOGIN, {
        username: email,
        password,
      });
      dispatch(setUserToken(res?.sso_auth_token));

      // Set when session will expire
      const session_timeout = add(new Date(), {
        seconds: res?.expiresIn,
      });

      const {
        data: {
          funder_id,
          funder_type_id,
          lcy_wallet_id,
          usd_wallet_id,
          biz_name,
          country,
          kyc_resolved_lastname,
          kyc_resolved_firstname,
        },
      } = await getData(apiEndpoints.USER);
      const lcy_wallet = await getData(
        `${apiEndpoints.WALLET_BALANCE}?savingsWalletId=${lcy_wallet_id}&funderId=${funder_id}`
      );
      dispatch(getNairaWalletDetails(funder_id, lcy_wallet_id));
      dispatch(
        setCurrentUser({
          ...user,
          first_name: res?.first_name,
          last_name: res?.last_name,
          email: res?.email,
          role: res?.roles[0],
          lcy_wallet_id,
          usd_wallet_id,
          session_timeout,
          funder_id,
          funder_type_id,
          activated: false,
          business_name: biz_name,
          country,
          kyc_resolved_lastname,
          kyc_resolved_firstname,
          customerAccountId: lcy_wallet.data?.customerAccountId,
        })
      );

      setLoading(false);
      history.push(ROUTES.FUNDER);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: CorporateInvestor },
      });
    }
  };

  const togglePasswordField = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const { data, isError, isLoading } = useWorldCountries();

  return (
    <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
      {error && <Alert message={error} />}
      {genderError && <Alert message={errorHandler(genderError)} />}
      {success && <Alert message={success} type="success" />}
      <div className="row">
        <div className="col-md-6">
          <CustomInputFieldFormItem
            type="text"
            maxLength={30}
            {...register("first_name")}
            placeholder="John"
            label="First Name"
            name="first_name"
            errors={errors.first_name}
            showRequiredIcon={true}
            readOnly={signUpDetails.bvnValidated}
            defaultValue={signUpDetails.first_name}
          />
        </div>
        <div className="col-md-6">
          <CustomInputFieldFormItem
            type="text"
            maxLength={30}
            {...register("last_name")}
            placeholder="Doe"
            label="Last Name"
            name="last_name"
            errors={errors.last_name}
            showRequiredIcon={true}
            readOnly={signUpDetails.bvnValidated}
            defaultValue={signUpDetails.last_name}
          />
        </div>
        <div className="col-md-12">
          <CustomInputFieldFormItem
            type="text"
            maxLength={128}
            {...register("biz_name")}
            placeholder="Rick and Morty Inc"
            label=" Business Name"
            name="biz_name"
            errors={errors.biz_name}
          />
        </div>
        <div className="col-md-12">
          <CustomInputFieldFormItem
            type="email"
            maxLength={128}
            {...register("email")}
            placeholder="hello@ricknmorty.com"
            label="Business/Work Email Address"
            name="email"
            errors={errors.email}
            readOnly
            defaultValue={signUpDetails?.email}
          />
        </div>
        <div className="col-6">
          <CustomSelectDropdown
            {...register("gender")}
            label="Gender"
            name="gender"
            errors={errors.gender}
            showRequiredIcon={true}
          >
            <option value="">Gender</option>
            {genders?.map(({ name, id }: { name: string; id: number }) => (
              <option value={name} key={id}>
                {name}
              </option>
            ))}
          </CustomSelectDropdown>
        </div>
        <div className="col-6">
          <CustomSelectDropdown
            readOnly
            label="Country"
            defaultValue={signUpDetails?.country}
            errors={errors.country}
            {...register("country")}
            extraLabel={isLoading ? "Loading countries" : ""}
          >
            <option value="">Select your country</option>
            {data?.map(
              ({
                name,
                iso2,
                id,
              }: {
                name: string;
                iso2: string;
                id: number;
              }) => (
                <option value={iso2} key={id}>
                  {name}
                </option>
              )
            )}
          </CustomSelectDropdown>
        </div>
        <div className="col-md-12">
          <CustomInputFieldFormItem
            type="number"
            maxLength={20}
            {...register("phone_number")}
            label="Contact Number"
            placeholder="07000000000"
            name="phone_number"
            errors={errors.phone_number}
            readOnly={signUpDetails.bvnValidated}
            defaultValue={signUpDetails?.phone_number}
          >
            <select
              onChange={(e) =>
                dispatch(
                  setSignUp({
                    ...signUpDetails,
                    phoneNoCountryDialCode: e.target.value,
                  })
                )
              }
              value={signUpDetails?.phoneNoCountryDialCode}
            >
              {data?.map(
                ({
                  iso2,
                  id,
                  dialCode,
                }: {
                  iso2: string;
                  id: number;
                  dialCode: string;
                }) => (
                  <option value={dialCode} key={id}>
                    {iso2 && countryCodeEmoji(iso2)} {dialCode}
                  </option>
                )
              )}
            </select>
          </CustomInputFieldFormItem>
        </div>
        <div className="col-md-12 mb-4">
          <label htmlFor="myDate" className="form-label">
            Date Of Birth
          </label>
          <sup className="ml-1 crimson">*</sup>
          <input
            className="form-control"
            type="date"
            {...register("dob")}
            id="myDate"
            readOnly={signUpDetails.bvnValidated}
            defaultValue={signUpDetails.dob}
          />

          <ErrorHandler errors={errors.dob} />
        </div>
        <div className="col-md-12">
          <CustomInputFieldFormItem
            type={passwordType}
            maxLength={128}
            {...register("password")}
            placeholder="********"
            label="Password"
            name="password"
            errors={errors.password}
            hasActionButton={true}
            actionButtonText={passwordType === "password" ? "show" : "hide"}
            onClickActionButton={togglePasswordField}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-wrap">
          <span className="text-muted pr-2">Already have an account? </span>
          <Link to={ROUTES.LOGIN} className="color-blue">
            Login
          </Link>
        </div>
        <button
          type="submit"
          value="submit"
          className="btn btn-sm advancly-btn"
          disabled={
            loading || isLoading || isError || isLoadingGender || isGenderError
          }
        >
          Create Account
          {loading && <Loader />}
        </button>
      </div>
    </form>
  );
};
export default CorporateInvestor;
