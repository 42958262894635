import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "./../../helpers/routes";
import styles from "./InvestmentCard.module.scss";
import { formatDate } from "./../../helpers/formatDate";
import { TCurrencyCodes } from "../../interfaces/currencies";
import { resolveCurrencyCodeBadges } from "./../../helpers/resolveBadges";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../interfaces/redux";

interface IInvestmentCard {
  investment: {
    name: string;
    start_date: string;
    end_date: string;
    id: number;
    lcy_corebanking_id: number;
    usd_corebanking_id: number;
    lcy_currency_code: TCurrencyCodes;
    usd_currency_code: TCurrencyCodes;
    investment_product_details: {
      currency_code: string;
    }[];
  };
}

export const InvestmentCard: React.FC<IInvestmentCard> = ({
  investment: {
    name,
    start_date,
    end_date,
    id,
    lcy_corebanking_id,
    usd_corebanking_id,
    lcy_currency_code,
    usd_currency_code,
    investment_product_details,
  },
}) => {
  const { lcy_wallet_id, usd_wallet_id } = useSelector(
    (state: ReduxStoreModel) => state?.user
  );
  const badges = resolveCurrencyCodeBadges([
    {
      code: usd_currency_code,
      value: usd_corebanking_id,
      walletId: usd_wallet_id,
    },
    {
      code: lcy_currency_code,
      value: lcy_corebanking_id,
      walletId: lcy_wallet_id,
    },
  ]);
  console.log(badges);
  return (
    <div className="card">
      <div className="font-weight-bold">{name}</div>
      <small className="color-grey mb-2">
        {investment_product_details?.length > 1
          ? `${investment_product_details[0].currency_code} & ${investment_product_details[1].currency_code} Fund`
          : `${investment_product_details[0].currency_code} Fund`}
      </small>
      <div className="currency-badges">
        {investment_product_details?.length > 0 &&
          investment_product_details.map((item, i: number) => (
            <div
              style={{ zIndex: investment_product_details.length - i }}
              key={i}
            >
              {item.currency_code}
            </div>
          ))}
      </div>
      <hr />
      <div className={styles.windowSection}>
        <div>
          <div>Window Opens</div>
          <div className="font-weight-bold">{formatDate(start_date)}</div>
        </div>
        <div>
          <div>Window Closes</div>
          <div className="font-weight-bold">{formatDate(end_date)}</div>
        </div>
      </div>
      <Link
        className="color-blue text-underline cursor-pointer"
        to={`${ROUTES.INVESTMENTS}/${id}`}
      >
        View Details
      </Link>
    </div>
  );
};
