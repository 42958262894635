import Alert from "../../../../components/Alert/Alert.component";
import React, { useEffect, useState } from "react";
import styles from "../WalletModalStepOne/WalletAddMoneyModal.module.scss";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

interface IAddMoneyModal {
  closeModal: () => void;
  walletDetails: {
    savingsWalletId?: number;
    funderId?: number;
    cbaClientName?: string;
    cbaClientId?: number;
    availableBalance?: number;
    cbaSavingsProductId?: number;
    cbaSavingsProductName?: string;
    currencyCode?: string;
    currencyName?: string;
    accountName?: string;
    accountNumber?: string;
    accountBankName?: string;
    gatewayBankCode?: string;
  };
}

const WalletAddMoneyModal: React.FC<IAddMoneyModal> = ({
  closeModal,
  walletDetails,
}) => {
  const [copied, setCopied] = useState("");
  const [accountDetailsCopy, setAccountDetailsCopy] = useState("Not Available");

  useEffect(() => {
    setAccountDetailsCopy(
      `
       Account Name: ${walletDetails?.accountName}
       Account Number:${walletDetails?.accountNumber}
       Bank Name: ${walletDetails?.accountBankName}`
    );
  }, [accountDetailsCopy, walletDetails]);
  const copyText = () => {
    setCopied("Copied.");

    navigator.clipboard.writeText(accountDetailsCopy);
    setTimeout(() => setCopied(""), 3000);
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletAddMoneyModal.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      <div className="modal-header p-0 mb-4">
        <h5 className="page-subtitle">Add money Via Bank Transfer</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            closeModal();
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0 mb-4">
        {copied && <Alert message={copied} type="info" />}
        <p className={styles.add_money_paragraph}>
          Kindly transfer above to this account number using your bank transfer
          or ussd.
        </p>
        <div className={styles.add_money_card_wrapper}>
          <div className={styles.add_money_account_details_wrapper}>
            <div>
              <p className={styles.add_money_account_name}>
                {walletDetails?.accountName}
              </p>
              <p className={styles.add_money_account_number}>
                {walletDetails?.accountNumber}
              </p>
              <p className={styles.add_money_account_bank_name}>
                {walletDetails?.accountBankName}
              </p>
            </div>
            <div>
              <span
                className="font-weight-bold cursor-pointer ml-4"
                onClick={() => copyText()}
              >
                <i className="ri-file-copy-fill color-blue ml-2">Copy</i>
              </span>
            </div>
          </div>
          <div className={styles.more_info_about_account_details}>
            <p>
              Any amount transferred to this accounts will reflect in your
              Advancly wallet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WalletAddMoneyModal;
